import "../App.css";

import React, { useState, useEffect } from "react";

import { createContext, useContext } from "react";

import { collection, getDocs, query, onSnapshot } from "firebase/firestore";

import { db } from "../firebase";

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import BuildIcon from "@mui/icons-material/Build";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { AppContext } from "../App";

function BottomMenu() {
  const { state, setState } = useContext(AppContext);

  if (state && state.loggedPlayer) {
    return (
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          value={state.page}
          onChange={(event, newValue) => {
            setState({
              ...state,
              mode: "none",
              page: newValue,
            });
          }}
          sx={{ backgroundColor: "#AACCFF", height: 70 }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction
            label="Calendar"
            icon={<CalendarTodayIcon />}
          />
          <BottomNavigationAction label="Event" icon={<SportsTennisIcon />} />
          <BottomNavigationAction label="Setup" icon={<BuildIcon />} />
          {state.tokenResult?.claims.license === "sysadmin" ? (
            <BottomNavigationAction
              label="Admin"
              icon={<AdminPanelSettingsIcon />}
            />
          ) : (
            <div key="notadmin"></div>
          )}
        </BottomNavigation>
      </Paper>
    );
  } else return <></>;
}

export default BottomMenu;
