import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import { collection, getDocs, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import BuildIcon from "@mui/icons-material/Build";

import { AppContext } from "../App";

import { Event } from "./Event";

function PlayingDay() {
  const { state, setState } = useContext(AppContext);

  if (state) {
    if (
      state.currentEvent &&
      state.currentLeague &&
      state.events[state.currentLeague] &&
      state.events[state.currentLeague][state.currentEvent]
    ) {
      // looking for event
      // const e = state.leagues[state.currentLeague].events[state.currentEvent];
      const e = state.events[state.currentLeague][state.currentEvent];

      return (
        <Box sx={{ marginTop: 7, marginBottom: 20 }}>
          <Event
            key={e.PlayingDayID}
            event={e}
            lid={state.currentLeague}
            showDetails={true}
          />
        </Box>
      );
    } else return <></>;
  } else return <></>;
}

export default PlayingDay;
