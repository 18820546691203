import logo from "./logo.svg";
import "./App.css";

import dayjs from "dayjs";

import React, { useState, useEffect, useRef } from "react";

import { VERSION } from "./processEmails";

//
// firebase
//

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  where,
  updateDoc,
  doc,
  setDoc,
  getDoc,
  collectionGroup,
} from "firebase/firestore";

import * as _ from "lodash";

import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  NextOrObserver,
  User,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";

import { db } from "./firebase";
import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import { auth } from "./firebase";

import { createContext, useContext } from "react";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import BottomMenu from "./components/BottomMenu";
import Home from "./components/Home";
import Calendar from "./components/Calendar";
import PlayingDay from "./components/PlayingDay";
import Setup from "./components/Setup";
import { PlayerButtonProps } from "./components/PlayerButton";
import { Auth } from "./components/Auth";
import {
  availabilityTable,
  eventOpenSpots,
  computePlayerAvailabilityUpdate,
  firestoreAutoId,
  eventCourtTimes,
  eventCourtTimesWithOverrides,
} from "./processEmails";

import {
  hLeagueType,
  hEventType,
  AppContextContent,
  LeagueType,
  EventType,
  hEventsPerLeagueType,
  AdminContextContent,
  TimeWindow,
  AvailabilityValueType,
  AvailabilityType,
  EmailTemplateType,
  EmailType,
  EventSummary,
  ConfigurationType,
  PlayerDetailsType,
  PlayerType,
  Invitation,
  PartialDocumentType,
  PlayerDetailsTypeWithAvailability,
} from "./types";
import { State } from "@dnd-kit/core/dist/store";
import Admin from "./components/Admin";
import { StaticDatePicker } from "@mui/x-date-pickers";

import { User as FirebaseUser } from "firebase/auth";
import { unsubscribe } from "diagnostics_channel";

//
// APP CONTEXT
//

export type AppContextType = {
  state: AppContextContent;
  setState: React.Dispatch<React.SetStateAction<AppContextContent>>;
};

var dayOfYear = require("dayjs/plugin/dayOfYear");
dayjs.extend(dayOfYear);

// import { hDayType } from "./components/Calendar";

const appContextDefaultValue = {
  state: {
    reads: 0,
    page: 0,
    leagues: {} as hLeagueType,
    events: {} as hEventsPerLeagueType,
    // hDay: {} as hDayType,
    // currentDay: "2024-02-01",
    currentEvent: null,
    currentLeague: null,
    hiddenLeagues: [],
    showStandalone: true,
    loggedPlayer: undefined,
    playerRecord: undefined,
    currentDay: dayjs(new Date()).format("YYYY-MM-DD"),
    mode: "none",

    // unsubscribesPerLeague: {},
    unsubscribeLeagues: undefined,
    unsubscribeEvents: undefined,
    unsubscribeCalendarEvents: undefined,
    unsubscribeSelf: undefined,
    timeWindow: {
      lower: dayjs(new Date()).format("YYYY-MM-DD"),
      higher: dayjs(new Date()).add(7, "days").format("YYYY-MM-DD"),
    },
    navigateWithinLeague: false,
    emailClipboard: undefined,

    tokenResult: undefined, // token associated with logged player, contains claims (e.g. license)
  } as AppContextContent,
  setState: () => {},
};

export const AppContext = createContext<AppContextType>(appContextDefaultValue);

//
// ADMIN CONTEXT
//

const adminContextDefaultValue = {
  state: {
    templates: {},
    unsubscribeTemplates: undefined,
    reads: 0,
  } as AdminContextContent,
  setState: () => {},
};

export type AdminContextType = {
  state: AdminContextContent;
  setState: React.Dispatch<React.SetStateAction<AdminContextContent>>;
};

export const AdminContext = createContext<AdminContextType>(
  adminContextDefaultValue
);

//
// DATABASE OPPS
//

export const updateLeagueWithEventSummary = (
  league: LeagueType,
  eventBefore: EventType,
  eventAfter: EventType
) => {
  const beforeCourtimes = eventCourtTimesWithOverrides(eventBefore);
  const afterCourtimes = eventCourtTimesWithOverrides(eventAfter);

  if (
    !_.isEqual(afterCourtimes, beforeCourtimes) ||
    eventAfter.when !== eventBefore.when
  ) {
    console.log("Updating league because event changed");

    const leagueRef = doc(db, "leagues", eventAfter.LeagueID);
    const newEventSummary: EventSummary = {
      when: eventAfter.when,
      playerTimeAndResults: afterCourtimes,
    };
    updateDoc(
      leagueRef,
      "eventsSummary." + eventAfter.PlayingDayID,
      newEventSummary
    );
  }
};

export const updateEvent = (
  league: LeagueType,
  oldevent: EventType,
  changes: { [k: string]: any }
) => {
  const newevent = _.cloneDeep(oldevent);
  // computing newevent
  Object.entries(changes).map(([k, v]) => {
    _.set(newevent, k, v);
  });
  // comparing new and old event, and writing league if necessary
  updateLeagueWithEventSummary(league, oldevent, newevent);
  // finally writing event
  const eventRef = doc(
    db,
    "leagues",
    league.LeagueID,
    "events",
    oldevent.PlayingDayID
  );
  console.log("Updating event " + oldevent.PlayingDayID);
  updateDoc(eventRef, changes);
};

export const updateLeague = (
  league: LeagueType,
  changes: { [k: string]: any }
) => {
  // verifications

  const newLeague = _.cloneDeep(league);
  Object.entries(changes).forEach(([k, v]) => {
    _.set(newLeague, k, v);
  });

  // counting admins
  const nbAdmins = newLeague.admins.length;
  if (nbAdmins < 1) {
    alert("This would remove the last league admin. Operation aborted.");
  } else {
    const leagueRef = doc(db, "leagues", league.LeagueID);
    updateDoc(leagueRef, changes);
  }
};

export const removePlayer = (
  player: PlayerDetailsTypeWithAvailability,
  league: LeagueType
) => {
  if (player.PlayerID && window.confirm("Remove player from league?")) {
    const newPlayers = league.players.filter((p) => p !== player.PlayerID);
    const newAdmins = league.admins.filter((p) => p !== player.PlayerID);
    const newPlayerDetails = _.cloneDeep(league.playerDetails);
    delete newPlayerDetails[player.PlayerID];

    updateLeague(league, {
      players: newPlayers,
      playerDetails: newPlayerDetails,
      admins: newAdmins,
    });
  }
};

export const updatePlayerRecord = (
  player: PlayerType,
  changes: { [k: string]: any }
) => {
  const playerRef = doc(db, "players", player.PlayerID);
  updateDoc(playerRef, changes);
};

export const updateInvitation = (
  invitation: Invitation,
  changes: { [k: string]: any }
) => {
  const leagueRef = doc(db, "leagues", invitation.LeagueID);
  // transposing keys to keys in a league
  const newChanges: { [k: string]: any } = {};
  Object.entries(changes).forEach(([k, v]) => {
    newChanges["invitations." + invitation.InvitationID + "." + k] = v;
  });
  updateDoc(leagueRef, newChanges);
};

export const setPlayerAvailability = async (
  // league: LeagueType,
  league: LeagueType,
  event: EventType,
  pid: string,
  availability: string,
  adminState: AdminContextContent,
  confirmed?: boolean
) => {
  const updating: { [k: string]: any } = computePlayerAvailabilityUpdate(
    event,
    pid,
    availability,
    confirmed
  );

  // checking if this would result in an additional openspot
  if (updating.nbopenspots && updating.nbopenspots > event.nbopenspots) {
    if (
      window.confirm(
        "This will trigger an email to event admins to find a replacement, proceed?"
      )
    ) {
      const templates = Object.values(adminState.templates).filter(
        (v) => v.name === "Open spot"
      );
      const template = templates.length > 0 ? templates[0] : undefined;
      if (template) {
        const options = _.cloneDeep(template.options);

        // patching options
        const who_option = options.findIndex((o) => o.name === "who");
        if (who_option >= 0) {
          options[who_option].value = league.playerDetails[pid].name || "???";
        }

        const newemail: EmailType = {
          EmailID: firestoreAutoId(),
          author: league.admins[0] || "",
          sent: false,
          toGroups: [],
          lessGroups: [],
          toIndividuals: league.admins,
          lessIndividuals: [],
          TemplateID: template.EmailTemplateID,
          whenCategory: "immediately",
          whenValue: 0,
          sendAt: dayjs().add(5, "minute").toISOString(),
          triggered: false,
          triggeredAt: "",
          options: options,
          LeagueID: league.LeagueID,
          EventID: event.PlayingDayID,
          test: false,
          individualized: false,
        };
        // addEmail
        const emailRef = doc(
          db,
          "leagues",
          league.LeagueID,
          "events",
          event.PlayingDayID,
          "emails",
          newemail.EmailID
        );
        setDoc(emailRef, newemail);
      }

      const eventRef = doc(
        db,
        "leagues",
        league.LeagueID,
        "events",
        event.PlayingDayID
      );
      await updateDoc(eventRef, updating);
    }
  } else {
    const eventRef = doc(
      db,
      "leagues",
      league.LeagueID,
      "events",
      event.PlayingDayID
    );
    await updateDoc(eventRef, updating);
  }
};

//
// SYNC EVENT TO OPENSPOTS
//

export const syncEventToOpenSpots = async (event: EventType) => {
  const openSpots = eventOpenSpots(event);
  console.log("Open spots:");
  console.log(JSON.stringify(openSpots, null, 2));
  event.nbopenspots = openSpots.length;
  event.openspots = openSpots;
};

//
//
//

//
// LOG USER IN
//

export const logUserIn = async (
  user: User,
  state: AppContextContent,
  setState: React.Dispatch<React.SetStateAction<AppContextContent>>
) => {
  // Action if the user is authenticated successfully
  //   alert("Success");
  // console.log(authResult);

  if (state.loggedPlayer?.email !== user.email) {
    console.log("User authenticated");

    // console.log(JSON.stringify(user, null, 2));
    await user.getIdToken(true);

    user.getIdTokenResult().then((token) => {
      setState((state) => {
        return { ...state, loggedPlayer: user, tokenResult: token };
      });
    });
  } else {
    console.log("Did not fetch");
  }
};

function App() {
  const [nbEffects, setNbEffects] = useState(0);

  const fetchPlayerRecord = async () => {
    if (!state.unsubscribeSelf && state.loggedPlayer) {
      console.log(
        "Subscribing to player record for " + state.loggedPlayer.displayName
      );

      const unsub = onSnapshot(
        doc(db, "players", state.loggedPlayer.uid),
        (doc) => {
          const player = doc.data() as PlayerType;
          if (
            player &&
            state.loggedPlayer &&
            player.PlayerID === state.loggedPlayer.uid &&
            player.email !== state.loggedPlayer?.email
          ) {
            updateDoc(doc.ref, "email", state.loggedPlayer.email);
          }
          if (player) {
            setState((state) => {
              return {
                ...state,
                playerRecord: doc.data() as PlayerType,
                reads: state.reads + 1,
              };
            });
          }
        }
      );

      setState((state) => {
        return { ...state, unsubscribeSelf: unsub };
      });
      return unsub;
    } else {
      return () => {};
    }
  };

  const fetchEvents = async (
    timeWindow: TimeWindow,
    unsubscribeKey: keyof AppContextContent
  ) => {
    if (!state[unsubscribeKey] && state.loggedPlayer) {
      // we subscribe to the events for this league
      console.log("Subscribing to events for " + unsubscribeKey);

      const equery = await query(
        collectionGroup(db, "events"),
        where("when", ">=", timeWindow.lower),
        where("when", "<", timeWindow.higher),
        where("players", "array-contains", state.loggedPlayer.uid)
      );
      const eunsubscribe = onSnapshot(equery, (eQuerySnapshot) => {
        eQuerySnapshot.docChanges().forEach((change) => {
          const doc = change.doc;

          if (change.type === "added") {
            console.log("New event: ", change.doc.data().PlayingDayID);
            setState((state) => {
              const newevents = _.cloneDeep(state.events);
              const eid = doc.data().PlayingDayID;
              const lid = doc.data().LeagueID;
              if (!newevents[lid]) {
                newevents[lid] = {};
              }
              newevents[lid][eid] = doc.data() as EventType;
              return {
                ...state,
                reads: state.reads + 1,
                events: newevents,
              };
            });
          }
          if (change.type === "modified") {
            console.log("Modified event: ", change.doc.data().PlayingDayID);
            setState((state) => {
              const newevents = _.cloneDeep(state.events);
              const eid = doc.data().PlayingDayID;
              const lid = doc.data().LeagueID;
              if (!newevents[lid]) {
                newevents[lid] = {};
              }
              newevents[lid][eid] = doc.data() as EventType;
              return {
                ...state,
                reads: state.reads + 1,
                events: newevents,
              };
            });
          }
          if (change.type === "removed") {
            console.log("Removed event: ", change.doc.data().PlayingDayID);
            setState((state) => {
              const newevents = _.cloneDeep(state.events);
              const eid = doc.data().PlayingDayID;
              const lid = doc.data().LeagueID;
              if (!newevents[lid]) {
                newevents[lid] = {};
              }
              delete newevents[lid][eid];
              return {
                ...state,
                events: newevents,
              };
            });
          }
        });

        // going through all events received
        // eQuerySnapshot.forEach((edoc) => {
        //   const eid = edoc.data().PlayingDayID;
        //   events[eid] = { ...edoc.data() } as EventType;
        //   console.log("Added event " + eid);
        // });
        //
        // storing the events received in state
        //
      });
      //
      // storing the unsubscribe in state
      //
      setState((state) => {
        return {
          ...state,
          // unsubscribeEvents: eunsubscribe,
          [unsubscribeKey]: eunsubscribe,
        };
      });

      return eunsubscribe;
    } else {
      console.log(
        "Skipping a subscription, subscription already exists for " +
          unsubscribeKey
      );
    }
  };

  // const getApplicationParameters = async (
  //   state: AdminContextContent,
  //   setState: React.Dispatch<React.SetStateAction<AdminContextContent>>
  // ) => {

  //   return res;
  // };

  const fetchLeagues = async (uid: string) => {
    var q = query(
      collection(db, "leagues"),
      where("closed", "==", false),
      where("players", "array-contains", uid)
    );

    // const unsubscribes: (() => void)[] = [];

    console.log("Fetching leagues for player " + uid);

    const unsubscribe = await onSnapshot(q, (querySnapshot) => {
      console.log("snapshot on leagues");
      if (true || !querySnapshot.metadata.hasPendingWrites) {
        querySnapshot.docChanges().forEach(async (change) => {
          //
          // new league
          //
          if (change.type === "added") {
            console.log("New league: ", change.doc.data().name);

            const doc = change.doc;
            const lid = doc.data().LeagueID;

            setState((state) => {
              const newleagues = _.cloneDeep(state.leagues);
              newleagues[lid] = doc.data() as LeagueType;
              return {
                ...state,
                reads: state.reads + 1,
                leagues: newleagues,
                // hDay: hDay
              };
            });
          }

          //
          // modified league
          //
          if (change.type === "modified") {
            console.log("Modified league: ", change.doc.data().name);
            // no need to load events

            setState((state) => {
              const newleagues = _.cloneDeep(state.leagues);
              newleagues[change.doc.data().LeagueID] =
                change.doc.data() as LeagueType;
              return { ...state, reads: state.reads + 1, leagues: newleagues };
            });
          }
          //
          // removed league
          //
          if (change.type === "removed") {
            console.log("Removed league: ", change.doc.data().name);
            const league = change.doc.data() as LeagueType;

            //
            // now remove league
            //
            setState((state) => {
              const newleagues = _.cloneDeep(state.leagues);
              delete newleagues[league.LeagueID];
              return { ...state, leagues: newleagues };
            });
          }
        });
      }
    });

    setState((state) => {
      return {
        ...state,
        unsubscribeLeagues: unsubscribe,
      };
    });

    return unsubscribe;

    // console.log("Now setting with unsubscribe");
    // console.log(unsubscribe);

    // setState((state) => {
    //   const newunsubscribes = _.cloneDeep(state.unsubscribesPerLeague);
    //   newunsubscribes["base"] = unsubscribe;
    //   return {
    //     ...state,
    //     unsubscribesPerLeague: newunsubscribes,
    //   };
    // });
  };

  const fetchTemplates = async () => {
    if (!adminState.unsubscribeTemplates) {
      const q = query(collection(db, "templates"));

      const unsubscribe = await onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          const template: EmailTemplateType =
            change.doc.data() as EmailTemplateType;

          if (change.type === "added") {
            console.log("New template: " + template.name);

            setAdminState((state) => {
              const newtemplates = _.cloneDeep(state.templates);
              newtemplates[template.EmailTemplateID] = template;
              return {
                ...state,
                templates: newtemplates,
                reads: state.reads + 1,
                // hDay: hDay
              };
            });
          }
          if (change.type === "modified") {
            console.log("Modified template: ", template.name);
            // no need to load events
            // console.log(JSON.stringify(template, null, 2));

            setAdminState((state) => {
              const newTemplates = _.cloneDeep(state.templates);
              newTemplates[template.EmailTemplateID] = template;
              return {
                ...state,
                templates: newTemplates,
                reads: state.reads + 1,
              };
            });
          }
          if (change.type === "removed") {
            console.log("Removed template: ", template.name);
            //
            // now remove template
            //
            setAdminState((state) => {
              const newtemplates = _.cloneDeep(state.templates);
              delete newtemplates[template.EmailTemplateID];
              return { ...state, templates: newtemplates };
            });
          }
        });
      });
      setAdminState((state) => {
        return {
          ...state,
          unsubscribeTemplates: unsubscribe,
        };
      });
      return unsubscribe;
    } else {
      console.log("Skipping a subscription to templates");
    }
  };

  const [state, setState] = useState<AppContextContent>(
    appContextDefaultValue.state
  );

  const [adminState, setAdminState] = useState<AdminContextContent>(
    adminContextDefaultValue.state
  );

  //
  // load confiration
  //

  useEffect(() => {
    if (state?.loggedPlayer?.uid) {
      console.log("subscribing to configuration");
      const res = onSnapshot(
        doc(db, "configuration", "parameters"),
        (docSnap) => {
          if (docSnap && docSnap.data() !== undefined) {
            console.log(
              "Got configuration - version " + docSnap.data()?.version
            );
            setAdminState((state) => {
              return {
                ...state,
                configuration: docSnap.data() as ConfigurationType,
              };
            });
          }
        }
      );
      return () => {
        res();
      };
    } else {
      return () => {};
    }
  }, [state?.loggedPlayer]);

  //
  // load partials
  //

  useEffect(() => {
    if (
      state?.loggedPlayer?.uid &&
      state.tokenResult?.claims &&
      ["sysadmin", "professional"].includes(
        state.tokenResult.claims.license as string
      )
    ) {
      console.log("subscribing to partials");
      const res = onSnapshot(
        doc(db, "configuration", "partials"),
        (docSnap) => {
          if (docSnap && docSnap.data() !== undefined) {
            console.log("Got partials");
            setAdminState((state) => {
              return {
                ...state,
                partials: docSnap.data() as PartialDocumentType,
              };
            });
          }
        }
      );
      return () => {
        res();
      };
    } else {
      console.log("Not subscribing to partials");
      // console.log(JSON.stringify(state.tokenResult, null, 2));
      // console.log(state.tokenResult?.claims.license);
      return () => {};
    }
  }, [state?.loggedPlayer]);

  //
  // load leagues when we have a logged in user
  //

  useEffect(() => {
    // setNbEffects(nbEffects + 1);

    if (state?.loggedPlayer?.uid) {
      console.log("Going to fetch player record");
      fetchPlayerRecord();

      console.log("Going to fetch leagues");
      fetchLeagues(state.loggedPlayer.uid);

      console.log("Going to fetch events");
      fetchEvents(state.timeWindow, "unsubscribeEvents");

      console.log("Going to fetch templates");
      fetchTemplates();

      return () => {
        if (state.unsubscribeSelf) {
          state.unsubscribeSelf();
          setState((state) => {
            return { ...state, unsubscribeSelf: undefined };
          });
        }
        if (state.unsubscribeLeagues) {
          state.unsubscribeLeagues();
          setState((state) => {
            return { ...state, unsubscribeLeagues: undefined };
          });
        }
        if (state.unsubscribeEvents) {
          state.unsubscribeEvents();
          setState((state) => {
            return { ...state, unsubscribeEvents: undefined };
          });
        }
        if (adminState.unsubscribeTemplates) {
          adminState.unsubscribeTemplates();
          setAdminState((state) => {
            return { ...state, unsubscribeTemplates: undefined };
          });
        }
      };
    } else {
      console.log("Not fetching leagues, no user");
    }
  }, [state?.loggedPlayer]); // runs when logged player has changed

  // useEffect(() => {
  //   console.log("Checking event subscriptions");
  //   checkEventSubscriptions();
  // }, [state]);

  //
  // when the day changes, we resubscribe to events for that day
  // unless we are covered by the main subscription (first seven days)
  //
  useEffect(() => {
    if (state.unsubscribeCalendarEvents) {
      console.log("Unsubscribing from calendar events");
      state.unsubscribeCalendarEvents();
      setState((state) => {
        return { ...state, unsubscribeCalendarEvents: undefined };
      });
    }
  }, [state.currentDay]);

  // we load events when the unsubscribe is null
  useEffect(() => {
    if (!state.unsubscribeCalendarEvents) {
      if (state.currentDay) {
        const lower = dayjs(state.currentDay).startOf("day").toISOString();
        const higher = dayjs(state.currentDay)
          .startOf("day")
          .add(1, "day")
          .toISOString();
        if (
          lower < state.timeWindow.lower ||
          higher > state.timeWindow.higher
        ) {
          fetchEvents(
            {
              lower: lower,
              higher: higher,
            },
            "unsubscribeCalendarEvents"
          );
        } else {
          console.log("No need to subscribe for calendar, already covered");
        }
      } else {
        console.log("no current day, no need to subscribe");
      }
    }
  }, [state.unsubscribeCalendarEvents, state.currentDay]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("onAuthStateChanged");
        logUserIn(user, state, setState);
        // alert("logged in as " + user.displayName);
      } else {
        // User is signed out
        // ...
      }
    });
    return unsubscribe;
  }, []);

  return (
    <div
      className="App"
      style={{ userSelect: "none", WebkitUserSelect: "none" }}
    >
      <AppContext.Provider value={{ state, setState }}>
        <AdminContext.Provider
          value={{ state: adminState, setState: setAdminState }}
        >
          <AppBar sx={{ minHeight: 42 }}>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography fontSize={18} margin={0} sx={{ marginTop: 1 }}>
                {
                  ["Racquetbudz", "Calendar", "Event", "Setup", "Admin"][
                    state.page
                  ]
                }
              </Typography>
              {adminState?.configuration &&
              adminState.configuration.version > VERSION ? (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  sx={{ margin: 1 }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Click here to Upgrade
                </Button>
              ) : (
                <></>
              )}
            </Stack>
            {/* <Typography>{nbEffects} effects</Typography> */}
          </AppBar>

          {state.loggedPlayer ? (
            <>
              {state.page === 0 ? (
                <Home />
              ) : state.page === 1 ? (
                <Calendar />
              ) : state.page === 2 ? (
                <PlayingDay />
              ) : state.page === 3 ? (
                <Setup />
              ) : state.page === 4 ? (
                <Admin />
              ) : (
                <></>
              )}
            </>
          ) : (
            <Auth />
          )}

          <BottomMenu />
        </AdminContext.Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
