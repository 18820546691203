import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";

// LODASH

import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";
import { AdminContext } from "../App";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  doc,
  deleteField,
  addDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Switch,
  Icon,
  Menu,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Theme, useTheme } from "@mui/material/styles";

//
// TYPES
//

import {
  EventType,
  PlayerDetailsType,
  LeagueType,
  EmailWhenCategoryType,
  EmailGroupType,
} from "../types";
import { EmailType } from "../types";

import { composestyle } from "./EmailsCompose";

// MUI ICONS

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { AppContext } from "../App";

//
// Finally (because there is the extend command), DAYJS
//
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

type ReviewProps = {
  event: EventType;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  lid: string;
  email: EmailType;
};

export const Review = ({
  event,
  showModal,
  setShowModal,
  lid,
  email,
}: ReviewProps) => {
  const { state: state, setState: setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const [selected, setSelected] = useState(0);

  if (email.carbonCopies) {
    return (
      <Modal
        open={showModal}
        onClose={() => {
          // if (window.confirm("You will lose changes, close?")) {
          setShowModal(false);
          // }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={composestyle}>
          <Stack direction="column" spacing={1}>
            <Typography>
              {email.carbonCopies.length.toString()} email
              {email.carbonCopies.length > 1 ? "s" : ""} sent
            </Typography>
            <Select
              onChange={(event) => {
                setSelected(event.target.value as number);
              }}
              value={selected}
              sx={{ width: "100%" }}
            >
              {email.carbonCopies.map((cc, index) => (
                <MenuItem value={index}>
                  {cc.to
                    .map(
                      (onerecipient) =>
                        state.leagues[lid].playerDetails[onerecipient]
                          ?.fullname || "removed player"
                    )
                    .join(", ")}
                </MenuItem>
              ))}
            </Select>

            {(() => {
              const cc = email.carbonCopies[selected];
              if (cc) {
                return (
                  <Stack direction="column" spacing={1}>
                    <Box>
                      {email.EmailID} / {cc.CarbonCopyID}
                    </Box>
                    <Stack direction="row" spacing={1}>
                      <Box>To:</Box>
                      <Box sx={{ maxHeight: 100, overflow: "auto" }}>
                        {cc.to
                          .map(
                            (onerecipient) =>
                              state.leagues[lid].playerDetails[onerecipient]
                                ?.fullname || "removed player"
                          )
                          .join(", ")}
                      </Box>
                    </Stack>
                    <Box>Subject: {cc.subject}</Box>

                    {!email.TemplateID ||
                    adminState.templates[email.TemplateID]?.type === "html" ? (
                      <Box
                        sx={{
                          maxHeight: "400px",
                          overflow: "auto",
                          backgroundColor: "white",
                        }}
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: cc.contents,
                        }}
                      ></Box>
                    ) : (
                      <Box
                        sx={{
                          whiteSpace: "pre-line",
                          height: "400px",
                          overflow: "auto",
                          backgroundColor: "white",
                        }}
                      >
                        {cc.contents}
                      </Box>
                    )}
                  </Stack>
                );
              } else {
                return <></>;
              }
            })()}
          </Stack>
        </Box>
      </Modal>
    );
  } else {
    return (
      <Modal
        open={showModal}
        onClose={() => {
          // if (window.confirm("You will lose changes, close?")) {
          setShowModal(false);
          // }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={composestyle}>
          <Typography>No trace of sent emails</Typography>
          <Select></Select>
        </Box>
      </Modal>
    );
  }
};
