import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";

// LODASH

import * as _ from "lodash";

// CONTEXTS

import { useContext } from "react";
import { AdminContext } from "../App";

// FIREBASE

import {
  collection,
  query,
  onSnapshot,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  Paper,
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  Select,
  MenuItem,
  List,
  ListItemButton,
  FormControl,
  InputLabel,
  ButtonGroup,
} from "@mui/material";

//
// TYPES
//

import { EventType, EmailGroupType, LeagueType } from "../types";
import { EmailType } from "../types";

// MUI ICONS

import EmailIcon from "@mui/icons-material/Email";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteIcon from "@mui/icons-material/Delete";

import { AppContext } from "../App";

import { style } from "./Event";
import {
  EmailDataType,
  EventDataType,
  MergeEmailOptionsWithTemplate,
  buildEmailDataPass1,
  buildEmailDataPass2,
  processToList,
  renderPartials,
} from "../processEmails";

import { EventForm } from "./EventForm";
import { LeaguePlayerForm } from "./LeaguePlayerForm";
import { Compose } from "./EmailsCompose";
import { Review } from "./EmailsReview";

//
// Finally (because there is the extend command), DAYJS
//
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { Compose2 } from "./EmailsCompose2";
dayjs.extend(relativeTime);
dayjs.extend(duration);

//
// CONSTANTS
//

type ListItemEmailProps = {
  email: EmailType;
  selected: boolean;
  onSelect: (email: EmailType) => void;
  emailData: EmailDataType;
  league: LeagueType;
  event: EventType;
};

const ListItemEmail = ({
  email,
  selected,
  onSelect,
  emailData,
  league,
  event,
}: ListItemEmailProps) => {
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const { state, setState } = useContext(AppContext);

  if (state.loggedPlayer && adminState.partials) {
    return (
      <ListItemButton
        onClick={(e) => {
          onSelect(email);
        }}
      >
        <Stack
          direction="column"
          sx={{ width: "100%", backgroundColor: selected ? "#ffd" : "#fff" }}
        >
          <Stack direction="row" spacing={1}>
            {email.sent ? <EmailIcon /> : <HourglassTopIcon />}
            <Typography sx={{ flexGrow: 1, textAlign: "start", fontSize: 13 }}>
              {(() => {
                if (email.carbonCopies && email.carbonCopies.length > 0) {
                  return email.carbonCopies[0].subject;
                } else {
                  const toList = processToList(email, emailData.groupids);

                  const emailDataPatched = buildEmailDataPass2(
                    league,
                    event,
                    toList,
                    state.loggedPlayer,
                    _.clone(emailData),
                    email
                  );

                  const subject = renderPartials(
                    email.subjectPartials || [],
                    emailData,
                    adminState.partials
                  );

                  return subject;
                }
              })()}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography sx={{ fontSize: 12, textAlign: "start" }}>
              {email.carbonCopies && email.carbonCopies.length > 0
                ? dayjs(email.carbonCopies[0].sentAt).format("LLLL")
                : email.whenCategory === "immediately"
                ? "immediately"
                : email.whenCategory === "never"
                ? "holding"
                : dayjs.duration({ minutes: email.whenValue }).humanize() +
                  " before the event"}
            </Typography>
            <Typography sx={{ fontSize: 12, textAlign: "end" }}>
              {email.sendAt ? dayjs(email.sendAt).fromNow() : "???"}
            </Typography>
          </Stack>
        </Stack>
      </ListItemButton>
    );
  } else return <></>;
};

type EmailsProps = {
  event: EventType;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  lid: string;
};

export const Emails = (props: EmailsProps) => {
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const { state, setState } = useContext(AppContext);
  const { event, showModal, setShowModal, lid } = props;

  // if set, contains the EmailTemplateID of template to use to create email
  const [showNew, setShowNew] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [email, setEmail] = useState<EmailType | undefined>(undefined);

  const [showReview, setShowReview] = useState(false);
  const [emails, setEmails] = useState<EmailType[]>([]);

  const league = state.leagues[lid];

  // pass 1 of build email data
  const [emailData, setEmailData] = useState(
    buildEmailDataPass1(league, event)
  );

  useEffect(() => {
    const q = query(
      collection(db, "leagues", lid, "events", event.PlayingDayID, "emails")
    );

    const eunsubscribe = onSnapshot(q, (querySnapshot) => {
      const emails: EmailType[] = [];
      var read = 0;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        emails.push(doc.data() as EmailType);
        read = read + 1;
      });
      setEmails(emails);
      setEmail(undefined);
      setState((state) => {
        return { ...state, reads: state.reads + read };
      });
    });

    return eunsubscribe;
  }, []);

  return (
    // <>
    //   <Modal
    //     open={showModal}
    //     onClose={() => {
    //       setShowModal(false);
    //     }}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    // <Box sx={style}>
    <Box>
      <Stack direction="column" spacing={1}>
        {/* <Paper> */}
        <List dense={true} sx={{ overflow: "auto" }}>
          {emails
            .sort((a, b) =>
              a.sendAt && b.sendAt && a.sendAt > b.sendAt ? -1 : 1
            )
            .map((e) => (
              <ListItemEmail
                key={e.EmailID}
                league={league}
                event={event}
                email={e}
                onSelect={(e) => {
                  if (email && email.EmailID === e.EmailID && !email.sent) {
                    setShowEdit(true);
                  } else if (
                    email &&
                    email.EmailID === e.EmailID &&
                    email.sent
                  ) {
                    setShowReview(true);
                  } else {
                    setEmail(e);
                  }
                }}
                selected={email && email.EmailID === e.EmailID ? true : false}
                emailData={emailData}
              />
            ))}
          {/* <ListItemEmail key={1} email={{ sent: false }} />
                <ListItemEmail key={2} email={{ sent: true }} />
                <ListItemEmail key={3} email={{ sent: true }} />
                <ListItemEmail key={4} email={{ sent: true }} /> */}
        </List>
        {/* </Paper> */}
        {showEdit && state.loggedPlayer && email && email.TemplateID ? (
          <Compose
            key={email?.EmailID || ""}
            event={event}
            showModal={showEdit}
            setShowModal={setShowEdit}
            lid={lid}
            email={email}
            // ? // if editing an existing email, we merge the template
            // options with the existing email options, just in case
            // there are new options in the template since it was used
            //   {
            //     ...email,
            //     options: MergeEmailOptionsWithTemplate(
            //       adminState.templates[showCompose].options,
            //       email.options
            //     ),
            //   }
            // : {
            //     toGroups: _.clone(
            //       adminState.templates[showCompose].toGroups
            //     ),
            //     lessGroups: _.clone(
            //       adminState.templates[showCompose].lessGroups
            //     ),
            //     individualized:
            //       adminState.templates[showCompose].individualized,
            //     EmailID: "",
            //     toIndividuals: [],
            //     lessIndividuals: [],
            //     sent: false,
            //     TemplateID: showCompose,
            //     whenCategory: "immediately",
            //     whenValue: 0,
            //     author: _.cloneDeep(state.loggedPlayer.uid),
            //     options: _.cloneDeep(
            //       adminState.templates[showCompose].options
            //     ),
            //     LeagueID: lid,
            //     EventID: event.PlayingDayID,
            //     test: false,
            //     triggered: false,
            //     triggeredAt: "",
            //   }
            // }
          />
        ) : (showNew || showEdit) && state.loggedPlayer ? (
          <Compose2
            key={email?.EmailID || ""}
            league={league}
            event={event}
            showModal={showNew || showEdit}
            setShowModal={(n: boolean) => {
              setShowEdit(n);
              setShowNew(n);
            }}
            lid={lid}
            email={
              email && showEdit
                ? // if editing an existing email, we merge the template
                  // options with the existing email options, just in case
                  // there are new options in the template since it was used
                  {
                    ...email,
                  }
                : {
                    toGroups: [],
                    lessGroups: [],
                    individualized: false,
                    EmailID: "",
                    toIndividuals: [],
                    lessIndividuals: [],
                    sent: false,
                    TemplateID: undefined,
                    whenCategory: "immediately",
                    whenValue: 0,
                    author: _.cloneDeep(state.loggedPlayer.uid),
                    options: [],
                    LeagueID: lid,
                    EventID: event.PlayingDayID,
                    test: false,
                    triggered: false,
                    triggeredAt: "",
                  }
            }
          />
        ) : (
          <></>
        )}
        {showReview && state.loggedPlayer && email ? (
          <Review
            event={event}
            showModal={showReview}
            setShowModal={setShowReview}
            lid={lid}
            email={email}
          />
        ) : (
          <></>
        )}

        <ButtonGroup sx={{ alignSelf: "center" }}>
          <Button
            variant="contained"
            startIcon={<EmailIcon />}
            onClick={() => {
              setShowNew(true);
            }}
          >
            new
          </Button>
          <Button
            variant="contained"
            disabled={email === undefined}
            startIcon={<DeleteIcon />}
            color="secondary"
            onClick={async () => {
              if (email && window.confirm("Sure?")) {
                const emailRef = doc(
                  db,
                  "leagues",
                  lid,
                  "events",
                  event.PlayingDayID,
                  "emails",
                  email.EmailID
                );
                await deleteDoc(emailRef);
              }
            }}
          >
            Del
          </Button>
          {/* </ButtonGroup>
        <ButtonGroup sx={{ alignSelf: "center" }}> */}
          <Button
            variant="contained"
            disabled={email === undefined}
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              setState((state) => {
                if (email) {
                  const newemail = _.cloneDeep(email);
                  newemail.EmailID = "";
                  delete newemail.carbonCopies;
                  newemail.sent = false;
                  newemail.LeagueID = "";
                  newemail.EventID = "";
                  return { ...state, emailClipboard: newemail };
                } else {
                  return state;
                }
              });
            }}
          >
            cp
          </Button>
          <Button
            variant="contained"
            disabled={state.emailClipboard === undefined}
            startIcon={<ContentPasteIcon />}
            onClick={() => {
              if (
                state.emailClipboard &&
                state.currentLeague &&
                state.currentEvent
              ) {
                setEmail({
                  ...state.emailClipboard,
                  triggered: false,
                  sent: false,
                  LeagueID: state.currentLeague,
                  EventID: state.currentEvent,
                });
              }
              setShowEdit(true);
            }}
          >
            paste
          </Button>
        </ButtonGroup>

        <ButtonGroup sx={{ alignSelf: "center" }}>
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
        </ButtonGroup>

        {/* <FormControl fullWidth sx={{ margingTop: 3 }}>
              <InputLabel id="newemaillabel">New email...</InputLabel>
              <Select
                label="New email..."
                labelId="newemaillabel"
                onChange={(event) => {
                  setEmail(undefined);
                  setShowCompose(event.target.value as string);
                }}
                value={0}
              >
                {Object.values(adminState.templates).map((t) => (
                  <MenuItem
                    key={t.EmailTemplateID}
                    value={t.EmailTemplateID}
                    selected={false}
                  >
                    <Stack direction="column">
                      <Typography>{t.name}</Typography>
                      <Typography sx={{ fontSize: "10pt" }}>
                        {t.description}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

        {/* <Button
              variant="contained"
              onClick={() => {
                setEmail(undefined);
                setShowCompose(true);
              }}
            >
              New email
            </Button> */}
      </Stack>
    </Box>
    //   </Modal>
    // </>
  );
};
