import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import { collection, getDocs, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Event } from "./Event";
import { EventType, hEventType } from "../types";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import FilterListIcon from "@mui/icons-material/FilterList";
import BuildIcon from "@mui/icons-material/Build";

import { LeagueType } from "../types";
import { AppContext } from "../App";

import { style } from "./Event";
import { HomeInvitation, HomeInvitations } from "./HomeInvitation";

const LeagueFilter = (props: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { showModal, setShowModal } = props;
  const { state, setState } = useContext(AppContext);

  if (state) {
    return (
      <>
        <Modal
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack>
              <Typography sx={{ alignContent: "center", fontSize: "16pt" }}>
                Leagues
              </Typography>
              <Stack direction="column">
                {Object.keys(state.leagues)
                  .filter((lid) => state.leagues[lid].standalone === false)
                  .map((lid) => (
                    <Stack
                      key={lid}
                      direction="row"
                      onClick={() => {
                        if (!state.hiddenLeagues.includes(lid)) {
                          setState((cur) => {
                            return {
                              ...cur,
                              hiddenLeagues: cur.hiddenLeagues.concat([lid]),
                            };
                          });
                        } else {
                          setState((cur) => {
                            return {
                              ...cur,
                              hiddenLeagues: cur.hiddenLeagues.filter(
                                (l) => l != lid
                              ),
                            };
                          });
                        }
                      }}
                    >
                      <Checkbox
                        checked={
                          state.hiddenLeagues.includes(lid) ? false : true
                        }
                      />
                      <Typography sx={{ marginTop: 1 }}>
                        {state.leagues[lid].name}
                      </Typography>
                    </Stack>
                  ))}

                <Stack direction="row">
                  <Checkbox
                    checked={state.showStandalone}
                    onChange={() => {
                      setState((state) => {
                        return {
                          ...state,
                          showStandalone: !state.showStandalone,
                        };
                      });
                    }}
                  />
                  <Typography sx={{ marginTop: 1 }}>
                    Show standalone events
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

function Home() {
  const { state, setState } = useContext(AppContext);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //
  //
  // COMPILING ALL EVENTS TO DISPLAY
  //
  //

  const eventsToDisplay = Object.values(state.leagues)
    .filter(
      (l) =>
        !state.hiddenLeagues.includes(l.LeagueID) &&
        !(l.standalone && state.showStandalone === false)
    )
    .reduce(
      (p, c: LeagueType) =>
        p.concat(
          state.events[c.LeagueID]
            ? Object.values(state.events[c.LeagueID]).map((e) => {
                return { ...e, LeagueID: c.LeagueID };
              })
            : []
        ),
      [] as EventType[]
    )
    .filter(
      (e) =>
        dayjs(e.when) >= dayjs().startOf("day") &&
        dayjs(e.when) < dayjs().add(7, "day")
    )
    .sort((a, b) => (a.when > b.when ? 1 : -1));

  if (state) {
    return (
      <Box
        sx={{ paddingTop: 8, paddingBottom: 10, backgroundColor: "#f7f7f7" }}
      >
        {showFilter ? (
          <LeagueFilter showModal={showFilter} setShowModal={setShowFilter} />
        ) : (
          <></>
        )}

        {/* <Typography>
          {Object.values(state.events)
            .reduce((p: number, c: hEventType) => p + Object.keys(c).length, 0)
            .toString() + " "}
          events in memory
        </Typography> */}
        {/* <Divider /> */}
        {/* <Box sx={{ margin: 0.5, backgroundColor: "#eee" }}>Coming up...</Box> */}

        <Typography>Your outlook for the coming 7 days:</Typography>
        <Stack direction="column" spacing={4} sx={{ margin: 2 }}>
          <HomeInvitations />

          {eventsToDisplay.map((e) => (
            <Event
              key={e.PlayingDayID}
              event={e}
              lid={e.LeagueID}
              showDetails={false}
            />
          ))}
        </Stack>

        <center>
          <Button
            size="large"
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={() => {
              setShowFilter(true);
            }}
          >
            select leagues
          </Button>
        </center>
      </Box>
    );
  } else return <></>;
}

export default Home;
