import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { Dispatch, SetStateAction } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { useContext } from "react";

// FIREBASE

// MUI

import { Box, Stack } from "@mui/material";

// TYPES

import { AppContext } from "../App";
import { PlayerDetailsTypeWithAvailability } from "../types";
import {
  OriginalPlayerPostit,
  PlayerButton,
  PlayerPostit,
} from "./PlayerButton";
import { hPlayersWithAvailabilitiesType } from "./Event";
import { PlayerButtonProps } from "./PlayerButton";

// DND

import {
  useDroppable,
  useDraggable,
  UseDroppableArguments,
  DraggableAttributes,
} from "@dnd-kit/core";
import { hCourtTimeType } from "../types";

// COMPONENTS

type AvailabilitiesProps = {
  eid: string;
  lid: string;
  showDetails: boolean;
  playersWithAvailabilities: hPlayersWithAvailabilitiesType;
  playerDragged?: PlayerButtonProps;
  currentPlayer: string | undefined;
  setCurrentPlayer: Dispatch<SetStateAction<string | undefined>>;
  courtTimes: hCourtTimeType;
};

export const Availabilities = (props: AvailabilitiesProps) => {
  const { eid, lid } = props;
  const { state, setState } = useContext(AppContext);
  const {
    playersWithAvailabilities,
    playerDragged,
    setCurrentPlayer,
    currentPlayer,
    courtTimes,
  } = props;

  // DND
  const { isOver, setNodeRef } = useDroppable({
    id: "availabilities",
  });
  const style = {
    opacity: playerDragged ? (isOver ? 1 : 0.5) : 1,
  };

  if (state) {
    const league = state.leagues[lid];
    const event = state.events[lid][eid];

    var sortedPlayers = Object.values(playersWithAvailabilities)
      .sort(
        (
          a: PlayerDetailsTypeWithAvailability,
          b: PlayerDetailsTypeWithAvailability
        ) => {
          if (event.priorityPolicy === "leastCourtTime") {
            return (courtTimes[a.PlayerID || ""]?.minutes || 0) >
              (courtTimes[b.PlayerID || ""]?.minutes || 0)
              ? 1
              : -1;
          } else if (event.priorityPolicy === "leastCourtTimeAgainstPlan") {
            if (
              !league.playerDetails[a.PlayerID || ""] ||
              league.playerDetails[a.PlayerID || ""].contractshares === null ||
              league.playerDetails[a.PlayerID || ""].contractshares === 0
            )
              return 1;
            if (
              !league.playerDetails[b.PlayerID || ""] ||
              league.playerDetails[b.PlayerID || ""].contractshares === null ||
              league.playerDetails[b.PlayerID || ""].contractshares === 0
            )
              return -1;

            return (courtTimes[a.PlayerID || ""]?.minutes || 0) /
              (league.playerDetails[a.PlayerID || ""]?.contractshares || 1) >
              (courtTimes[b.PlayerID || ""]?.minutes || 0) /
                (league.playerDetails[b.PlayerID || ""]?.contractshares || 1)
              ? 1
              : -1;
          } else if (event.priorityPolicy === "firstAvailable") {
            return (event.availabilities[a.PlayerID || ""]
              ?.dateFirstAvailable || dayjs().toISOString()) >
              (event.availabilities[b.PlayerID || ""]?.dateFirstAvailable ||
                dayjs().toISOString())
              ? 1
              : -1;
          } else {
            return a.name > b.name ? 1 : -1;
          }
        }
      )
      .map((p, index) => {
        return { ...p, index: index + 1 };
      });

    if (event) {
      return (
        <div ref={setNodeRef} style={style}>
          <Box sx={{ marginTop: 1, minHeight: 50, marginBottom: 1 }}>
            <Stack sx={{ alignItems: "center" }}>
              <OriginalPlayerPostit
                postitname="freeform player"
                mid="new"
                position=""
              />
              <Stack direction="row" sx={{ marginTop: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {sortedPlayers
                    .filter(
                      (pwa) =>
                        pwa.availability &&
                        pwa.availability !== "none" &&
                        pwa.availability !== "unknown" &&
                        !pwa.playing
                    )
                    // .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((p, index) => (
                      <PlayerButton
                        key={p.PlayerID}
                        // matchesPlayersAndSpots={event.matches}
                        player={p}
                        playerDragged={playerDragged}
                        forAvailability={true}
                        currentPlayer={currentPlayer}
                        setCurrentPlayer={setCurrentPlayer}
                        // lid={lid}
                        index={
                          event.priorityPolicy !== "none" ? p.index : undefined
                        }
                      />
                    ))}
                  {/* </Box>
              </Stack>
              <Stack direction="row">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                > */}
                  {sortedPlayers
                    .filter(
                      (pwa) => pwa.availability === "none" && !pwa.playing
                    )
                    // .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((p, index) => (
                      <PlayerButton
                        key={p.PlayerID}
                        // matchesPlayersAndSpots={event.matches}
                        player={p}
                        playerDragged={playerDragged}
                        forAvailability={true}
                        currentPlayer={currentPlayer}
                        setCurrentPlayer={setCurrentPlayer}
                        // lid={lid}
                        index={
                          event.priorityPolicy !== "none" ? p.index : undefined
                        }
                      />
                    ))}
                  {/* </Box>
              </Stack>
              <Stack direction="row">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                > */}
                  {sortedPlayers
                    .filter(
                      (pwa) =>
                        (!pwa.availability || pwa.availability === "unknown") &&
                        !pwa.playing
                    )
                    // .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((p, index) => (
                      <PlayerButton
                        key={p.PlayerID}
                        // matchesPlayersAndSpots={event.matches}
                        player={p}
                        playerDragged={playerDragged}
                        forAvailability={true}
                        currentPlayer={currentPlayer}
                        setCurrentPlayer={setCurrentPlayer}
                        // lid={lid}
                        index={
                          event.priorityPolicy !== "none" ? p.index : undefined
                        }
                      />
                    ))}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </div>
      );
    } else return <></>;
  } else return <></>;
};
