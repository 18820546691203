import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  runTransaction,
  doc,
  // updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  Box,
  Typography,
  Stack,
  Button,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

// MUI ICONS

// TYPES

import { AdminContext, removePlayer } from "../App";
import {
  EventType,
  PlayerDetailsTypeWithAvailability,
  AvailabilityValueType,
} from "../types";

import { AppContext } from "../App";

import { style } from "./Event";
import { setPlayerAvailability } from "../App";

type EventAvailabilityFormProps = {
  showModal: boolean;
  setShowModal: (b: boolean) => void;
  player: PlayerDetailsTypeWithAvailability;
  lid: string;
  event: EventType;
};

type AvailRadioType = "avail" | "none" | "unknown";

export const EventAvailabilityForm = ({
  showModal,
  setShowModal,
  player,
  lid,
  event,
}: EventAvailabilityFormProps) => {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const [avail, setAvail] = useState<AvailabilityValueType>(
    ([
      "All",
      "prefer singles",
      "prefer doubles",
      "only singles",
      "only doubles",
    ].includes(player.availability || "none")
      ? player.availability
      : "All") as AvailabilityValueType
  );
  const [availRadio, setAvailRadio] = useState<AvailRadioType>(
    [
      "All",
      "prefer singles",
      "prefer doubles",
      "only singles",
      "only doubles",
    ].includes(player.availability || "none")
      ? "avail"
      : player.availability === "none"
      ? "none"
      : "unknown"
  );
  const [askedConfirmation, setAskedConfirmation] = useState(false);

  const resultingAvail = availRadio === "avail" ? avail : availRadio;

  const saveAvailability = () => {
    if (player.PlayerID) {
      setPlayerAvailability(
        state.leagues[lid],
        event,
        player.PlayerID,
        resultingAvail,
        adminState,
        false
      );
    }
    setShowModal(false);
  };

  const leaveLeague = () => {
    if (
      state.leagues[lid] &&
      window.confirm("Sure you want to leave this league?")
    ) {
      removePlayer(player, state.leagues[lid]);
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {player.playing && !askedConfirmation && !player.confirmed ? (
          <Stack direction="column" spacing={3}>
            <Typography sx={{ textAlign: "center" }}>
              Please confirm that you can play
            </Typography>
            <Stack
              direction="row"
              sx={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!player.confirmed && player.PlayerID) {
                    setPlayerAvailability(
                      state.leagues[lid],
                      event,
                      player.PlayerID,
                      "All",
                      adminState,
                      true
                    );
                  }
                  setShowModal(false);
                }}
              >
                Yes, confirm
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  //   setPlayerAvailability(
                  //     state.leagues[lid],
                  //     event,
                  //     player.PlayerID,
                  //     "none",
                  //     adminState,
                  //     false
                  //   );
                  setAskedConfirmation(true);
                }}
              >
                Not yet
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Typography
              sx={{ alignContent: "center", fontSize: "16pt", marginBottom: 3 }}
            >
              {player.name}
            </Typography>
            <Stack direction="row">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={availRadio}
                name="radio-buttons-group"
                onChange={(newvalue) => {
                  setAvailRadio(newvalue.currentTarget.value as AvailRadioType);
                }}
              >
                <FormControlLabel
                  value="avail"
                  control={<Radio />}
                  label="Available"
                />

                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="Not available"
                />
                <FormControlLabel
                  value="unknown"
                  control={<Radio />}
                  label="Unknown"
                />
              </RadioGroup>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Available for...
                </InputLabel>
                <Select
                  disabled={availRadio !== "avail"}
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={avail}
                  label="Available for..."
                  onChange={(e) => {
                    setAvail(e.target.value as AvailabilityValueType);
                  }}
                >
                  <MenuItem value="All">Anything</MenuItem>
                  <MenuItem value="only singles">Only singles</MenuItem>
                  <MenuItem value="prefer singles">Prefer singles</MenuItem>
                  <MenuItem value="prefer doubles">Prefer doubles</MenuItem>
                  <MenuItem value="only doubles">Only doubles</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack
              direction="row"
              sx={{
                marginTop: 3,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button variant="contained" onClick={saveAvailability}>
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={leaveLeague}
              >
                Leave league
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};
