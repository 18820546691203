import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  runTransaction,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

// MUI ICONS

import { EventType, MessageType } from "../types";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { AppContext, updateEvent } from "../App";
import SendIcon from "@mui/icons-material/Send";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import { style } from "./Event";

type EventPriorityProps = {
  event: EventType;
  lid: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const EventPriority = ({
  showModal,
  setShowModal,
  event,
  lid,
}: EventPriorityProps) => {
  const { state, setState } = useContext(AppContext);

  const league = state.leagues[lid];

  const setPriorityPolicy = async (priorityPolicy: string) => {
    // const eventRef = doc(db, "leagues", lid, "events", event.PlayingDayID);
    updateEvent(state.leagues[lid], event, { priorityPolicy: priorityPolicy });
    // await updateDoc(eventRef, "priorityPolicy", priorityPolicy);
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormControl fullWidth sx={{ marginTop: 1 }}>
          <InputLabel id="sorting">Priority policy</InputLabel>
          <Select
            size="small"
            disabled={
              !(
                league.admins.includes(state.loggedPlayer?.uid || "") ||
                state.tokenResult?.claims.license === "sysadmin"
              )
            }
            labelId="sorting"
            label="Priority policy"
            id="sorting-select"
            value={event.priorityPolicy || "none"}
            onChange={(event) => setPriorityPolicy(event.target.value)}
            // input={<OutlinedInput label="By default, send to" />}
            // MenuProps={MenuProps}
          >
            <MenuItem key="none" value="none">
              None
            </MenuItem>
            <MenuItem key="firstAvailable" value="firstAvailable">
              First available
            </MenuItem>
            <MenuItem key="leastCourtTime" value="leastCourtTime">
              Least court time
            </MenuItem>
            <MenuItem
              key="leastCourtTimeAgainstPlan"
              value="leastCourtTimeAgainstPlan"
            >
              Least court time against plan
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Modal>
  );
};
