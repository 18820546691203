import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState } from "react";
import * as _ from "lodash";

import { leagueCourtTimes } from "../processEmails";

// CONTEXTS

import { useContext, SetStateAction, Dispatch } from "react";

// FIREBASE

// MUI

import {
  Paper,
  Box,
  Stack,
  Modal,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  TextField,
  Typography,
} from "@mui/material";

// MUI ICONS

import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";

// TYPES

import { EventType, PlayerDetailsType } from "../types";

import { AppContext, updateEvent } from "../App";

import { hCourtTimeType } from "../types";
import { LeagueType } from "../types";
import { Label, Sort } from "@mui/icons-material";

// STYLES

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type EventRosterProps = {
  league: LeagueType;
  event: EventType;
  closeModal: () => void;
};

type EventStatisticsProps = {
  courtTimes: hCourtTimeType;
  showModal: "none" | "results" | "time";
} & EventRosterProps;

type SortButtonProps = {
  label: string;
  sort: SortType;
  sortBy: SortType;
  upDown: number;
  setUpDown: (a: number) => void;
  setSortBy: (a: SortType) => void;
};

const SortButton = ({
  label,
  sort,
  sortBy,
  upDown,
  setUpDown,
  setSortBy,
}: SortButtonProps) => {
  return (
    <Button
      variant={sortBy === sort ? "contained" : "outlined"}
      sx={{ padding: 1, minWidth: 30 }}
      size="small"
      onClick={() => {
        if (sortBy === sort) {
          setUpDown(-upDown);
        } else {
          setSortBy(sort);
        }
      }}
      // endIcon={
      //   sortBy === "minutes" ? (
      //     upDown === 1 ? (
      //       <NorthIcon fontSize="small" />
      //     ) : (
      //       <SouthIcon fontSize="small" />
      //     )
      //   ) : (
      //     false
      //   )
      // }
    >
      {label}
    </Button>
  );
};

export const EventRoster = ({
  league,
  event,
  closeModal,
}: EventRosterProps) => {
  const { state, setState } = useContext(AppContext);
  const [search, setSearch] = useState("");
  const allplayers = Object.values(
    state.leagues[league.LeagueID].playerDetails
  ).sort((a, b) => (a.fullname > b.fullname ? 1 : -1));
  const players = allplayers
    .filter(
      (pd) =>
        search == "" || pd.fullname.toLowerCase().includes(search.toLowerCase())
    )
    .slice(0, 10);

  return (
    <Modal
      open={true}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <TextField
          label="search by name"
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
          }}
        />

        <table>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>

          {players.map((pd: PlayerDetailsType) => {
            return (
              <tr>
                <td>{pd ? pd.name : "???"}</td>
                <td>
                  <a href={"mailto:" + (pd ? pd.email : "???")}>
                    {pd ? pd.email : "???"}
                  </a>
                </td>
                <td>
                  <a href={"sms:" + (pd ? pd.phone : "???")}>
                    {pd ? pd.phone : "???"}
                  </a>
                </td>
              </tr>
            );
          })}
        </table>

        <Typography>
          {allplayers.length} players{" "}
          {allplayers.length > players.length ? "(partial list shown)" : ""}{" "}
        </Typography>
      </Box>
    </Modal>
  );
};

type SortType =
  | "percentAgainstPlan"
  | "minutes"
  | "player"
  | "contract"
  | "today";

export const EventStatistics = ({
  courtTimes,
  league,
  event,
  showModal,
  closeModal,
}: EventStatisticsProps) => {
  const { state, setState } = useContext(AppContext);
  // const [panel, setPanel] = useState("results");
  const [periods, setPeriods] = useState<string[]>(["start", "today", "end"]);
  const [sortBy, setSortBy] = useState<SortType>("player");
  const [upDown, setUpDown] = useState<number>(1);

  const setCourtTimeOverride = (pid: string, override: string) => {
    if (override === "" || Number.isNaN(Number.parseFloat(override))) {
      const change = { ["courtTimeOverrides." + pid]: null };
      updateEvent(league, event, change);
    } else {
      const change = {
        ["courtTimeOverrides." + pid]: Number.parseFloat(override),
      };
      updateEvent(league, event, change);
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {(() => {
          const courtTimeFoPeriod = _.isEqual(periods, [
            "start",
            "today",
            "end",
          ])
            ? courtTimes
            : leagueCourtTimes(
                league,
                Object.entries(league?.eventsSummary || {})
                  .filter(
                    ([PlayingDayID, es]) =>
                      (periods.includes("start") && es.when < event.when) ||
                      (periods.includes("today") &&
                        PlayingDayID === event.PlayingDayID) ||
                      (periods.includes("end") && es.when > event.when)
                  )
                  .map(([PlayingDayID, es]) => PlayingDayID)
              );

          return (
            <Stack
              direction="column"
              sx={{
                width: "100%",
                display: "flewx",
                alignItems: "center",
              }}
            >
              {showModal == "time" || showModal == "results" ? (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={periods}
                  onChange={(event, newval: string[]) => setPeriods(newval)}
                  aria-label="Platform"
                >
                  <ToggleButton value="start">Start</ToggleButton>
                  <ToggleButton value="today">Today</ToggleButton>
                  <ToggleButton value="end">End</ToggleButton>
                </ToggleButtonGroup>
              ) : (
                <></>
              )}

              {/* <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={panel}
                  exclusive
                  onChange={(event, newval) => setPanel(newval || panel)}
                  aria-label="Platform"
                  sx={{ marginTop: 1 }}
                >
                  <ToggleButton value="courttime">Court time</ToggleButton>
                  <ToggleButton value="results">Results</ToggleButton>
                </ToggleButtonGroup> */}

              <Box
                sx={{
                  marginTop: 2,
                  maxHeight: "400px",
                  overflow: "auto",
                  fontSize: "14px",
                }}
              >
                <table>
                  {showModal === "time" ? (
                    <tr>
                      <th>
                        <SortButton
                          label="Player"
                          sort="player"
                          upDown={upDown}
                          setUpDown={setUpDown}
                          sortBy={sortBy}
                          setSortBy={setSortBy}
                        />
                      </th>
                      <th>
                        <SortButton
                          label="Today"
                          sort="today"
                          upDown={upDown}
                          setUpDown={setUpDown}
                          sortBy={sortBy}
                          setSortBy={setSortBy}
                        />
                      </th>
                      <th>
                        <SortButton
                          label="Mins"
                          sort="minutes"
                          upDown={upDown}
                          setUpDown={setUpDown}
                          sortBy={sortBy}
                          setSortBy={setSortBy}
                        />
                      </th>
                      <th>
                        <SortButton
                          label="Plan"
                          sort="contract"
                          upDown={upDown}
                          setUpDown={setUpDown}
                          sortBy={sortBy}
                          setSortBy={setSortBy}
                        />
                      </th>
                      <th>
                        <SortButton
                          label="%"
                          sort="percentAgainstPlan"
                          upDown={upDown}
                          setUpDown={setUpDown}
                          sortBy={sortBy}
                          setSortBy={setSortBy}
                        />
                      </th>
                    </tr>
                  ) : showModal === "results" ? (
                    <tr>
                      <th>Player</th>

                      <th>w</th>
                      <th>l</th>
                      <th>d</th>
                    </tr>
                  ) : (
                    <tr>
                      <th>Player</th>
                      <th>email</th>
                      <th>phone</th>
                    </tr>
                  )}

                  {Object.entries(courtTimes)
                    .sort(([ka, a], [kb, b]) => {
                      var courtTimeA = league?.eventsSummary
                        ? league.eventsSummary[event.PlayingDayID]
                            ?.playerTimeAndResults[ka]?.minutes || 0
                        : 0;
                      var courtTimeOverrideA = event.courtTimeOverrides
                        ? event.courtTimeOverrides[ka]
                        : undefined;

                      var courtTimeB = league?.eventsSummary
                        ? league.eventsSummary[event.PlayingDayID]
                            ?.playerTimeAndResults[kb]?.minutes || 0
                        : 0;
                      var courtTimeOverrideB = event.courtTimeOverrides
                        ? event.courtTimeOverrides[kb]
                        : undefined;

                      switch (sortBy) {
                        case "today":
                          return (courtTimeOverrideA || courtTimeA) >
                            (courtTimeOverrideB || courtTimeB)
                            ? 1 * upDown
                            : -1 * upDown;
                        case "percentAgainstPlan":
                          const percentA =
                            a.minutes /
                            (league.playerDetails[ka]?.contractshares ||
                              0.0001);
                          const percentB =
                            b.minutes /
                            (league.playerDetails[kb]?.contractshares ||
                              0.0001);
                          return percentA > percentB ? 1 * upDown : -1 * upDown;
                        case "contract":
                          return (league.playerDetails[ka].contractshares ||
                            -1) >
                            (league.playerDetails[kb].contractshares || -1)
                            ? 1 * upDown
                            : -1 * upDown;
                        case "minutes":
                          return a.minutes > b.minutes
                            ? 1 * upDown
                            : -1 * upDown;
                        default:
                          return league.playerDetails[ka].name >
                            league.playerDetails[kb].name
                            ? 1 * upDown
                            : -1 * upDown;
                      }
                    })
                    .map(([k, v]) => {
                      var courtTime = league?.eventsSummary
                        ? league.eventsSummary[event.PlayingDayID]
                            ?.playerTimeAndResults[k]?.minutes
                        : 0;
                      var courtTimeOverride = event.courtTimeOverrides
                        ? event.courtTimeOverrides[k]
                        : undefined;

                      return showModal == "time" ? (
                        <tr>
                          <td>
                            <Button
                              size="small"
                              variant={
                                courtTimeOverride ? "contained" : "outlined"
                              }
                              onClick={() => {
                                if (
                                  league.admins.includes(
                                    state.loggedPlayer?.uid || ""
                                  ) ||
                                  state.tokenResult?.claims.license ===
                                    "sysadmin"
                                ) {
                                  const newoverride = window.prompt(
                                    "New override",
                                    (courtTimeOverride || 0).toString()
                                  );
                                  setCourtTimeOverride(k, newoverride || "");
                                }
                              }}
                            >
                              {state.leagues[league.LeagueID].playerDetails[k]
                                ? state.leagues[league.LeagueID].playerDetails[
                                    k
                                  ].name
                                : "???"}
                            </Button>
                          </td>
                          <td>{courtTimeOverride || courtTime}</td>
                          <td>
                            {courtTimeFoPeriod[k]
                              ? courtTimeFoPeriod[k].minutes
                              : ""}
                          </td>
                          <td>
                            {(state.leagues[league.LeagueID].playerDetails[k]
                              ?.contractshares || 0) * 60}
                          </td>
                          <td>
                            {state.leagues[league.LeagueID].playerDetails[k]
                              ?.contractshares == null
                              ? ""
                              : (
                                  (100 * v.minutes) /
                                  ((state.leagues[league.LeagueID]
                                    .playerDetails[k]?.contractshares || 1) *
                                    60)
                                ).toFixed(1)}
                            %
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            {state.leagues[league.LeagueID].playerDetails[k]
                              ? state.leagues[league.LeagueID].playerDetails[k]
                                  .name
                              : "???"}
                          </td>

                          <td>
                            {courtTimeFoPeriod[k] ? courtTimeFoPeriod[k].w : ""}
                          </td>
                          <td>
                            {courtTimeFoPeriod[k] ? courtTimeFoPeriod[k].l : ""}
                          </td>
                          <td>
                            {courtTimeFoPeriod[k] ? courtTimeFoPeriod[k].d : ""}
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </Box>
            </Stack>
          );
        })()}
      </Box>
    </Modal>
  );
};
