import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";
import dayjs, { Dayjs } from "dayjs";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import { collection, getDocs, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { EventType, PlayerDetailsType } from "../types";

// MUI ICONS

import { AppContext } from "../App";
import { LeagueType } from "../types";

import { style } from "./Event";
import { firestoreAutoId } from "../processEmails";
import { EditLeague } from "./EditLeague";

function usePrevious(value: boolean) {
  const ref = useRef<boolean>();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;

export const LeaguePicker = (props: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { showModal, setShowModal } = props;

  const { state, setState } = useContext(AppContext);
  const [lid, setLid] = useState(Object.keys(state.leagues)[0]);
  const [showForm, setShowForm] = useState(false);
  const prevShowForm = usePrevious(showForm);
  const [leagueOrStandalone, setLeagueOrStandalone] = useState<
    "league" | "standalone"
  >("standalone");

  const leagues = Object.keys(state.leagues).filter(
    (lid) =>
      !state.leagues[lid].standalone &&
      state.loggedPlayer &&
      (state.leagues[lid].admins.includes(state.loggedPlayer.uid) ||
        state.tokenResult?.claims.license === "sysadmin")
  );

  useEffect(() => {
    if (prevShowForm == true && showForm == false) {
      setShowModal(false);
    }
  }, [showForm]);

  const blankEvent: EventType = {
    PlayingDayID: firestoreAutoId(),
    when: state.currentDay,
    LeagueID: "",
    players: [state.loggedPlayer?.uid || ""],
    matches: {},
    availabilities: {},
    description: "",
    location: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    nbopenspots: 0,
    openspots: [],
    priorityPolicy: "none",
    status: "",
    messages: [],
  };

  const showEventForm = () => {
    blankEvent.LeagueID = leagueOrStandalone === "league" ? lid : "";
    blankEvent.priorityPolicy =
      leagueOrStandalone === "league"
        ? state.leagues[lid].defaultPriorityPolicy
        : "none";
    setShowForm(true);
  };

  const mintNewLeague = () => {
    // var newevent: EventType = JSON.parse(JSON.stringify(event));
    // newevent = {
    //   ...newevent,
    //   description: eventDescription || "",
    //   location: eventLocation || "",
    //   when: eventDateTime.toISOString(),
    //   timezone: eventTimeZone,
    // };

    // const events: { [PlayingDayID: string]: EventType } = {};
    // events[newevent.PlayingDayID] = newevent;
    const playerDetails: { [PlayerID: string]: PlayerDetailsType } = {};

    if (state.loggedPlayer) {
      playerDetails[state.loggedPlayer.uid] = {
        name: state.loggedPlayer.displayName || "anonymous",
        fullname: state.loggedPlayer.displayName || "anonymous",
        email: state.loggedPlayer.email || "unknown email",
        phone: "unknown phone",
        contractshares: null,
        external: false,
        // isadmin: true,
      };
    }
    const newLeague: LeagueType = {
      LeagueID: firestoreAutoId(),
      name: (state.loggedPlayer?.displayName || "") + "'s event",
      // events: events,
      players: [state.loggedPlayer?.uid || ""],
      admins: [state.loggedPlayer?.uid || ""],
      playerDetails: playerDetails,
      closed: false,
      standalone: true,
      eventtype: "match",
      minutes: 90,
      courtTimeByEvent: {},
      defaultPriorityPolicy: "none",
      invitations: {},
    };
    // newLeague.events[newevent.PlayingDayID].LeagueID = newLeague.LeagueID;
    // newevent.LeagueID = newLeague.LeagueID;
    // newevent.players = [state.loggedPlayer?.uid || ""];

    return newLeague;
  };

  if (state) {
    if (showForm)
      return (
        // <EventForm
        //   event={blankEvent}
        //   showModal={showForm}
        //   setShowModal={setShowForm}
        //   lid={leagueOrStandalone == "league" ? lid : ""}
        // />
        <EditLeague
          event={blankEvent}
          league={
            leagueOrStandalone === "league"
              ? state.leagues[lid]
              : mintNewLeague()
          }
          showModal={showForm}
          setShowModal={setShowForm}
          isnewleague={leagueOrStandalone === "standalone"}
          isnewevent={true}
        />
      );
    else
      return (
        <>
          <Modal
            open={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Stack>
                <Typography sx={{ alignContent: "center", fontSize: "16pt" }}>
                  Create a new event...
                </Typography>
                <Stack direction="column" spacing={2}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={leagueOrStandalone}
                    name="radio-buttons-group"
                    onChange={(event) => {
                      if (
                        ["sysadmin", "professional"].includes(
                          state.tokenResult?.claims.license as string
                        )
                      ) {
                        setLeagueOrStandalone(
                          event.currentTarget.value as "league" | "standalone"
                        );
                      } else {
                        alert(
                          "Please upgrade your plan to professional to manage leagues"
                        );
                      }
                    }}
                  >
                    <FormControlLabel
                      value={"standalone"}
                      control={<Radio />}
                      label="as a standalone event"
                      sx={{ marginTop: 1 }}
                    />
                    <Typography>
                      You will invite players individually
                    </Typography>

                    <FormControlLabel
                      value={"league"}
                      control={<Radio />}
                      disabled={leagues.length == 0}
                      label="in a league"
                      sx={{ marginTop: 1 }}
                    />
                    <Typography>
                      All league players will be offered spots
                    </Typography>
                  </RadioGroup>

                  <FormControl>
                    <InputLabel id="leagueselectlabel">League</InputLabel>
                    <Select
                      labelId="leagueselectlabel"
                      id="leagueselect"
                      value={lid}
                      label="League"
                      // sx={{ marginTop: 2 }}
                      onChange={(event) => {
                        setLid(event.target.value);
                        setLeagueOrStandalone("league");
                      }}
                    >
                      {leagues.map((lid) => (
                        <MenuItem value={lid}>
                          {state.leagues[lid].name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Button
                    sx={{ marginTop: 2 }}
                    variant="contained"
                    onClick={() => {
                      showEventForm();
                    }}
                  >
                    create
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Modal>
        </>
      );
  } else {
    return <></>;
  }
};
