import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  Component,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import { collection, getDocs, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Event } from "./Event";
import { EventType, hEventType } from "../types";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import FilterListIcon from "@mui/icons-material/FilterList";
import BuildIcon from "@mui/icons-material/Build";
import ReportIcon from "@mui/icons-material/Report";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";

import { LeagueType } from "../types";
import { AppContext } from "../App";

import { style } from "./Event";
import { availabilityTable, computeAlerts } from "../processEmails";
import { EventTitleHeader } from "./EventTitle";
import { JsxElement } from "typescript";

type EventAlertProps = {
  event: EventType;
};

const colorTranslation: { [c: string]: string } = {
  red: "#fee",
  orange: "#fec",
  green: "#efc",
  blue: "#eef",
};

const icons: { [color: string]: JSX.Element } = {
  red: (
    <ReportIcon fontSize="large" sx={{ color: "#FF8376", paddingRight: 1 }} />
  ),
  orange: (
    <ErrorIcon fontSize="large" sx={{ color: "orange", paddingRight: 1 }} />
  ),
  green: (
    <CheckCircleIcon
      fontSize="large"
      sx={{ color: "#60A84E", paddingRight: 1 }}
    />
  ),
  blue: (
    <InfoIcon fontSize="large" sx={{ color: "#1876D2", paddingRight: 1 }} />
  ),
};

export const EventAlert = ({ event }: EventAlertProps) => {
  const { state, setState } = useContext(AppContext);

  if (state && state.loggedPlayer) {
    const uid = state.loggedPlayer.uid;
    const league = state.leagues[event.LeagueID];

    const alerts = computeAlerts(league, event, state.loggedPlayer.uid);

    // console.log(JSON.stringify(alerts, null, 2));
    return (
      <Stack direction="column" spacing={0.2}>
        {Object.entries(alerts).map(([color, as]) =>
          as.map((a, index2) => (
            <Stack
              key={event.PlayingDayID + "-" + color + "-" + index2.toString()}
              direction="row"
              sx={{
                backgroundColor: colorTranslation[color],
                padding: 1,
                alignItems: "center",
              }}
            >
              {icons[color]}
              <Typography
                sx={{
                  flexGrow: 1,
                  lineHeight: 1.2,
                  fontSize: "10pt",
                  textAlign: "left",
                }}
              >
                {a.text}
              </Typography>
            </Stack>
          ))
        )}
      </Stack>
    );
  } else {
    return <></>;
  }
};
