import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, Dispatch, SetStateAction, useContext } from "react";

import * as _ from "lodash";

// FIREBASE

// MUI

import {
  Box,
  Typography,
  Stack,
  Button,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  Tooltip,
  IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// TYPES

import { PlayerDetailsType, PlayerDetailsTypeWithAvailability } from "../types";

// STYLE

import { style } from "./Event";
import { AppContext, removePlayer, updateLeague } from "../App";

export const LeaguePlayerForm = (props: {
  showModal: boolean;
  setShowModal: (a: boolean) => void;
  player: PlayerDetailsTypeWithAvailability;
  // lid: string;
  save: (
    newPlayer: PlayerDetailsType & { PlayerID: string },
    playerIsAdmin: boolean
  ) => void;
}) => {
  const { state, setState } = useContext(AppContext);

  const {
    showModal,
    setShowModal,
    player,
    // lid,
    save,
  } = props;

  const lid = state.currentLeague || "";

  const [playerName, setPlayerName] = useState(player.name);
  const [playerTime, setPlayerTime] = useState(player.contractshares);
  const [playerExternal, setPlayerExternal] = useState(
    player.external ? true : false
  );
  const [playerIsAdmin, setPlayerIsAdmin] = useState(
    player.PlayerID !== undefined &&
      state.leagues[lid].admins.includes(player.PlayerID)
  );

  const savePlayer = () => {
    // saving
    if (player.PlayerID) {
      const newplayer: PlayerDetailsType & { PlayerID: string } = {
        fullname: player.fullname,
        email: player.email,
        phone: player.phone || "",
        name: playerName,
        contractshares: playerTime,
        external: playerExternal,
        // isadmin: playerIsAdmin,
        PlayerID: player.PlayerID,
      };
      save(newplayer, playerIsAdmin);
    }
  };

  const removePlayerLocal = () => {
    if (player.PlayerID && window.confirm("Remove player from league?")) {
      removePlayer(player, state.leagues[lid]);
      setShowModal(false);
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={style}>
          <Stack spacing={2}>
            <Stack direction="column" spacing={0}>
              <Typography sx={{ alignContent: "center", fontSize: "16pt" }}>
                Editing league player
              </Typography>
              <Typography
                sx={{
                  fontSize: "10pt",
                  paddingBottom: 2,
                  color: "grey",
                }}
              >
                {player.email}
              </Typography>
            </Stack>

            <TextField
              value={playerName}
              label="Short name in this league"
              onChange={(event) => {
                setPlayerName(event.currentTarget.value);
              }}
            />

            <TextField
              value={playerTime}
              label="Court time contracted"
              onChange={(event) => {
                setPlayerTime(Number(event.currentTarget.value));
              }}
            />

            <Stack
              direction="row"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={playerIsAdmin}
                    onChange={(event) => {
                      setPlayerIsAdmin(!playerIsAdmin);
                    }}
                  />
                }
                label="Is admin"
              />

              <IconButton
                size="small"
                onClick={() => {
                  alert(
                    `Admins can manage this league: create events, invite / remove players, rename players, assign contract shares, send messages, etc. Users need to have a professional license to be able to manage leagues.`
                  );
                }}
              >
                <InfoIcon />
              </IconButton>
            </Stack>

            <Stack
              direction="row"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={playerExternal}
                    onChange={(event) => {
                      setPlayerExternal(!playerExternal);
                    }}
                  />
                }
                label="External"
              />

              <IconButton
                size="small"
                onClick={() => {
                  alert(
                    `Use this flag to indicate players who occasionally participate but are not core to the group. You can identify these when you send emails to exclude them from regular communications.`
                  );
                }}
              >
                <InfoIcon />
              </IconButton>
            </Stack>

            <Stack
              direction="row"
              sx={{ display: "flex", justifyContent: "space-between" }}
              spacing={1}
            >
              <Button variant="contained" onClick={() => savePlayer()}>
                Save
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removePlayerLocal()}
              >
                Remove
              </Button>

              <Button
                onClick={() => {
                  setShowModal(false);
                }}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </LocalizationProvider>
    </Modal>
  );
};
