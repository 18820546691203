import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";

import {
  hEmailGroupsType,
  processGroups,
  processToList,
  processTemplate2,
  computeTimeToSend,
  buildEmailDataPass1,
  buildEmailDataPass2,
  getSubjectContentsOptions,
  EmailDataType,
  renderPartial,
  renderPartials,
} from "../processEmails";

// Handlebars

import Handlebars from "handlebars";

// LODASH

import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";
import { AdminContext } from "../App";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  doc,
  deleteField,
  addDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Switch,
  Icon,
  Menu,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Theme, useTheme } from "@mui/material/styles";

//
// TYPES
//

import {
  EventType,
  LeagueType,
  EmailWhenCategoryType,
  EmailGroupType,
  ToLessGroups,
  EmailTemplateOption,
  EmailPartialType,
  PartialType,
} from "../types";
import { EmailType } from "../types";

// MUI ICONS

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DataObjectIcon from "@mui/icons-material/DataObject";

import { AppContext } from "../App";
import { firestoreAutoId } from "../processEmails";

//
// Finally (because there is the extend command), DAYJS
//
import dayjs, { Dayjs, max } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { JsonTreeBrowser } from "./EmailTemplates";
import { DefaultRecipients } from "./EmailsCompose";
dayjs.extend(relativeTime);

//
// CONSTANTS
//

export const groups: EmailGroupType[] = [
  "All",
  "Playing",
  "Not playing",
  "Known availability",
  "Unknown availability",
  "Available",
  "Not available",
  "External",
  "Opted out emails",
  "Not external",
  "Confirmed",
  "Not confirmed",
  "Administrators",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: any[], theme: Theme) {
  return {
    fontWeight:
      !personName || personName.indexOf(name as any) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const composestyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "#eef",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type PartialOptionValueProps = {
  option: EmailTemplateOption;
  index: number;
  setOptionValue: (val: boolean | string, index: number) => void;
  emailData: EmailDataType;
  jsonTree?: Object;
};

const PartialOptionValue = ({
  option,
  index,
  setOptionValue,
  emailData,
  jsonTree,
}: PartialOptionValueProps) => {
  const [editTextField, setEditTextField] = useState(false);
  const [showObjectPicker, setShowObjectPicker] = useState(false);
  const inputRef = React.useRef();
  const [selectionStart, setSelectionStart] = React.useState();
  const updateSelectionStart = () => {
    if (inputRef.current)
      setSelectionStart((inputRef.current as any).selectionStart);
  };

  const insertText = (s: string) => {
    if (selectionStart && option.value && typeof option.value == "string") {
      const newtext =
        option.value.slice(0, selectionStart) +
        s +
        option.value.slice(selectionStart);

      setOptionValue(newtext as string, index);
      setTimeout(() => {
        (inputRef.current as any).setSelectionRange(
          selectionStart,
          newtext.length
        );
      });
    }
  };

  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      {jsonTree && showObjectPicker ? (
        <JsonTreeBrowser
          jsonTree={jsonTree}
          insertText={insertText}
          close={() => {
            setShowObjectPicker(false);
            if (inputRef) {
              const timeout = setTimeout(() => {
                try {
                  (inputRef.current as any).focus();
                } catch (e) {}
              }, 100);
            }
          }}
        />
      ) : (
        <></>
      )}

      {/* {editTextField && typeof option.value == "string" ? (
        <FullScreenText
          onClose={() => {
            setEditTextField(false);
          }}
          text={option.value}
          setText={(newtext) => {
            setOptionValue(newtext as string, index);
          }}
          jsonTree={emailData}
        />
      ) : (
        <></>
      )} */}
      {option.type === "boolean" ? (
        <Stack direction="row" sx={{ width: "100%" }}>
          <FormControlLabel
            label={
              <Typography sx={{ lineHeight: 1 }}>
                {option.description}
              </Typography>
            }
            control={
              <Checkbox
                checked={option.value ? true : false}
                onChange={(e) => {
                  setOptionValue(option.value ? false : true, index);
                }}
              />
            }
          />
        </Stack>
      ) : (
        <Stack
          direction="column"
          sx={{ width: "100%", marginTop: 1, marginBottom: 1 }}
        >
          <Typography sx={{ lineHeight: 1, paddingBottom: 1 }}>
            {option.description}
          </Typography>
          <Stack direction="row">
            <TextField
              onSelect={updateSelectionStart}
              inputRef={inputRef}
              value={option.value}
              size="small"
              onChange={(e) => {
                setOptionValue(e.target.value, index);
              }}
              multiline
              rows={option.rows ? option.rows : 1}
              sx={{ flexGrow: 1 }}
              // focused
              // autoFocus
            />
            {/* <IconButton
              size="small"
              onClick={() => {
                setEditTextField(true);
              }}
            >
              <EditIcon />
            </IconButton> */}
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShowObjectPicker(true);
              }}
              aria-label="close"
            >
              <DataObjectIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

type PartialPickerProps = {
  onPartialPicked: (PartialID: string) => void;
  onCancel: () => void;
  html: boolean;
};

const PartialPicker = ({
  onPartialPicked,
  onCancel,
  html,
}: PartialPickerProps) => {
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={composestyle}>
        <Stack direction="column" spacing={1}>
          {adminState.partials?.partials
            .filter((p) => p.isHtml === html)
            .map((p) => (
              <Button
                variant="outlined"
                onClick={() => {
                  onPartialPicked(p.PartialID);
                }}
              >
                {p.name}
              </Button>
            ))}
          <Stack direction="row">
            <Button onClick={onCancel} variant="contained" color="secondary">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

type EmailComposePartialRenderProps = {
  partial: EmailPartialType;
  setPartial: (np: EmailPartialType) => void;
  deletePartial: () => void;
  emailData: EmailDataType;
  preview: boolean;
  justText?: boolean;
  isHtml?: boolean;
  onClick?: () => void;
  p: PartialType;
  maxHeight?: number;
};

const EmailComposePartialRender = ({
  partial,
  emailData,
  setPartial,
  deletePartial,
  preview,
  justText,
  isHtml,
  onClick,
  p,
  maxHeight,
}: EmailComposePartialRenderProps) => {
  if (isHtml) {
    return (
      <Box
        sx={{
          maxHeight: maxHeight ? maxHeight : undefined,
          overflow: maxHeight ? "auto" : "visible",
          minHeight: preview ? "0px" : "20px",
          //   width: "100%",
          border: preview ? 0 : 1,
          borderStyle: "dashed",
          marginTop: preview && !justText ? "20px" : "0px", // to account for the <p/> inserted between block
        }}
        className="content"
        dangerouslySetInnerHTML={{
          __html: renderPartial(partial, emailData, p),
        }}
        onClick={() => {
          // if (!justText) {
          //   setEditing(true);
          // }
          if (onClick) onClick();
        }}
      ></Box>
    );
  } else {
    return (
      <Typography
        sx={{
          maxHeight: maxHeight ? maxHeight : undefined,
          overflow: maxHeight ? "auto" : "visible",
        }}
        onClick={() => {
          // if (!justText) {
          //   setEditing(true);
          // }
          if (onClick) onClick();
        }}
      >
        {renderPartial(partial, emailData, p)}
      </Typography>
    );
  }
};

type EmailComposePartialProps = {
  partial: EmailPartialType;
  setPartial: (np: EmailPartialType) => void;
  deletePartial: () => void;
  emailData: EmailDataType;
  preview: boolean;
  justText?: boolean;
  isHtml?: boolean;
  editing: boolean;
  setEditing: (b: boolean | undefined) => void;
};

const EmailComposePartial = (props: EmailComposePartialProps) => {
  const {
    partial,
    emailData,
    setPartial,
    deletePartial,
    preview,
    justText,
    isHtml,
    editing,
    setEditing,
  } = props;
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  // const [editing, setEditing] = useState(false);

  const [localPartial, setLocalPartial] = useState(partial);

  const setOptionValue = (i: number, val: string | boolean) => {
    const newpartial = _.cloneDeep(localPartial);
    newpartial.options[i].value = val;
    // setPartial(newpartial);
    setLocalPartial(newpartial);
  };

  const p = adminState.partials?.partials.find(
    (p) => p.PartialID === partial.PartialID
  );

  if (p) {
    if (editing) {
      return (
        <Modal
          open={editing}
          onClose={() => {
            if (
              _.isEqual(localPartial, partial) ||
              window.confirm("You will lose changes, proceed?")
            ) {
              setEditing(undefined);
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={composestyle}>
            <Stack
              sx={{
                // width: "100%",
                // border: 1,
                // borderStyle: "dashed",
                padding: 1,
              }}
            >
              <Box sx={{ margin: 1, background: "white" }}>
                <EmailComposePartialRender
                  {...props}
                  partial={localPartial}
                  p={p}
                />
              </Box>
              {localPartial.options.map((o, i) => (
                <PartialOptionValue
                  option={o}
                  index={i}
                  setOptionValue={(val) => {
                    setOptionValue(i, val);
                  }}
                  emailData={emailData}
                  jsonTree={emailData}
                />
              ))}
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    deletePartial();
                    setEditing(undefined);
                  }}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setEditing(undefined);
                    setPartial(localPartial);
                  }}
                >
                  Done
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>
      );
    } else {
      return (
        <EmailComposePartialRender
          {...props}
          p={p}
          onClick={() => {
            setEditing(true);
          }}
        />
      );
    }
  } else {
    return <></>;
  }
};

type EmailComposePartialsProps = {
  partials?: EmailPartialType[];
  setPartials: (np: EmailPartialType[]) => void;
  preview: boolean;
  justText?: boolean;
  isHtml?: boolean;
  emailData: EmailDataType;
};

const EmailComposePartials = ({
  partials,
  setPartials,
  preview,
  justText,
  isHtml,
  emailData,
}: EmailComposePartialsProps) => {
  const { state: state, setState: setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const [pickForIndex, setPickForIndex] = useState<number | undefined>(
    undefined
  );
  const [indexEditing, setIndexEditing] = useState<number | undefined>(
    undefined
  );

  const addPartialAt = (index: number, PartialID: string) => {
    const p = adminState?.partials?.partials.find(
      (p) => p.PartialID === PartialID
    );
    if (p) {
      const newPartial: EmailPartialType = {
        PartialID: PartialID,
        EmailPartialID: firestoreAutoId(),
        options: _.cloneDeep(p.options),
      };
      const newPartials = _.cloneDeep(partials || []);
      newPartials.splice(index, 0, newPartial);
      setPartials(newPartials);
      setPickForIndex(undefined);
      setIndexEditing(index);
    }
  };

  const setPartialAt = (index: number, partial: EmailPartialType) => {
    const newPartials = _.cloneDeep(partials || []);
    newPartials.splice(index, 1, partial);
    setPartials(newPartials);
  };

  const deletePartialAt = (index: number) => {
    const newPartials = _.cloneDeep(partials || []);
    newPartials.splice(index, 1);
    setPartials(newPartials);
  };

  return (
    <Stack
      direction="column"
      spacing={0}
      sx={{ maxHeight: "300px", overflow: "auto" }}
    >
      {typeof pickForIndex == "number" ? (
        <PartialPicker
          onCancel={() => {
            setPickForIndex(undefined);
          }}
          onPartialPicked={(PartialID: string) => {
            addPartialAt(pickForIndex, PartialID);
          }}
          html={isHtml ? true : false}
        />
      ) : (
        <></>
      )}

      {!preview ? (
        <IconButton
          size="small"
          onClick={() => {
            setPickForIndex(0);
          }}
        >
          <AddIcon />
        </IconButton>
      ) : (
        <></>
      )}
      {partials?.map((p, i) => (
        <Stack direction="column" key={p.PartialID + "/" + i.toString()}>
          <EmailComposePartial
            key={p.EmailPartialID || i.toString()}
            emailData={emailData}
            partial={p}
            setPartial={(partial: EmailPartialType) => {
              setPartialAt(i, partial);
              setIndexEditing(undefined);
            }}
            deletePartial={() => {
              deletePartialAt(i);
            }}
            preview={preview}
            justText={justText}
            isHtml={isHtml}
            editing={i === indexEditing}
            setEditing={(b: boolean | undefined) => {
              if (b) {
                setIndexEditing(i);
              } else {
                setIndexEditing(undefined);
              }
            }}
          />
          {!preview ? (
            <IconButton
              size="small"
              onClick={() => {
                setPickForIndex(i + 1);
              }}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

type ComposeProps2 = {
  league: LeagueType;
  event: EventType;
  showModal: boolean;
  setShowModal: (n: boolean) => void;
  lid: string;
  email: EmailType;
};

export const Compose2 = ({
  league,
  event,
  showModal,
  setShowModal,
  lid,
  email,
}: ComposeProps2) => {
  const { state: state, setState: setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  //   const [emailData, setEmailData] = useState(
  //     buildEmailData(league, event, [], state.loggedPlayer as User)
  //   );

  const [editing, setEditing] = useState<EmailType>(_.cloneDeep(email));
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  // const [preview, setPreview] = useState(true);

  // will store locally the compute groups
  // recomputed with app context or admin context are changed
  const [groups, setGroups] = useState<hEmailGroupsType>({});
  const [toList, setToList] = useState<string[]>([]);
  const [toListOptedOut, setToListOptedOut] = useState<string[]>([]);

  // preview switch
  const [preview, setPreview] = useState(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onSave = async () => {
    if (state.loggedPlayer) {
      const toSave: EmailType = _.cloneDeep(editing);
      if (toSave.EmailID == "") {
        toSave.EmailID = firestoreAutoId();
      }
      const emailRef = doc(
        db,
        "leagues",
        lid,
        "events",
        event.PlayingDayID,
        "emails",
        toSave.EmailID
      );

      // can't save undefined as a field value, removing fields
      if (!editing.subject) delete toSave.subject;
      if (!editing.contents) delete toSave.contents;

      // setting time to send
      toSave.sendAt = computeTimeToSend(event, editing);
      toSave.author = state.loggedPlayer.uid;

      if (
        toSave.sendAt > dayjs().toJSON() ||
        window.confirm("Email will be sent immeditaly, proceed?")
      ) {
        // console.log(JSON.stringify(toSave, null, 2));

        if (toSave.TemplateID === undefined) {
          delete toSave.TemplateID;
        }
        await setDoc(emailRef, toSave);
        setShowModal(false);
      }
    }
  };

  const onDelete = async () => {
    if (event.PlayingDayID && window.confirm("Delete email?")) {
      const emailRef = doc(
        db,
        "leagues",
        lid,
        "events",
        event.PlayingDayID,
        "emails",
        editing.EmailID
      );
      await deleteDoc(emailRef);
      setShowModal(false);
    }
  };

  //
  // recomputing the groups whenever the state changes
  //

  useEffect(() => {
    setGroups(processGroups(state.leagues[lid], event));
  }, [state, adminState]);

  //
  // recomputing the tolist whenever the groups change
  //

  useEffect(() => {
    const processedToList = processToList(editing, groups).sort((a, b) =>
      state.leagues[lid].playerDetails[a].fullname >
      state.leagues[lid].playerDetails[b].fullname
        ? 1
        : -1
    );
    setToList(processedToList);
    const processedToListOptedOut = _.intersection(
      processedToList,
      groups["Opted out emails"]
    );
    setToListOptedOut(processedToListOptedOut);
  }, [groups, editing]);

  const groupString = () => {
    var res = "";
    if (editing.toGroups && editing.toGroups.length > 0) {
      res += "+" + editing.toGroups.join(" +");
    }
    if (editing.lessGroups && editing.lessGroups.length > 0) {
      res += " -" + editing.lessGroups.join(" -");
    }
    if (editing.toIndividuals && editing.toIndividuals.length > 0) {
      res +=
        " +" +
        editing.toIndividuals
          .map((ti) => state.leagues[lid].playerDetails[ti].fullname)
          .join(" +");
    }
    if (editing.lessIndividuals && editing.lessIndividuals.length > 0) {
      res +=
        " -" +
        editing.lessIndividuals
          .map((ti) => state.leagues[lid].playerDetails[ti].fullname)
          .join(" -");
    }
    return res;
  };

  //
  // we compute what we are going to show for subject and contents
  // could be editing or could be preview
  //

  //   const subjectContentsOptions = getSubjectContentsOptions(
  //     editing,
  //     adminState.templates,
  //     adminState.partials || { partials: [] }
  //   );

  if (state.loggedPlayer && adminState.partials) {
    var emailData = buildEmailDataPass1(
      state.leagues[lid],
      event,
      editing.individualized ? toList.slice(0, 1) : toList
    );
    emailData = buildEmailDataPass2(
      state.leagues[lid],
      event,
      editing.individualized ? toList.slice(0, 1) : toList,
      state.loggedPlayer,
      emailData,
      editing
    );

    // console.log(emailData);

    return (
      <Modal
        open={showModal ? true : false}
        onClose={() => {
          if (window.confirm("You will lose changes, close?")) {
            setShowModal(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={composestyle}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Stack direction="row" spacing={1}>
                <Stack direction="column" sx={{ textAlign: "right" }}>
                  <Typography>To:</Typography>
                </Stack>

                <Typography
                  onClick={(event) => {
                    if (expanded === "panel1") {
                      event.stopPropagation();
                      alert(
                        _.difference(toList, toListOptedOut)
                          .map(
                            (tl) =>
                              state.leagues[lid].playerDetails[tl].fullname
                          )
                          .join(", ")
                      );
                    }
                  }}
                >
                  {_.difference(toList, toListOptedOut)
                    // if expanded, we show 6, otherwise 2
                    .slice(0, expanded === "panel1" ? 6 : 1)
                    .map((tl) => state.leagues[lid].playerDetails[tl].fullname)
                    .join(", ") +
                    (_.difference(toList, toListOptedOut).length >
                    (expanded === "panel1" ? 6 : 1)
                      ? " +" +
                        (
                          _.difference(toList, toListOptedOut).length -
                          (expanded === "panel1" ? 6 : 1)
                        ).toString()
                      : "")}
                </Typography>
                {toListOptedOut.length > 0 ? (
                  <Typography
                    sx={{ color: "red" }}
                    onClick={(event) => {
                      if (expanded === "panel1") {
                        event.stopPropagation();
                        alert(
                          "These players opted out of emails: " +
                            toListOptedOut
                              .map(
                                (tl) =>
                                  state.leagues[lid].playerDetails[tl].fullname
                              )
                              .join(", ")
                        );
                      }
                    }}
                  >
                    {toListOptedOut
                      // if expanded, we show 6, otherwise 2
                      .slice(0, expanded === "panel1" ? 6 : 1)
                      .map(
                        (tl) => state.leagues[lid].playerDetails[tl].fullname
                      )
                      .join(", ") +
                      (toListOptedOut.length > (expanded === "panel1" ? 6 : 1)
                        ? " +" +
                          (
                            toListOptedOut.length -
                            (expanded === "panel1" ? 6 : 1)
                          ).toString()
                        : "")}
                  </Typography>
                ) : (
                  <></>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <DefaultRecipients
                formValues={editing}
                setFormValues={
                  setEditing as (et: EmailType | ToLessGroups) => void
                }
                lid={lid}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              When: {dayjs(editing.sendAt).format("LLLL")},{" "}
              {dayjs(editing.sendAt).fromNow()}
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={editing.whenCategory || "never"}
                  name="radio-buttons-group"
                  onChange={(newvalue) => {
                    setEditing({
                      ...editing,
                      whenCategory: newvalue.currentTarget
                        .value as EmailWhenCategoryType,
                      sendAt: computeTimeToSend(event, {
                        ...editing,
                        whenCategory: newvalue.currentTarget
                          .value as EmailWhenCategoryType,
                      }),
                    });
                  }}
                >
                  <FormControlLabel
                    value="never"
                    control={<Radio />}
                    label="Don't send - hold"
                  />
                  <FormControlLabel
                    value="immediately"
                    control={<Radio />}
                    label="Send right away"
                  />
                  <FormControlLabel
                    value="before"
                    control={<Radio />}
                    label="before the event - specify below:"
                  />
                </RadioGroup>
                {(() => {
                  const days = Math.floor(editing.whenValue / (60 * 24));
                  const hours = Math.floor(
                    (editing.whenValue - days * (60 * 24)) / 60
                  );
                  const minutes = Math.floor(
                    editing.whenValue - days * (60 * 24) - hours * 60
                  );

                  return (
                    <Stack direction="row" spacing={1}>
                      <TextField
                        label="days"
                        value={days.toString()}
                        onChange={(newvalue) => {
                          const nb =
                            Number.parseInt(newvalue.currentTarget.value) || 0;
                          const whenValue = nb * 60 * 24 + hours * 60 + minutes;
                          setEditing({
                            ...editing,
                            whenValue: whenValue,
                            sendAt: computeTimeToSend(event, {
                              ...editing,
                              whenValue: whenValue,
                            }),
                          });
                        }}
                      />
                      <TextField
                        label="hours"
                        value={hours.toString()}
                        onChange={(newvalue) => {
                          const nb =
                            Number.parseInt(newvalue.currentTarget.value) || 0;
                          const whenValue = days * 24 * 60 + nb * 60 + minutes;
                          if (!isNaN(nb)) {
                            setEditing({
                              ...editing,
                              whenValue: whenValue,
                              sendAt: computeTimeToSend(event, {
                                ...editing,
                                whenValue: whenValue,
                              }),
                            });
                          }
                        }}
                      />
                      <TextField
                        value={minutes.toString()}
                        label="minutes"
                        onChange={(newvalue) => {
                          const nb =
                            Number.parseInt(newvalue.currentTarget.value) || 0;
                          const whenValue = days * 24 * 60 + hours * 60 + nb;
                          if (!isNaN(nb)) {
                            setEditing({
                              ...editing,
                              whenValue: whenValue,
                              sendAt: computeTimeToSend(event, {
                                ...editing,
                                whenValue: whenValue,
                              }),
                            });
                          }
                        }}
                      />
                    </Stack>
                  );
                })()}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Subject:{" "}
              {renderPartials(
                editing.subjectPartials || [],
                emailData,
                adminState.partials
              )}
            </AccordionSummary>
            <AccordionDetails>
              <EmailComposePartials
                emailData={emailData}
                key={editing.EmailID}
                partials={editing.subjectPartials}
                setPartials={(newPartials: EmailPartialType[]) => {
                  setEditing({ ...editing, subjectPartials: newPartials });
                }}
                preview={preview}
                justText={false}
                isHtml={false}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              Contents
            </AccordionSummary>
            <AccordionDetails>
              <EmailComposePartials
                emailData={emailData}
                key={editing.EmailID}
                partials={editing.contentPartials}
                setPartials={(newPartials: EmailPartialType[]) => {
                  setEditing({ ...editing, contentPartials: newPartials });
                }}
                preview={preview}
                isHtml={true}
              />
            </AccordionDetails>
          </Accordion>

          <FormGroup sx={{ paddingTop: 2, marginLeft: 3, marginTop: -1 }}>
            <FormControlLabel
              control={<Switch checked={preview} />}
              label="preview"
              onChange={(event) => {
                setPreview(!preview);
              }}
            />
          </FormGroup>

          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              key="save"
              variant="contained"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              key="delete"
              onClick={() => {
                onDelete();
              }}
            >
              Delete
            </Button>
            <Button
              key="cancel"
              variant="contained"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  } else {
    return <></>;
  }
};
