import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import dayjs, { Dayjs } from "dayjs";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  TextField,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import Grid from "@mui/material/Unstable_Grid2";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import BuildIcon from "@mui/icons-material/Build";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";

// TYPES

import { MatchType, AppContextContent, LeagueType, SpotType } from "../types";

import { AppContext } from "../App";
import { match } from "assert";
import { flexbox } from "@mui/system";
import { EventType } from "../types";
import { PlayerButton, Slot } from "./PlayerButton";

import { hPlayersWithAvailabilitiesType } from "./Event";
import { PlayerButtonProps } from "./PlayerButton";

// STYLE

import { style } from "./Event";

type ScoreProps = {
  match: MatchType;
  save: (m: MatchType) => void;
  showModal: boolean;
  setShowModal: (ns: boolean) => void;
  aplayers: string;
  bplayers: string;
};

export const ScoreForm = (props: ScoreProps) => {
  const [wins, setWins] = useState(
    !props.match.awins
      ? "U"
      : props.match.awins === "W"
      ? "A"
      : props.match.bwins === "W"
      ? "B"
      : "D"
  );
  const [score, setScore] = useState(props.match.score);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWins(event.target.value);
  };
  const newScore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScore(event.target.value);
  };

  const onSave = () => {
    const newmatch: MatchType = {
      ...props.match,
      awins:
        wins === "U" ? null : wins === "A" ? "W" : wins === "D" ? "D" : "L",
      bwins:
        wins === "U" ? null : wins === "B" ? "W" : wins === "D" ? "D" : "L",
      score: wins === "U" ? "" : score ? score : "",
    };
    props.save(newmatch);
  };

  return (
    <Modal
      open={props.showModal}
      onClose={() => {
        props.setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Result for {props.match.court}
        </Typography>
        <RadioGroup
          onChange={handleChange}
          aria-label="winnder"
          value={wins}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="U"
            control={<Radio />}
            label="To be played"
          />
          <FormControlLabel
            value="A"
            control={<Radio />}
            label={props.aplayers + " wins"}
          />
          <FormControlLabel
            sx={{ mt: 0 }}
            value="D"
            control={<Radio />}
            label={"Draw"}
          />
          <FormControlLabel
            sx={{ mt: 0 }}
            value="B"
            control={<Radio />}
            label={props.bplayers + " wins"}
          />
        </RadioGroup>
        <TextField
          id="outlined-basic"
          label="Score"
          variant="outlined"
          defaultValue={props.match.score}
          onChange={newScore}
        />
        <p />
        <Button
          variant="contained"
          onClick={() => {
            onSave();
          }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

type ResultProps = {
  onclick: () => void;
  match: MatchType;
};

export const MatchResultButton = (props: ResultProps) => {
  return (
    <Stack
      direction="row"
      onClick={props.onclick}
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {props.match.awins && props.match.awins == "W" ? (
        <ArrowDropUpOutlinedIcon />
      ) : props.match.bwins && props.match.bwins == "W" ? (
        <ArrowDropDownOutlinedIcon />
      ) : props.match.awins && props.match.awins == "D" ? (
        <DehazeOutlinedIcon />
      ) : (
        <HorizontalRuleOutlinedIcon />
      )}
      {props.match.score && props.match.score > "" ? (
        <Typography fontSize={12}>{props.match.score}</Typography>
      ) : (
        ""
      )}
    </Stack>
  );
};
