import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import dayjs, { Dayjs } from "dayjs";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import BuildIcon from "@mui/icons-material/Build";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// TYPES

import { MatchType, LeagueType, SpotType, AppContextContent } from "../types";

import { AppContext, updateEvent } from "../App";
import { match } from "assert";
import { flexbox } from "@mui/system";
import { EventType } from "../types";
import { PlayerButton, Slot } from "./PlayerButton";

import { hPlayersWithAvailabilitiesType } from "./Event";
import { PlayerButtonProps } from "./PlayerButton";
import { CourtForm } from "./CourtForm";

import { MatchResultButton } from "./Score";
import { ScoreForm } from "./Score";
import { DraftStateType } from "./EventDraftBar";

type TennisCourtProps = {
  match: MatchType;
  event: EventType;
  league: LeagueType;
  showDetails: boolean;
  playersWithAvailabilities: hPlayersWithAvailabilitiesType;
  playerDragged?: PlayerButtonProps;
  currentPlayer?: string;
  setCurrentPlayer: (np: string | undefined) => void;
  showTimes: boolean;
  draftState: DraftStateType;
  setDraftState: (nds: DraftStateType) => void;
  changePostIt: (mid?: string, position?: string, postitname?: string) => void;
};

//
// TENNIS COURT
//

export const TennisCourt = (props: TennisCourtProps) => {
  const { state, setState } = useContext(AppContext);
  const [editingCourt, setEditingCourt] = useState(false);
  const [editingScore, setEditingScore] = useState(false);

  const {
    match,
    event,
    league,
    showDetails,
    playersWithAvailabilities,
    playerDragged,
    setCurrentPlayer,
    currentPlayer,
    draftState,
    setDraftState,
    changePostIt,
  } = props;

  const playerPos = [
    { position: "A2" },
    { position: "A1" },
    { position: "score" },
    { position: "B1" },
    { position: "B2" },
  ];

  const saveScore = async (m: MatchType) => {
    updateEvent(league, event, {
      ["matches." + match.MatchID + ".score"]: m.score,
      ["matches." + match.MatchID + ".awins"]: m.awins,
      ["matches." + match.MatchID + ".bwins"]: m.bwins,
    });
  };

  if (state) {
    return (
      <Stack
        sx={
          {
            // minWidth: 90, margin: 0
          }
        }
        spacing={0}
      >
        {editingScore ? (
          <ScoreForm
            key={match.MatchID}
            showModal={editingScore}
            setShowModal={setEditingScore}
            match={match}
            save={(newm: MatchType) => {
              saveScore(newm);
              setEditingScore(false);
            }}
            aplayers={match.players
              .filter((p) => p.position == "A1")
              .concat(match.players.filter((p) => p.position === "A2"))
              .map((p) =>
                p.postitname
                  ? p.postitname
                  : league.playerDetails[p.PlayerID || ""]?.name || "???"
              )
              .join(" and ")}
            bplayers={match.players
              .filter((p) => p.position == "B1")
              .concat(match.players.filter((p) => p.position === "B2"))
              .map(
                (p) =>
                  p.postitname ||
                  league.playerDetails[p.PlayerID || ""]?.name ||
                  "???"
              )
              .join(" and ")}
          />
        ) : (
          <></>
        )}
        {editingCourt ? (
          <CourtForm
            showModal={editingCourt}
            setShowModal={setEditingCourt}
            event={event}
            match={match}
            lid={league.LeagueID}
          />
        ) : (
          <></>
        )}
        <Button
          onClick={() => {
            if (!draftState.editMode) setEditingCourt(true);
          }}
        >
          {props.showTimes ? (
            <>
              {match.court}
              <br />
              {dayjs(props.event.when)
                .add(props.match.offset || 0, "minute")
                .format("LT")}
            </>
          ) : (
            <>{props.match.court}</>
          )}
        </Button>
        {playerPos.map((pvp, index) => {
          // if editing a draft, we pull from draft, otherwise from match

          // console.log(draftState);

          const matchtodisplay = draftState.editMode
            ? props.draftState.draft[match.MatchID]
            : match;

          const players = matchtodisplay.players.filter(
            (p) => p.position === pvp.position
          );

          if (pvp.position === "score") {
            return (
              <MatchResultButton
                key={match.MatchID}
                match={match}
                onclick={() => {
                  if (!draftState.editMode) setEditingScore(true);
                }}
              />
            );
          } else if (
            players.length === 1
            // &&
            // props.league.playerDetails[players[0].PlayerID] &&
            // props.league.playerDetails[players[0].PlayerID].name
          ) {
            const player =
              players[0].PlayerID &&
              playersWithAvailabilities[players[0].PlayerID]
                ? playersWithAvailabilities[players[0].PlayerID]
                : players[0].postitname
                ? undefined
                : {
                    PlayerID: players[0].PlayerID,
                    name: "???",
                    external: false,
                    availability: "out",
                    fullname: "???",
                    contractshares: 0,
                    email: "???",
                    phone: "???",
                  };

            const postitname = players[0].postitname;

            return (
              <Slot
                key={match.MatchID + "/" + pvp.position}
                position={pvp.position}
                event={event}
                match={match}
                player={player}
                postitname={postitname}
                playerDragged={
                  playerDragged
                    ? {
                        ...playerDragged,
                        position: pvp.position,
                        mid: match.MatchID,
                      }
                    : undefined
                }
                currentPlayer={currentPlayer}
                setCurrentPlayer={setCurrentPlayer}
                lid={league.LeagueID}
                draftState={draftState}
                setDraftState={setDraftState}
                changePostIt={changePostIt}
              ></Slot>
            );
          } else {
            return (
              <Slot
                key={match.MatchID + "/" + pvp.position}
                match={match}
                position={pvp.position}
                event={event}
                currentPlayer={currentPlayer}
                setCurrentPlayer={setCurrentPlayer}
                lid={league.LeagueID}
                draftState={draftState}
                setDraftState={setDraftState}
                changePostIt={() => {}}
              ></Slot>
            );
          }
        })}
      </Stack>
    );
  } else return <></>;
};
