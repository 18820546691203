import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import dayjs, { Dayjs } from "dayjs";

import "../firebase-ui-auth.css";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

// MUI

import { Box } from "@mui/material";
import { logUserIn } from "../App";

// MUI ICONS

import { AppContext } from "../App";

// FIREBASE UI

import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import { auth } from "../firebase";

export const Auth = () => {
  const { state, setState } = useContext(AppContext);

  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start("#firebaseui-auth-container", {
      callbacks: {
        signInSuccessWithAuthResult: function (
          authResult: any,
          redirectUrl?: string | undefined //
        ) {
          logUserIn(authResult.user, state, setState);
          return false;
        },
        uiShown: function () {
          // This is what should happen when the form is full loaded. In this example, I hide the loader element.
          //   document.getElementById("loader")!.style.display = "none";
        },
      },
      // signInSuccessUrl: "", // This is where should redirect if the sign in is successful.
      signInOptions: [
        // This array contains all the ways an user can authenticate in your application. For this example, is only by email.
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
          disableSignUp: {
            status: false,
          },
        },
        // {
        //   provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //   requireDisplayName: true,
        //   disableSignUp: {
        //     status: false,
        //   },
        // },
        // {
        //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        //   requireDisplayName: true,
        //   disableSignUp: {
        //     status: false,
        //   },
        // },
      ],
      tosUrl: "https://www.example.com/terms-conditions", // URL to you terms and conditions.
      privacyPolicyUrl: function () {
        // URL to your privacy policy
        window.location.assign("https://www.example.com/privacy-policy");
      },
    });
  }, []);

  return (
    <>
      <Box sx={{ border: 0, marginTop: 10 }}>
        <div id="firebaseui-auth-container"></div>
      </Box>
    </>
  );
};
