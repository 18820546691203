import "../App.css";

// REACT + GENERAL LIBRARIES

import * as _ from "lodash";

// MUI

import { IconButton, Stack, MenuItem, Select } from "@mui/material";

// MUI ICONS

import SaveIcon from "@mui/icons-material/Save";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

// TYPES

import { updateEvent } from "../App";
import { EventType, LeagueType, hMatchSpotsAndPlayersType } from "../types";

type EventDraftBarProperties = {
  league: LeagueType;
  event: EventType;
  draftState: DraftStateType;
  setDraftState: (nds: DraftStateType) => void;
  setEditMode: (ne: boolean) => void;
};

export type DraftStateType = {
  editMode: boolean;
  currentDraftName: string | undefined;
  saved: boolean;
  published: boolean;
  draft: hMatchSpotsAndPlayersType;
};

export const EventDraftBar = ({
  league,
  event,
  draftState,
  setDraftState,
  setEditMode,
}: EventDraftBarProperties) => {
  const changeSelect = (e: any) => {
    if (e) {
      if (
        draftState.saved ||
        window.confirm("You haven't save the current draft, proceed?")
      ) {
        const newdraft =
          event.drafts && event.drafts[e.target.value]
            ? event.drafts[e.target.value]
            : draftState.draft; // no change, we probably went to save as...

        setDraftState({
          ...draftState,
          currentDraftName: e.target.value,
          // we filter to only the existing matches in actual event
          draft: _.pick(newdraft, Object.keys(event.matches)),
          saved: event.drafts && event.drafts[e.target.value] ? true : false,
        });
      }
    }
  };

  const onSave = () => {
    var newDraftName: string | undefined | null = draftState.currentDraftName;
    if (!newDraftName || newDraftName === "save as...") {
      newDraftName = window.prompt("New draft name");
    }

    if (newDraftName) {
      const newDraft = draftState.draft;
      //   console.log(newDraft);

      updateEvent(league, event, {
        ["drafts." + newDraftName]: newDraft,
      });

      setDraftState({
        ...draftState,
        saved: true,
        currentDraftName: newDraftName,
      });
    }
  };

  const onDelete = () => {
    if (
      event.drafts &&
      draftState.currentDraftName !== undefined &&
      event.drafts[draftState.currentDraftName] &&
      window.confirm("Delete draft " + draftState.currentDraftName + "?")
    ) {
      const newdrafts = _.cloneDeep(event.drafts);
      delete newdrafts[draftState.currentDraftName || ""];
      updateEvent(league, event, {
        drafts: newdrafts,
      });
      setDraftState({ ...draftState, currentDraftName: undefined });
    }
  };

  const onPublish = () => {
    if (draftState.saved || window.confirm("Draft not saved, proceed?")) {
      const newMatches = _.cloneDeep(event.matches);
      Object.keys(draftState.draft).forEach((mid) => {
        // we only patch from draft to actual if the match exists in actual
        if (newMatches[mid]) {
          newMatches[mid].players = _.cloneDeep(draftState.draft[mid].players);
          newMatches[mid].spots = _.cloneDeep(draftState.draft[mid].spots);
        }
      });
      updateEvent(league, event, {
        matches: newMatches,
      });
      setDraftState({ ...draftState, editMode: false });
    }
  };

  return (
    <Stack
      direction="row"
      sx={{ backgroundColor: "#DBDBDB", padding: 1, marginBottom: 2 }}
      spacing={0}
    >
      <Select
        size="small"
        sx={{ flexGrow: 1 }}
        value={draftState.currentDraftName || "save as..."}
        onChange={changeSelect}
      >
        {Object.keys(event.drafts || {}).map((d) => (
          <MenuItem value={d}>{d}</MenuItem>
        ))}
        <MenuItem value="save as...">save as...</MenuItem>
      </Select>
      <IconButton
        disabled={
          draftState.currentDraftName === undefined ||
          draftState.currentDraftName === "save as..."
        }
        onClick={onDelete}
      >
        <DeleteIcon />
      </IconButton>
      <IconButton disabled={draftState.saved} onClick={onSave}>
        <SaveIcon />
      </IconButton>
      <IconButton disabled={draftState.published} onClick={onPublish}>
        <PublishIcon />
      </IconButton>

      <IconButton
        onClick={() => {
          if (draftState.saved || window.confirm("Draft not saved, proceed?"))
            setEditMode(false);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
