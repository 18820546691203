import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import { collection, getDocs, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import BuildIcon from "@mui/icons-material/Build";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";

// TYPES

import { MatchType, LeagueType, AppContextContent } from "../types";

import { AppContext } from "../App";
import { match } from "assert";
import { flexbox } from "@mui/system";
import { EventType } from "../types";
import { Event } from "./Event";

// IMPORTS

import { EventForm } from "./EventForm";
import { firestoreAutoId } from "../processEmails";

import { LeaguePicker } from "./LeaguePicker";

//
// CALENDAR CONTEXT
//

export type DayEvent = {
  when: string;
  playing: boolean;
  PlayingDayID: string;
  LeagueID: string;
};

export type hDayType = { [day: string]: DayEvent[] };

export type CalendarContextContent = {
  hDay: hDayType;
};

type CalendarContextType = {
  calendarState: CalendarContextContent;
  setCalendarState: React.Dispatch<
    React.SetStateAction<CalendarContextContent>
  >;
};

const calendarContextDefaultValue = {
  calendarState: {
    hDay: {} as hDayType,
  } as CalendarContextContent,
  setCalendarState: () => {},
};

export const CalendarContext = createContext<CalendarContextType>(
  calendarContextDefaultValue
);

//
//
//

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
) {
  const { state, setState } = useContext(AppContext);
  const { calendarState, setCalendarState } = useContext(CalendarContext);
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;

  const dayInMonth = props.day.date();

  const events = calendarState.hDay[props.day.format("YYYY-MM-DD")];

  const played = events ? events.filter((e) => e.playing).length > 0 : false;

  //
  // RENDER
  //

  return played ? (
    <Box sx={{ backgroundColor: "#fda" }}>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      >
        <SportsTennisIcon sx={{ fontSize: 18 }} /> {dayInMonth}
      </PickersDay>
    </Box>
  ) : events ? (
    <Box sx={{ backgroundColor: "#fda" }}>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      ></PickersDay>
    </Box>
  ) : (
    <Box>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      ></PickersDay>
    </Box>
  );
}

function Calendar() {
  const { state, setState } = useContext(AppContext);
  const [showEventForm, setShowEventForm] = useState(false);
  const [showLeaguePicker, setShowLeaguePicker] = useState(false);

  const [calendarState, setCalendarState] = useState<CalendarContextContent>(
    calendarContextDefaultValue.calendarState
  );

  // SCROLL TO TOP ON FIRST RENDER

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // now getting a day by day
    const hDay: hDayType = {};

    //
    //
    // COMPILING ALL EVENTS TO DISPLAY
    //
    //

    const eventsToDisplay = Object.values(state.leagues)
      .filter(
        (l) =>
          !state.hiddenLeagues.includes(l.LeagueID) &&
          !(l.standalone && state.showStandalone === false)
      )
      // .reduce(
      //   (p, c: LeagueType) =>
      //     p.concat(
      //       state.events[c.LeagueID]
      //         ? Object.values(state.events[c.LeagueID]).map((e) => {
      //             return { ...e, LeagueID: c.LeagueID };
      //           })
      //         : []
      //     ),
      //   [] as EventType[]
      // )
      .reduce(
        (p: DayEvent[], c: LeagueType) =>
          p.concat(
            c.eventsSummary
              ? Object.entries(c.eventsSummary).map(([PlayingDayID, es]) => {
                  return {
                    playing:
                      es.playerTimeAndResults[state.loggedPlayer?.uid || ""] &&
                      es.playerTimeAndResults[state.loggedPlayer?.uid || ""]
                        .minutes > 0,
                    PlayingDayID: PlayingDayID,
                    LeagueID: c.LeagueID,
                    when: es.when,
                  };
                })
              : []
          ),
        [] as DayEvent[]
      );

    //

    eventsToDisplay.forEach((e) => {
      const day = dayjs(e.when).format("YYYY-MM-DD");
      const array = Object(hDay)[day] ?? [];
      array.push(e);
      hDay[day] = array;
    });

    setCalendarState((cur) => {
      return { ...cur, hDay: hDay };
    });
  }, [state.leagues, state.events, state.hiddenLeagues]);

  if (state) {
    const events = calendarState.hDay[state.currentDay];

    return (
      <CalendarContext.Provider value={{ calendarState, setCalendarState }}>
        {showLeaguePicker ? (
          <LeaguePicker
            showModal={showLeaguePicker}
            setShowModal={setShowLeaguePicker}
          />
        ) : (
          //   <EventForm
          //     showModal={showEventForm}
          //     setShowModal={setShowEventForm}
          //     event={blankEvent}
          //     lid={state.currentLeague || ""}
          //   />
          <></>
        )}
        <Box sx={{ marginTop: 7, marginBottom: 20 }}>
          {/* <Paper elevation={3} sx={{ margin: 2, padding: 2 }}> */}
          {/* <Typography>
            {state.timeWindow.lower} - {state.timeWindow.higher}
          </Typography> */}
          <Button
            variant="outlined"
            onClick={() => {
              setState((state) => {
                return {
                  ...state,
                  currentDay: dayjs().startOf("day").format("YYYY-MM-DD"),
                };
              });
            }}
          >
            Today
          </Button>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              //   defaultValue={dayjs("2024-02-01")}
              value={dayjs(state.currentDay)}
              onChange={(newvalue) => {
                setState({
                  ...state,
                  currentDay: newvalue.format("YYYY-MM-DD"),
                });
              }}
              slots={{
                day: ServerDay,
              }}
              //   fixedWeekNumber={6}
              showDaysOutsideCurrentMonth
              onMonthChange={(newvalue) => {
                // if we change the date with the date button,
                // this function will be triggered if we change
                // month
                if (
                  dayjs(state.currentDay)
                    .startOf("month")
                    .format("YYYY-MM-DD") !==
                  dayjs(newvalue).format("YYYY-MM-DD")
                ) {
                  setState({
                    ...state,
                    currentDay: newvalue.format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </LocalizationProvider>

          <Stack direction="column" spacing={4} sx={{ margin: 2 }}>
            {events ? (
              events.map((e) =>
                state.events[e.LeagueID] &&
                state.events[e.LeagueID][e.PlayingDayID] ? (
                  <Event
                    key={e.PlayingDayID}
                    event={state.events[e.LeagueID][e.PlayingDayID]}
                    lid={e.LeagueID}
                    showDetails={false}
                  />
                ) : (
                  <></>
                )
              )
            ) : (
              <></>
            )}

            {["professional", "individual", "sysadmin"].includes(
              state.tokenResult?.claims.license as string
            ) ? (
              <Button
                variant="contained"
                onClick={() => {
                  setShowLeaguePicker(true);
                }}
              >
                <AddIcon />
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      </CalendarContext.Provider>
    );
  } else return <></>;
}

export default Calendar;
