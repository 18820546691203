// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {
    getAuth,
    connectAuthEmulator,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    NextOrObserver,
    User, setPersistence, browserLocalPersistence, browserSessionPersistence
} from 'firebase/auth';

import { user_emulator } from "./processEmails";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD5VGu-IiJR46HYsZH8zUzHVK_o0j3qjmA",
    authDomain: "racquetbudz.firebaseapp.com",
    projectId: "racquetbudz",
    storageBucket: "racquetbudz.appspot.com",
    messagingSenderId: "618575630255",
    appId: "1:618575630255:web:1a9b2b244c8cd87ebd295a",
    measurementId: "G-V7GYEKD141"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// setPersistence(auth, browserLocalPersistence)

// export const USE_EMULATOR = true

if (user_emulator) connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });

export const db = getFirestore(app);

if (user_emulator) connectFirestoreEmulator(db, 'localhost', 8080);
const analytics = getAnalytics(app);


export const signInUser = async (
    email: string,
    password: string
) => {
    if (!email && !password) return;

    return await signInWithEmailAndPassword(auth, email, password)
}

export const userStateListener = (callback: NextOrObserver<User>) => {
    return onAuthStateChanged(auth, callback)
}

export const SignOutUser = async () => await signOut(auth);