import "../App.css";

// REACT + GENERAL LIBRARIES

import * as _ from "lodash";

// CONTEXTS

import { useContext } from "react";

// MUI

import { Box, Stack, Button, Divider } from "@mui/material";

// MUI ICONS

import AddIcon from "@mui/icons-material/Add";

// TYPES

import { EventType } from "../types";

import { AppContext } from "../App";

import { TennisCourt } from "./TennisCourt";

import { PlayerButtonProps } from "./PlayerButton";

// FUNCTIONS

import { DraftStateType } from "./EventDraftBar";
import { hPlayersWithAvailabilitiesType } from "./Event";

type EventMatchesProps = {
  event: EventType;
  draftState: DraftStateType;
  setDraftState: (ns: DraftStateType) => void;
  showDetails: boolean;
  players: hPlayersWithAvailabilitiesType;
  playerDragged: PlayerButtonProps | null;
  currentPlayer: string | undefined;
  setCurrentPlayer: (np: string | undefined) => void;
  lid: string;
  addCourt: () => void;
  editor: boolean;
  changePostIt: (mid?: string, position?: string, postitname?: string) => void;
};

export const EventMatches = ({
  event,
  draftState,
  setDraftState,
  showDetails,
  players,
  playerDragged,
  currentPlayer,
  setCurrentPlayer,
  lid,
  addCourt,
  editor,
  changePostIt,
}: EventMatchesProps) => {
  const { state, setState } = useContext(AppContext);

  return (
    <Box
      sx={{
        overflow: "auto",
        backgroundColor: draftState.editMode && false ? "#eeeecc" : "#f5f5ff",
        padding: 1,
        paddingRight: 10,
        justifyContent: "center",
      }}
    >
      {/* 
                
                Tennis courts
                
                */}

      <Stack
        direction="row"
        sx={{ margin: 0, display: "flex" }}
        divider={
          <Divider orientation="vertical" flexItem sx={{ margin: 0.3 }} />
        }
        spacing={0}
      >
        {Object.values(event.matches)
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .map((m) => (
            <TennisCourt
              key={m.MatchID}
              event={event}
              match={m}
              league={state.leagues[lid]}
              showDetails={showDetails}
              playersWithAvailabilities={players}
              playerDragged={playerDragged ? playerDragged : undefined}
              currentPlayer={currentPlayer}
              setCurrentPlayer={setCurrentPlayer}
              showTimes={
                Object.values(event.matches).findIndex(
                  (m) => m.offset && m.offset !== 0
                ) >= 0
              }
              draftState={draftState}
              setDraftState={setDraftState}
              changePostIt={changePostIt}
            />
          ))}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: 2,
          }}
        >
          {/* you can only create courts if admin of this league */}
          {editor && !draftState.editMode ? (
            <Stack direction="row">
              <Button
                variant="contained"
                onClick={() => {
                  addCourt();
                }}
              >
                <AddIcon />
              </Button>
              <Box sx={{ minWdith: 30 }}>&nbsp;&nbsp;</Box>
            </Stack>
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
