import "../App.css";

// REACT + GENERAL LIBRARIES
import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// FIREBASE

import { setDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  TextField,
  Divider,
  Select,
  MenuItem,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Dialog,
  Slide,
  DialogContent,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TransitionProps } from "@mui/material/transitions";

import { Event } from "./Event";
import {
  EmailTemplateOption,
  EmailTemplateTypeType,
  EventType,
  ToLessGroups,
  hEventType,
} from "../types";
import { DefaultRecipients } from "./EmailsCompose";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import FilterListIcon from "@mui/icons-material/FilterList";
import BuildIcon from "@mui/icons-material/Build";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import DataObjectIcon from "@mui/icons-material/DataObject";

import {
  LeagueType,
  EmailTemplateType,
  AdminContextContent,
  hTemplateType,
} from "../types";
import { AppContext } from "../App";
import { AdminContext } from "../App";

import { style } from "./Event";
import { firestoreAutoId } from "../processEmails";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type TemplateOptionProps = {
  option: EmailTemplateOption;
  index: number;
  setOption: (newoption: EmailTemplateOption, index: number) => void;
  deleteOption: (index: number) => void;
  moveUpOption: (index: number) => void;
};

export const TemplateOption = ({
  option,
  index,
  setOption,
  deleteOption,
  moveUpOption,
}: TemplateOptionProps) => {
  return (
    <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
      <Stack direction="column" sx={{ justifyContent: "center" }}>
        <Typography>{index + 1}</Typography>
        <IconButton
          onClick={(e) => {
            deleteOption(index);
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
        {index > 0 ? (
          <IconButton
            onClick={(e) => {
              moveUpOption(index);
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Stack>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
          <Select
            size="small"
            value={option.type}
            onChange={(e) => {
              setOption(
                { ...option, type: e.target.value as "boolean" | "string" },
                index
              );
            }}
          >
            <MenuItem key="boolean" value="boolean">
              Boolean
            </MenuItem>
            <MenuItem key="string" value="string">
              String
            </MenuItem>
          </Select>
          <TextField
            size="small"
            key={"optionname-" + index}
            id={"optionname-" + index}
            value={option.name}
            onChange={(e) => {
              setOption({ ...option, name: e.target.value }, index);
            }}
            label="Option name"
          />
        </Stack>
        <TextField
          size="small"
          key={"optiondesc-" + index}
          id={"optiondesc-" + index}
          value={option.description}
          onChange={(e) => {
            setOption({ ...option, description: e.target.value }, index);
          }}
          label="Option description"
        />
        {option.type === "string" ? (
          <>
            <TextField
              size="small"
              key={"valuestring-" + index}
              id={"valuestring-" + index}
              value={option.value}
              onChange={(e) => {
                setOption({ ...option, value: e.target.value || "" }, index);
              }}
              label="Default value"
            />
            <TextField
              size="small"
              key={"numberrows-" + index}
              id={"numberrows-" + index}
              value={option.rows}
              onChange={(e) => {
                setOption(
                  { ...option, rows: Number.parseInt(e.target.value) || 1 },
                  index
                );
              }}
              label="Number of rows"
            />
          </>
        ) : (
          <FormControlLabel
            label="Default value"
            control={
              <Checkbox
                checked={option.value ? true : false}
                onChange={(e) => {
                  setOption(
                    { ...option, value: option.value ? false : true },
                    index
                  );
                }}
              />
            }
          />
        )}
      </Stack>
    </Stack>
  );
};

type JsonTreeBrowserProps = {
  jsonTree: Object;
  insertText: (s: string) => void;
  close: () => void;
};

export const JsonTreeBrowser = ({
  jsonTree,
  insertText,
  close,
}: JsonTreeBrowserProps) => {
  const [nodePath, setNodePath] = useState<string | undefined>(undefined);

  const obj =
    nodePath !== undefined ? _.at(jsonTree as any, nodePath)[0] : jsonTree;

  const down = (n: string, objdown: any) => {
    if (typeof objdown === "string" || typeof objdown === "number") {
      insertText("{{" + nodePath + "." + n + "}}");
      close();
    } else if (typeof objdown === "boolean") {
      insertText("{{#if " + nodePath + "." + n + "}} {{else}} {{/if}}");
    } else {
      if (nodePath === undefined) {
        setNodePath(n);
      } else {
        setNodePath(nodePath + "." + n);
      }
    }
  };

  const up = () => {
    setNodePath(
      nodePath && nodePath.includes(".")
        ? nodePath.split(".").slice(0, -1).join(".")
        : undefined
    );
  };

  console.log(obj);
  return (
    <Modal
      open={true}
      onClose={() => {
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="column" spacing={1}>
          {nodePath ? (
            <Button size="small" variant="contained" onClick={up}>
              ..
            </Button>
          ) : (
            <></>
          )}
          {typeof obj === "object" ? (
            Object.keys(obj).map((k) => {
              const objdown = obj[k];
              return (
                <Stack>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={(e) => down(k, objdown)}
                  >
                    {k}
                  </Button>
                  {(() => {
                    if (typeof objdown === "string") {
                      return (
                        <Typography sx={{ textAlign: "center", fontSize: 12 }}>
                          {objdown}
                        </Typography>
                      );
                    } else if (typeof objdown === "boolean") {
                      return (
                        <Typography sx={{ textAlign: "center", fontSize: 12 }}>
                          {objdown === true ? "true" : "false"}
                        </Typography>
                      );
                    } else if (typeof objdown === "number") {
                      return (
                        <Typography sx={{ textAlign: "center", fontSize: 12 }}>
                          {objdown}
                        </Typography>
                      );
                    }
                  })()}
                </Stack>
              );
            })
          ) : (
            <></>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

type FullScreenTextProps = {
  onClose: () => void;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  jsonTree?: Object;
};

export const FullScreenText = ({
  onClose,
  text,
  setText,
  jsonTree,
}: FullScreenTextProps) => {
  const [editorText, setEditorText] = useState(text);
  const inputRef = React.useRef();
  const [selectionStart, setSelectionStart] = React.useState();
  const updateSelectionStart = () => {
    if (inputRef.current)
      setSelectionStart((inputRef.current as any).selectionStart);
  };
  const [showObjectPicker, setShowObjectPicker] = useState(false);

  const insertText = (s: string) => {
    if (selectionStart) {
      setEditorText(
        editorText.slice(0, selectionStart) +
          s +
          editorText.slice(selectionStart)
      );
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={() => {
          onClose();
        }}
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ margin: 0, padding: 0 }}>
          {jsonTree && showObjectPicker ? (
            <JsonTreeBrowser
              jsonTree={jsonTree}
              insertText={insertText}
              close={() => {
                setShowObjectPicker(false);
                if (inputRef) {
                  const timeout = setTimeout(() => {
                    try {
                      (inputRef.current as any).focus();
                    } catch (e) {}
                  }, 100);
                }
              }}
            />
          ) : (
            <></>
          )}
          <TextField
            fullWidth
            onSelect={updateSelectionStart}
            inputRef={inputRef}
            id="contents-tf"
            label="Contents"
            multiline
            // rows={12}
            value={editorText}
            variant="filled"
            inputProps={{
              style: {
                fontSize: 14,
                fontFamily: "monospace",
                lineHeight: 1.4,
              },
            }}
            onChange={(event) => {
              setEditorText(event.currentTarget.value);
            }}
          />
          <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  onClose();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setShowObjectPicker(true);
                }}
                aria-label="close"
              >
                <DataObjectIcon />
              </IconButton>
              <Button
                variant="contained"
                onClick={() => {
                  setText(editorText);
                  onClose();
                }}
              >
                Save
              </Button>
            </Toolbar>
          </AppBar>
        </DialogContent>
      </Dialog>
    </>
  );
};

type TemplateFormProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  template: EmailTemplateType;
};

export const TemplateForm = ({
  showModal,
  setShowModal,
  template,
}: TemplateFormProps) => {
  const [editing, setEditing] = useState<EmailTemplateType>(
    _.cloneDeep(template)
  );
  const [editTextField, setEditTextField] = useState<string | undefined>(
    undefined
  );

  // accordions
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onSave = async () => {
    const toSave: EmailTemplateType = {
      ...editing,
      EmailTemplateID:
        template.EmailTemplateID === ""
          ? firestoreAutoId()
          : template.EmailTemplateID,
      // name: templateName,
      // description: templateDescription,
      // subject: templateSubject,
      // type: templateType,
      // contents: templateContents,
      // toGroups: recipients.toGroups,
      // lessGroups: recipients.lessGroups,
      // individualized: recipients.individualized,
      // options: options,
    };

    const templateRef = doc(db, "templates", toSave.EmailTemplateID);
    await setDoc(templateRef, toSave);

    // console.log(JSON.stringify(toSave, null, 2));

    setShowModal(false);
  };

  const onDelete = async () => {
    if (template.EmailTemplateID && window.confirm("Sure?")) {
      const templateRef = doc(db, "templates", template.EmailTemplateID);
      await deleteDoc(templateRef);
      setShowModal(false);
    }
  };

  if (editTextField) {
    return (
      <FullScreenText
        onClose={() => {
          setEditTextField(undefined);
        }}
        text={editing[editTextField as keyof EmailTemplateType] as string}
        setText={(newtext) => {
          const newEditing = _.cloneDeep(editing) as any;
          newEditing[editTextField] = newtext;
          setEditing(newEditing);
        }}
      />
    );
  }

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ alignContent: "center", fontSize: "16pt", marginBottom: 2 }}
        >
          {template.EmailTemplateID == "" ? "New template" : "Editing template"}
        </Typography>

        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary>Name, description</AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" spacing={2}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="type-select"
                    value={editing.type}
                    label="Type"
                    onChange={(newvalue) => {
                      setEditing({
                        ...editing,
                        type: newvalue.target.value as EmailTemplateTypeType,
                      });
                    }}
                  >
                    <MenuItem value={"genai"}>LLM</MenuItem>
                    <MenuItem value={"html"}>html</MenuItem>
                    <MenuItem value={"text"}>text</MenuItem>
                  </Select>
                  <TextField
                    key="name-tf"
                    value={editing.name}
                    label="Name"
                    onChange={(event) => {
                      setEditing({
                        ...editing,
                        name: event.currentTarget.value,
                      });
                    }}
                  />
                  <TextField
                    key="desc-tf"
                    value={editing.description}
                    label="Description"
                    onChange={(event) => {
                      setEditing({
                        ...editing,
                        description: event.currentTarget.value,
                      });
                    }}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "options"}
              onChange={handleChange("options")}
            >
              <AccordionSummary>Options</AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ maxHeight: "300px", overflow: "auto" }}
                >
                  {editing.options.map((o, i) => (
                    <TemplateOption
                      option={o}
                      index={i}
                      setOption={(newoption, index) => {
                        const newoptions = _.cloneDeep(editing.options);
                        newoptions[index] = newoption;
                        setEditing({
                          ...editing,
                          options: newoptions,
                        });
                        // setOptions(newoptions);
                      }}
                      deleteOption={(index) => {
                        var newoptions = editing.options.filter(
                          (v, i) => i != index
                        );
                        setEditing({
                          ...editing,
                          options: newoptions,
                        });
                      }}
                      moveUpOption={(index) => {
                        if (index > 0) {
                          const newoptions = _.cloneDeep(editing.options);
                          const down = newoptions[index - 1];
                          const up = newoptions[index];
                          newoptions[index - 1] = up;
                          newoptions[index] = down;
                          setEditing({
                            ...editing,
                            options: newoptions,
                          });
                        }
                      }}
                    />
                  ))}
                  <Button
                    variant="contained"
                    onClick={() => {
                      const newoptions = _.cloneDeep(editing.options);
                      newoptions.push({
                        type: "boolean",
                        name: "",
                        description: "",
                      });
                      setEditing({
                        ...editing,
                        options: newoptions,
                      });
                    }}
                  >
                    <AddIcon />
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary>Contents</AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row">
                    <TextField
                      key="subject-tf"
                      value={editing.subject}
                      label="Subject"
                      multiline
                      rows={5}
                      inputProps={{
                        style: {
                          fontSize: 12,
                          fontFamily: "monospace",
                          lineHeight: 1.4,
                        },
                      }}
                      onChange={(event) => {
                        setEditing({
                          ...editing,
                          subject: event.currentTarget.value,
                        });
                      }}
                      sx={{ flexGrow: 1 }}
                    />
                    <Button
                      onClick={() => {
                        setEditTextField("subject");
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                  <Stack direction="row">
                    <TextField
                      id="contents-tf"
                      label="Contents"
                      multiline
                      rows={12}
                      value={editing.contents}
                      variant="filled"
                      inputProps={{
                        style: {
                          fontSize: 12,
                          fontFamily: "monospace",
                          lineHeight: 1.4,
                        },
                      }}
                      sx={{ flexGrow: 1 }}
                      onChange={(event) => {
                        setEditing({
                          ...editing,
                          contents: event.currentTarget.value,
                        });
                      }}
                    />
                    <Button
                      onClick={() => {
                        setEditTextField("contents");
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary>Default recipients</AccordionSummary>
              <AccordionDetails>
                <DefaultRecipients
                  formValues={{
                    toGroups: editing.toGroups,
                    lessGroups: editing.lessGroups,
                    individualized: editing.individualized,
                    test: editing.test,
                  }}
                  setFormValues={(newrecipients) => {
                    setEditing({ ...editing, ...newrecipients });
                  }}
                  lid={""}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary>JSON</AccordionSummary>
              <AccordionDetails>
                <TextField
                  multiline
                  rows="15"
                  fullWidth
                  sx={{ fontSize: "12px" }}
                  value={JSON.stringify(editing, null, 2)}
                  onChange={(event) => {
                    setEditing(JSON.parse(event.currentTarget.value));
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              key="save"
              variant="contained"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              key="delete"
              onClick={() => {
                onDelete();
              }}
            >
              Delete
            </Button>
            <Button
              key="cancel"
              variant="contained"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
