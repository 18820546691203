import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  runTransaction,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

// MUI ICONS

import { EventType, MessageType } from "../types";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { AppContext, updateEvent } from "../App";
import SendIcon from "@mui/icons-material/Send";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import { style } from "./Event";

type EventMessagesProps = {
  event: EventType;
  showDetails: boolean;
  lid: string;
};

type EventMessagesDialogProps = EventMessagesProps & {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const EventMessagesDialog = ({
  event,
  lid,
  showDetails,
  showModal,
  setShowModal,
}: EventMessagesDialogProps) => {
  const { state, setState } = useContext(AppContext);

  const [newMessage, setNewMessage] = useState("");
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [event.messages]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, []);

  const changeStatus = async () => {
    const newstatus = prompt("Update status", event.status || "");
    if (newstatus !== null) {
      // const eventRef = doc(db, "leagues", lid, "events", event.PlayingDayID);
      updateEvent(state.leagues[lid], event, { status: newstatus });
      // await updateDoc(eventRef, "status", newstatus);
    }
  };

  const sendMessage = async () => {
    if (newMessage !== "" && state.loggedPlayer) {
      const newMessages = _.cloneDeep(event.messages || []);
      const newMsg: MessageType = {
        PlayerID: state.loggedPlayer.uid,
        when: dayjs().toISOString(),
        message: newMessage,
      };
      newMessages.push(newMsg);
      const eventRef = doc(db, "leagues", lid, "events", event.PlayingDayID);
      setNewMessage("");

      updateEvent(state.leagues[lid], event, { messages: newMessages });
      // await updateDoc(eventRef, "messages", newMessages);
    }
  };

  const editMessage = async (edited: MessageType) => {
    const newmessage = prompt("Editing message", edited.message);
    var newMessages: MessageType[] = [];
    if (newmessage) {
      newMessages = event.messages.map((m) =>
        edited.when === m.when &&
        edited.PlayerID === m.PlayerID &&
        m.message === edited.message
          ? ({
              PlayerID: m.PlayerID,
              when: m.when,
              message: newmessage,
            } as MessageType)
          : m
      );
    } else {
      newMessages = event.messages.filter(
        (m) =>
          !(
            edited.when === m.when &&
            edited.PlayerID === m.PlayerID &&
            m.message === edited.message
          )
      );
    }
    // const eventRef = doc(db, "leagues", lid, "events", event.PlayingDayID);
    updateEvent(state.leagues[lid], event, { messages: newMessages });
    // await updateDoc(eventRef, "messages", newMessages);
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="column">
          <Stack
            direction="row"
            sx={{
              marginTop: 1,
              border: 0,
              borderRadius: "10px",
              backgroundColor: "#eee",
              display: "flex",
              padding: 1,
              marginBottom: 2,
            }}
          >
            <Typography sx={{ fontSize: 12, flexGrow: 1 }}>
              {event.status}
            </Typography>
          </Stack>

          <Stack
            direction="column"
            sx={{ maxHeight: "300px", overflow: "scroll" }}
          >
            {(event.messages || []).map((m) => {
              return (
                <Stack direction="row" ref={scrollRef}>
                  <Typography>
                    {state.leagues[lid]?.playerDetails[m.PlayerID].name || ""}:
                  </Typography>
                  {state.loggedPlayer?.uid === m.PlayerID ? (
                    <Typography
                      sx={{ marginLeft: 0.6, textDecoration: "underline" }}
                      onClick={(e) => {
                        editMessage(m);
                      }}
                    >
                      {m.message}
                    </Typography>
                  ) : (
                    <Typography sx={{ marginLeft: 0.6 }}>
                      {m.message}
                    </Typography>
                  )}
                </Stack>
              );
            })}
          </Stack>

          <Stack direction="row">
            <TextField
              sx={{ marginTop: 2, flexGrow: 1 }}
              label="new message"
              size="small"
              id="newmessage"
              value={newMessage}
              onChange={(event) => {
                setNewMessage(event.currentTarget.value);
              }}
              onKeyUpCapture={(e) => {
                if (e.keyCode == 13) {
                  e.preventDefault();
                  e.stopPropagation();
                  sendMessage();
                }
              }}
            />
            <IconButton sx={{ marginTop: 1.5 }} onClick={sendMessage}>
              <SendIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export const EventMessages = ({
  event,
  lid,
  showDetails,
}: EventMessagesProps) => {
  const { state, setState } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);

  const changeStatus = async () => {
    const newstatus = prompt("Update status", event.status || "");
    if (newstatus !== null) {
      // const eventRef = doc(db, "leagues", lid, "events", event.PlayingDayID);
      updateEvent(state.leagues[lid], event, { status: newstatus });
      // await updateDoc(eventRef, "status", newstatus);
    }
  };

  if (
    (!event.status || event.status === "") &&
    (!event.messages || event.messages.length === 0) &&
    (!showDetails ||
      !(
        state.leagues[lid].admins.includes(state.loggedPlayer?.uid || "") ||
        state.tokenResult?.claims.license === "sysadmin"
      ))
  ) {
    return <></>;
  } else {
    return (
      <Box>
        <Divider sx={{ marginTop: 1 }} />
        <Stack direction="row" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            sx={{
              marginTop: 1,
              border: 0,
              borderRadius: "10px",
              backgroundColor: "#eee",
              display: "flex",
              padding: 1,
              flexGrow: 1,
            }}
          >
            {showModal ? (
              <EventMessagesDialog
                key="EventMessagesDialog"
                event={event}
                lid={lid}
                showDetails={showDetails}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            ) : (
              <></>
            )}

            <Typography
              sx={{ fontSize: 12, flexGrow: 1 }}
              onClick={() => {
                if (
                  state.leagues[lid].admins.includes(
                    state.loggedPlayer?.uid || ""
                  ) ||
                  state.tokenResult?.claims.license === "sysadmin"
                ) {
                  changeStatus();
                }
              }}
            >
              {event.status}
            </Typography>

            {/* {showDetails &&
            state.leagues[lid].admins.includes(
              state.loggedPlayer?.uid || ""
            ) ? (
              <IconButton onClick={changeStatus}>
                <EditNoteIcon />
              </IconButton>
            ) : (
              <></>
            )} */}
          </Stack>
          <Box sx={{ marginTop: 2, paddingLeft: 2 }}>
            <Badge
              badgeContent={event.messages?.length || 0}
              color="primary"
              max={999}
            >
              <ChatBubbleOutlineIcon
                onClick={(e) => {
                  setShowModal(true);
                }}
              />
            </Badge>
          </Box>
        </Stack>
      </Box>
    );
  }
};
