import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";
import dayjs, { Dayjs } from "dayjs";

// LODASH

import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  doc,
  updateDoc,
  deleteField,
  addDoc,
  setDoc,
  deleteDoc,
  where,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Switch,
  Icon,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  FormGroup,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//
// TYPES
//

import { Event } from "./Event";
import { EventSummary, EventType, Invitation, PlayerType } from "../types";
import { PlayerDetailsType, LeagueType } from "../types";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import FilterListIcon from "@mui/icons-material/FilterList";
import BuildIcon from "@mui/icons-material/Build";
import CheckIcon from "@mui/icons-material/Check";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { AppContext, updateEvent, updateLeague } from "../App";

import { style } from "./Event";
import { eventCourtTimes, firestoreAutoId } from "../processEmails";
import { EventForm } from "./EventForm";
import { LeaguePlayerForm } from "./LeaguePlayerForm";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { Label } from "@mui/icons-material";
import { ParticipantList } from "./ParticipantList";

type EditLeagueProps = {
  event: EventType;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  league: LeagueType;
  isnewleague: boolean;
  isnewevent: boolean;
};

export const EditLeague = ({
  showModal,
  setShowModal,
  league,
  event,
  isnewleague,
  isnewevent,
}: EditLeagueProps) => {
  const { state, setState } = useContext(AppContext);

  const [eventDescription, setEventDescription] = useState(
    event.description || ""
  );
  const [eventLocation, setEventLocation] = useState(event.location || "");
  const [eventAddress, setEventAddress] = useState(event.address || "");
  const [eventDateTime, setEventDateTime] = useState(dayjs(event.when));
  const [eventTimeZone, setEventTimeZone] = useState(event.timezone || "");

  const [leagueName, setLeagueName] = useState(league.name);
  const [leagueEventType, setLeagueEventType] = useState(league.eventtype);
  const [leagueMinutes, setLeagueMinutes] = useState(league.minutes || 90);
  const [leagueStandalone, setLeagueStandalone] = useState(
    league.standalone === undefined ? true : league.standalone
  );

  const participantHash = () => {
    return _.keyBy(
      Object.keys(league.playerDetails).map((k) => {
        return { ...league.playerDetails[k], PlayerID: k };
      }),
      (p) => p.PlayerID
    );
  };

  // compute initial list of selected players
  const [selectedPlayers, setSelectedPlayers] = useState<{
    [PlayerID: string]: PlayerDetailsType & { PlayerID: string };
  }>(participantHash());

  // accordion
  const [acc, setAcc] = useState("details");

  //
  // delete event
  //

  const ondelete = async () => {
    if (window.confirm("Delete this event")) {
      if (league.standalone) {
        console.log("Deleting league as this is a standalone event");
        await deleteDoc(doc(db, "leagues", league.LeagueID)); // deletion of league will delete events and emails
      } else {
        console.log("Deleting single event of recurring league");
        const eventRef = doc(
          db,
          "leagues",
          league.LeagueID,
          "events",
          event.PlayingDayID
        );
        await deleteDoc(eventRef); // deletion of event will delete emails
      }
    }
  };

  //
  // save league
  //

  const save = async () => {
    // this may be "", then we need to create league

    const leagueModified =
      leagueName !== league.name ||
      leagueEventType !== league.eventtype ||
      leagueMinutes !== league.minutes ||
      leagueStandalone !== league.standalone;
    const participantsModified = !_.isEqual(participantHash(), selectedPlayers);
    const eventModified =
      eventDescription !== event.description ||
      eventLocation !== event.location ||
      eventAddress !== event.address ||
      eventDateTime !== dayjs(event.when) ||
      eventTimeZone !== event.timezone;

    const writeLeague = isnewleague || leagueModified || participantsModified;

    // save changes to the league
    if (writeLeague) {
      const playing = _.uniq(
        _.flattenDeep(
          state.events[league.LeagueID]
            ? Object.values(state.events[league.LeagueID]).map((e) =>
                Object.values(e.matches).map((m) =>
                  m.players.map((p) => p.PlayerID)
                )
              )
            : []
        )
      );
      // console.log(JSON.stringify(playing, null, 2));

      const playingNotInLeague = playing.reduce((p, c) => {
        return p.concat(!selectedPlayers[c] ? [c] : []);
      }, [] as string[]);

      if (
        playingNotInLeague.length === 0 ||
        window.confirm(
          "The following removed players are in matches. Proceed? " +
            playingNotInLeague
              .map(
                (pid) =>
                  state.leagues[league.LeagueID].playerDetails[pid]?.name ||
                  "unknown"
              )
              .join(",")
        )
      ) {
        const leagueRef = doc(db, "leagues", league.LeagueID);

        const newleague = _.cloneDeep(league);
        newleague.players = Object.keys(selectedPlayers);
        // newleague.admins = Object.keys(selectedPlayers).filter(
        //   (sp) => selectedPlayers[sp].isadmin
        // );
        newleague.playerDetails = selectedPlayers;
        newleague.name = leagueName;
        newleague.minutes = leagueMinutes;
        newleague.standalone = leagueStandalone;
        newleague.eventtype = leagueEventType;

        // setting the initial event summary
        const newSummary = {
          when: eventDateTime.toISOString(),
          playerTimeAndResults: eventCourtTimes(event),
        };

        if (isnewleague) {
          console.log("Creating new league");
          newleague.eventsSummary = {};
          newleague.eventsSummary[event.PlayingDayID] = newSummary;
          await setDoc(leagueRef, newleague);
        } else {
          console.log("Updating league");
          updateLeague(league, {
            players: Object.keys(selectedPlayers),
            // admins: Object.keys(selectedPlayers).filter(
            //   (sp) => selectedPlayers[sp].isadmin
            // ),
            playerDetails: selectedPlayers,
            name: leagueName,
            eventtype: leagueEventType,
            minutes: leagueMinutes,
            standalone: leagueStandalone,
            ["eventsSummary." + event.PlayingDayID]: newSummary,
          });
        }
      }
    }

    // save changes to the event
    if (isnewevent || eventModified) {
      // saving

      //   alert("Create in " + lid);
      const eventRef = doc(
        db,
        "leagues",
        league.LeagueID,
        "events",
        event.PlayingDayID
      );

      const newevent = _.cloneDeep(event);
      newevent.description = eventDescription;
      newevent.location = eventLocation;
      newevent.address = eventAddress;
      newevent.when = eventDateTime.toISOString();
      newevent.timezone = eventTimeZone;
      // this was not set before, as the newly minted league
      // and new event were passed at once as component parameters
      if (isnewevent) newevent.LeagueID = league.LeagueID;

      newevent.players = _.clone(league.players);
      // subsequent updates will be done by cloud function

      if (isnewevent) {
        await setDoc(eventRef, newevent);
      } else {
        const changes: { [a: string]: any } = {
          description: eventDescription,
          location: eventLocation,
          address: eventAddress,
          when: eventDateTime.toISOString(),
          timezone: eventTimeZone,
        };

        // if we are changing the time, or location, we may need to remove confirmations
        const nbconfirmed = Object.entries(event.availabilities).filter(
          ([k, v]) => v.confirmed
        ).length;
        if (nbconfirmed > 0) {
          const timeChanged = eventDateTime.toISOString() !== event.when;
          const locationChanged =
            eventLocation !== event.location || eventAddress !== event.address;
          const message =
            timeChanged && locationChanged
              ? "Time and location have changed, "
              : timeChanged
              ? "The time of the event has changed, "
              : locationChanged
              ? "The location of the event has changed, "
              : null;
          if (
            message &&
            window.confirm(
              message + "do you want to clear player confirmations?"
            )
          ) {
            Object.entries(event.availabilities).forEach(([k, v]) => {
              if (v.confirmed) {
                changes["availabilities." + k + ".confirmed"] = false;
              }
            });
          }
        }

        updateEvent(league, event, changes);
        // await updateDoc(
        //   eventRef,
        //   "description",
        //   eventDescription,
        //   "location",
        //   eventLocation,
        //   "when",
        //   eventDateTime.toISOString(),
        //   "timezone",
        //   eventTimeZone
        // );
      }

      // if we have modified an event's when, we update the league
      if (
        (!writeLeague && !isnewevent && newevent.when !== event.when) ||
        (!writeLeague && isnewevent)
      ) {
        if (isnewevent) {
          console.log("New event, writing league");
        } else {
          console.log("Not new event, but new when, wirting league");
        }

        // const leagueRef = doc(db, "leagues", league.LeagueID);
        const newEventSummary: EventSummary = {
          when: newevent.when,
          playerTimeAndResults: eventCourtTimes(newevent),
        };
        updateLeague(league, {
          ["eventsSummary." + newevent.PlayingDayID]: newEventSummary,
        });
        // updateDoc(
        //   leagueRef,
        //   "eventsSummary." + newevent.PlayingDayID,
        //   newEventSummary
        // );
      }
    }

    setShowModal(false);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Modal
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="column">
              <Accordion
                expanded={acc === "details"}
                onChange={() => {
                  setAcc("details");
                }}
              >
                <AccordionSummary>Name, description, location</AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={1}>
                    <TextField
                      label={leagueStandalone ? "Event name" : "League name"}
                      onChange={(event) => {
                        setLeagueName(event.target.value);
                      }}
                      value={leagueName}
                      required
                    />
                    <TextField
                      size="small"
                      label={
                        leagueStandalone
                          ? "Event type (e.g. match, practice)"
                          : "League type (e.g. match, practice)"
                      }
                      onChange={(event) => {
                        setLeagueEventType(event.target.value);
                      }}
                      value={leagueEventType}
                      required
                    />

                    <Stack
                      direction="row"
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography sx={{ paddingTop: 0.9, paddingRight: 1.5 }}>
                        single event
                      </Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={<Switch checked={!leagueStandalone} />}
                          label="league"
                          onChange={(event) => {
                            if (
                              ["sysadmin", "professional"].includes(
                                state.tokenResult?.claims.license as string
                              ) ||
                              leagueStandalone === true
                            ) {
                              setLeagueStandalone(!leagueStandalone);
                            } else {
                              alert(
                                "Please upgrade plan to professional to manage leagues"
                              );
                            }
                          }}
                        />
                      </FormGroup>
                    </Stack>

                    <TextField
                      size="small"
                      value={eventDescription}
                      label="Event description"
                      onChange={(event) => {
                        setEventDescription(event.currentTarget.value);
                      }}
                      sx={{ marginTop: 3 }}
                    />
                    <TextField
                      value={eventLocation}
                      label="Event location"
                      onChange={(event) => {
                        setEventLocation(event.currentTarget.value);
                      }}
                      sx={{ marginTop: 3 }}
                    />
                    <TextField
                      size="small"
                      value={eventAddress}
                      label="Address"
                      onChange={(event) => {
                        setEventAddress(event.currentTarget.value);
                      }}
                      sx={{ marginTop: 3 }}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={acc === "time"}
                onChange={() => {
                  setAcc("time");
                }}
              >
                <AccordionSummary>Time and duration</AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column">
                    <MobileDateTimePicker
                      value={eventDateTime}
                      format="LLLL"
                      onChange={(v) => {
                        if (v) setEventDateTime(v);
                      }}
                    />
                    <Stack
                      direction="row"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 2,
                      }}
                    >
                      <TextField
                        size="small"
                        key="timezone"
                        disabled
                        value={eventTimeZone ? eventTimeZone : ""}
                        label="Time zone"
                        onChange={() => {}}
                      />
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setEventTimeZone(
                            Intl.DateTimeFormat().resolvedOptions().timeZone
                          );
                        }}
                      >
                        update
                      </Button>
                    </Stack>
                    <Typography sx={{ marginTop: 3 }}>
                      Default match duration in minutes
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      Each match can have a different duration. Match duration
                      is used when calculating player court time.
                    </Typography>
                    <Slider
                      sx={{ marginTop: 4 }}
                      aria-label="Default match duration"
                      valueLabelDisplay="on"
                      min={0}
                      max={180}
                      step={5}
                      onChange={(event, newvalue) => {
                        setLeagueMinutes(
                          typeof newvalue == "number" ? newvalue : 0
                        );
                      }}
                      value={leagueMinutes}
                      marks
                    ></Slider>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Stack
                direction="row"
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    save();
                  }}
                >
                  save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    ondelete();
                  }}
                >
                  delete
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Modal>
      </LocalizationProvider>
    </>
  );
};
