import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  updateDoc,
  setDoc,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

import { User as FirebaseUser } from "firebase/auth";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  TextField,
  Divider,
  Select,
  MenuItem,
  List,
  ListItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Event } from "./Event";
import {
  EmailTemplateTypeType,
  EventType,
  LicenseType,
  PartialType,
  PlayerType,
  hEventType,
} from "../types";

// MUI ICONS

import AddIcon from "@mui/icons-material/Add";

import {
  LeagueType,
  EmailTemplateType,
  AdminContextContent,
  hTemplateType,
} from "../types";
import { AppContext } from "../App";
import { AdminContext } from "../App";

import { style } from "./Event";
import { firestoreAutoId } from "../processEmails";

import { TemplateForm, TemplateOption } from "./EmailTemplates";
import { AdminPartials } from "./EmailPartials";

const AdminTemplates = () => {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  const [showFilter, setShowFilter] = useState(false);
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    EmailTemplateType | undefined
  >(undefined);

  return (
    <Box>
      <Typography>
        {Object.keys(adminState.templates).length.toString()} templates
      </Typography>

      <List
        component="div"
        role="list"
        sx={{ overflow: "auto", width: "100%", justifyContent: "center" }}
      >
        {Object.values(adminState.templates).map((t) => (
          <ListItem
            sx={{
              display: "flex",
            }}
            key={t.EmailTemplateID}
            onClick={() => {
              if (
                selectedTemplate &&
                selectedTemplate.EmailTemplateID == t.EmailTemplateID
              ) {
                setShowTemplateForm(true);
              } else {
                setSelectedTemplate(t);
              }
            }}
          >
            <Paper
              sx={{
                width: "100%",
                padding: 2,
                backgroundColor:
                  selectedTemplate &&
                  selectedTemplate.EmailTemplateID == t.EmailTemplateID
                    ? "#ddc"
                    : "#fff",
              }}
            >
              <Stack direction="column">
                <Typography sx={{ fontSize: "12" }}>{t.name}</Typography>
                <Typography sx={{ fontSize: "10" }}>{t.description}</Typography>
              </Stack>
            </Paper>
          </ListItem>
        ))}
      </List>
      {showTemplateForm ? (
        <TemplateForm
          showModal={showTemplateForm}
          setShowModal={(newval) => {
            // if we are closing the dialog, we unselect the template
            if (newval == false) {
              setSelectedTemplate(undefined);
            }
            setShowTemplateForm(newval);
          }}
          template={
            selectedTemplate || {
              EmailTemplateID: "",
              name: "",
              description: "",
              type: "html",
              subject: "",
              contents: "",
              toGroups: [],
              lessGroups: [],
              individualized: false,
              test: false,
              options: [],
            }
          }
        />
      ) : (
        <></>
      )}

      <Button
        variant="contained"
        onClick={() => {
          setSelectedTemplate(undefined);
          setShowTemplateForm(true);
        }}
      >
        New template
      </Button>
    </Box>
  );
};

type AdminUserFormProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  user: PlayerType;
};

const AdminUserForm = ({
  showModal,
  setShowModal,
  user,
}: AdminUserFormProps) => {
  const saveUser = async () => {
    const templateRef = doc(db, "players", editing.PlayerID);
    setDoc(templateRef, editing);
    setShowModal(false);
  };

  const [editing, setEditing] = useState(_.cloneDeep(user));

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="column" spacing={2}>
          <TextField
            label="fullname"
            value={editing.fullname}
            onChange={(event) => {
              setEditing({ ...editing, fullname: event.currentTarget.value });
            }}
          />
          <TextField
            label="license expires"
            value={editing.licenseExpires}
            onChange={(event) => {
              setEditing({
                ...editing,
                licenseExpires: event.currentTarget.value,
              });
            }}
          />
          <TextField
            label="license"
            value={editing.CustomClaims?.license || ""}
            onChange={(event) => {
              setEditing({
                ...editing,
                CustomClaims: {
                  ...(editing.CustomClaims || {}),
                  license: event.currentTarget.value as LicenseType,
                },
              });
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              saveUser();
            }}
          >
            Save
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const AdminUsers = () => {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [users, setUsers] = useState<PlayerType[]>([]);
  const [user, setUser] = useState<PlayerType | undefined>(undefined);

  const fetchUsers = async () => {
    console.log("Searching");
    var q = query(
      collection(db, "players"),
      orderBy("fullname"),
      where("fullname", ">=", search),
      where("fullname", "<", search + "~")
    );

    const docs = await getDocs(q);
    setUsers(
      docs.docs.map((d) => {
        return { ...(d.data() as PlayerType), PlayerID: d.id };
      })
    );
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField
            id="search"
            size="small"
            label="search"
            value={search}
            onChange={(event) => {
              setSearch(event.currentTarget.value);
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              fetchUsers();
            }}
          >
            Search
          </Button>
        </Stack>

        {user ? (
          <AdminUserForm
            showModal={user ? true : false}
            setShowModal={() => {
              setUser(undefined);
            }}
            user={user}
          />
        ) : (
          <></>
        )}

        {users.map((u) => {
          return (
            <Button
              key={u.PlayerID}
              onClick={() => {
                setUser(u);
              }}
            >
              {u.fullname}
            </Button>
          );
        })}
      </Stack>
    </>
  );
};

function Admin() {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  const [panel, setPanel] = useState("templates");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (state) {
    return (
      <Box sx={{ marginTop: 7, marginBottom: 20 }}>
        <Accordion
          expanded={panel === "templates"}
          onChange={() => {
            setPanel("templates");
          }}
        >
          <AccordionSummary>Templates</AccordionSummary>
          <AccordionDetails>
            <AdminTemplates></AdminTemplates>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel === "partials"}
          onChange={() => {
            setPanel("partials");
          }}
        >
          <AccordionSummary>Partials</AccordionSummary>
          <AccordionDetails>
            <AdminPartials></AdminPartials>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel === "users"}
          onChange={() => {
            setPanel("users");
          }}
        >
          <AccordionSummary>Users</AccordionSummary>
          <AccordionDetails>
            <AdminUsers />
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  } else return <></>;
}

export default Admin;
