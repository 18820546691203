import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

// MUI

import {
  IconButton,
  Box,
  Typography,
  Stack,
  Button,
  Divider,
} from "@mui/material";

// MUI ICONS

import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PlaceIcon from "@mui/icons-material/Place";

// TYPES

import {
  AppContextContent,
  EventType,
  PlayerDetailsTypeWithAvailability,
} from "../types";

import { AppContext } from "../App";

import { EventProps, hPlayersWithAvailabilitiesType } from "./Event";
import { EventForm } from "./EventForm";
import { style } from "./Event";
import { setPlayerAvailability } from "../App";
import { EditLeague } from "./EditLeague";
import { EventAvailabilityForm } from "./EventAvailabilityForm";
import { firstMatch } from "../processEmails";

type EventTitleHeaderProps = {
  event: EventType;
  showDetails: boolean;
  lid: string;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EventTitleHeader = ({
  event,
  showDetails,
  lid,
  setEditing,
}: EventTitleHeaderProps) => {
  const { state, setState } = useContext(AppContext);

  return (
    <Box
      onClick={
        !showDetails
          ? () => {
              setState(
                (cur: AppContextContent) =>
                  ({
                    ...cur,
                    currentEvent: event.PlayingDayID,
                    currentLeague: lid,
                    currentPlayer: state.loggedPlayer?.uid,
                    page: 2,
                  } as AppContextContent)
              );
            }
          : // let's edit it if we are admin
            () => {
              if (
                (state.loggedPlayer &&
                  state.leagues[lid].admins.includes(state.loggedPlayer.uid)) ||
                state.tokenResult?.claims.license === "sysadmin"
              ) {
                setEditing(true);
              }
            }
      }
    >
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography align="left" sx={{ whiteSpace: "nowrap" }}>
          {dayjs(event.when).format("dddd LL")}
        </Typography>
        <Typography align="right" sx={{ whiteSpace: "nowrap" }}>
          {dayjs(event.when).format("hh:mm a")}
        </Typography>
      </Stack>

      <Stack direction="row">
        <Box sx={{ width: "40px" }}></Box>
        <Stack direction="column" sx={{ flexGrow: 1 }}>
          <Typography align="center">
            <b>{state.leagues[lid].name}</b>
          </Typography>
          <Typography align="center">{event.description}</Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "10pt",
            }}
          >
            {event.location}
          </Typography>
        </Stack>
        {event.address ? (
          <IconButton
            onClick={(e) => {
              if (event.address) {
                e.preventDefault();
                e.stopPropagation();
                var otherWindow = window.open();
                if (otherWindow) {
                  otherWindow.opener = null;
                  otherWindow.location =
                    "https://www.google.com/maps/place/" +
                    encodeURI(event.address);
                }
              }
            }}
          >
            <PlaceIcon />
          </IconButton>
        ) : (
          <Box sx={{ width: "40px" }}></Box>
        )}
      </Stack>
    </Box>
  );
};

export const EventTitle = (
  props: EventProps & { player?: PlayerDetailsTypeWithAvailability } & {
    hideAvailAndSpots?: boolean;
  }
) => {
  const { state, setState } = useContext(AppContext);
  const { event, lid, player, showDetails, hideAvailAndSpots } = props;
  const [showModal, setShowModal] = useState(false);
  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  const [editing, setEditing] = useState(false);

  if (state && state.loggedPlayer?.uid) {
    return (
      <>
        {editing ? (
          <EditLeague
            showModal={editing}
            setShowModal={setEditing}
            event={event}
            league={state.leagues[lid]}
            isnewevent={false}
            isnewleague={false}
          />
        ) : (
          //   <EventForm
          //     event={event}
          //     showModal={editing}
          //     setShowModal={setEditing}
          //     lid={lid}
          //   />
          <></>
        )}

        {showModal && player ? (
          <EventAvailabilityForm
            key="availabilityform"
            lid={lid}
            event={event}
            showModal={showModal}
            setShowModal={setShowModal}
            player={player}
          />
        ) : (
          <></>
        )}
        <Stack direction="column" spacing={1}>
          <EventTitleHeader
            event={event}
            lid={lid}
            showDetails={showDetails}
            setEditing={setEditing}
          />
          <Divider />
          {!hideAvailAndSpots ? (
            <Stack
              direction="row"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button variant="outlined" size="small" onClick={handleOpen}>
                {player?.playing && player?.availability === "All" ? (
                  <Stack direction="row" spacing={1}>
                    <SportsTennisIcon />
                    <Typography>
                      {player.confirmed ? "confirmed" : "scheduled"}
                    </Typography>
                  </Stack>
                ) : !player?.playing &&
                  (player?.availability === "none" ||
                    player?.availability === "unknown" ||
                    !player?.availability) ? (
                  <Stack direction="row" spacing={1}>
                    {player?.availability === "none" ? (
                      <CancelIcon />
                    ) : (
                      <QuestionMarkIcon />
                    )}
                    <Typography sx={{ whiteSpace: "nowrap" }}>
                      {player?.availability === "none"
                        ? "not available"
                        : "availability"}{" "}
                    </Typography>
                  </Stack>
                ) : !player?.playing &&
                  player?.availability &&
                  player?.availability !== "unknown" &&
                  player?.availability !== "none" ? (
                  <Stack direction="row" spacing={1}>
                    <EmojiPeopleIcon />
                    <Typography sx={{ whiteSpace: "nowrap" }}>
                      {player?.availability !== "All"
                        ? player?.availability
                        : "available"}
                    </Typography>
                  </Stack>
                ) : player?.playing && player?.availability === "none" ? (
                  <Stack direction="row" spacing={1}>
                    <ErrorIcon />
                    <Typography sx={{ whiteSpace: "nowrap" }}>
                      no longer avail.
                    </Typography>
                  </Stack>
                ) : player?.playing &&
                  (!player?.availability ||
                    player?.availability === "unknown") ? (
                  <Stack direction="row" spacing={1}>
                    <SportsTennisIcon />
                    <Typography sx={{ whiteSpace: "nowrap" }}>
                      unknown avail.
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={1}>
                    <SportsTennisIcon />
                    <Typography sx={{ whiteSpace: "nowrap" }}>
                      {player?.availability}
                    </Typography>
                  </Stack>
                )}
              </Button>

              <Box sx={{ paddingRight: 0 }}>
                {(() => {
                  // old method
                  // const nbopenspots =
                  //   event.matches && Object.keys(event.matches).length > 0
                  //     ? Object.values(event.matches).reduce(
                  //         (p, c) => p + c.openspots.length,
                  //         0
                  //       )
                  //     : 0;

                  // const openspots = eventOpenSpots(event);
                  // const nbopenspots = openspots.length;

                  const nbopenspots = event.nbopenspots || 0;

                  // console.log(JSON.stringify(openspots, null, 2));

                  const fm = firstMatch(
                    event,
                    state.leagues[lid],
                    state.loggedPlayer.uid
                  );

                  if (fm && !showDetails) {
                    return (
                      <Stack direction="row-reverse" spacing={0}>
                        <Typography
                          sx={{
                            fontSize: "9pt",
                            paddingLeft: 1,
                            paddingRight: 0,
                            lineHeight: 1,
                            border: 0,
                            textAlign: "end",
                          }}
                        >
                          {fm.court}
                          <br />
                          {fm.participants.length > 0
                            ? " " + fm.participants.join(", ")
                            : ""}
                          {dayjs(fm.startTime).isSame(dayjs(event.when))
                            ? ""
                            : " at " + dayjs(fm.startTime).format("LT")}
                        </Typography>
                      </Stack>
                    );
                  } else {
                    return (
                      <Stack direction="row-reverse" spacing={1}>
                        {nbopenspots > 0 ? <RadioButtonCheckedIcon /> : <></>}
                        <Typography>
                          {nbopenspots == 0
                            ? "full"
                            : nbopenspots.toString() +
                              " spot" +
                              (nbopenspots > 1 ? "s" : "")}
                          {}
                        </Typography>
                      </Stack>
                    );
                  }
                })()}
              </Box>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      </>
    );
  } else return <></>;
};
