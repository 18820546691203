import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";

import {
  hEmailGroupsType,
  processGroups,
  processToList,
  processTemplate2,
  computeTimeToSend,
  buildEmailDataPass1,
  buildEmailDataPass2,
  getSubjectContentsOptions,
} from "../processEmails";

// LODASH

import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";
import { AdminContext } from "../App";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  doc,
  deleteField,
  addDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Switch,
  Icon,
  Menu,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormGroup,
  Tooltip,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Theme, useTheme } from "@mui/material/styles";

//
// TYPES
//

import {
  EventType,
  PlayerDetailsType,
  LeagueType,
  EmailWhenCategoryType,
  EmailGroupType,
  ToLessGroups,
  EmailTemplateOption,
} from "../types";
import { EmailType } from "../types";

// MUI ICONS

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { AppContext } from "../App";
import { firestoreAutoId } from "../processEmails";

//
// Finally (because there is the extend command), DAYJS
//
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { FullScreenText } from "./EmailTemplates";
import { groups } from "./EmailsCompose2";
dayjs.extend(relativeTime);

//
// CONSTANTS
//

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: any[], theme: Theme) {
  return {
    fontWeight:
      !personName || personName.indexOf(name as any) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const composestyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#eef",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type DefaultRecipientsProps = {
  formValues: EmailType | ToLessGroups;
  setFormValues: (et: EmailType | ToLessGroups) => void;
  lid: string;
};

export const DefaultRecipients = ({
  formValues,
  setFormValues,
  lid,
}: DefaultRecipientsProps) => {
  const { state, setState } = useContext(AppContext);
  const theme = useTheme();

  const league = state.leagues[lid];

  return (
    <Stack direction="column" sx={{ mt: 1, mb: 1 }} spacing={2}>
      <FormControl fullWidth>
        <InputLabel id="toGroups-label">Add groups</InputLabel>
        <Select
          size="small"
          labelId="toGroups-label"
          label="Add groups"
          id="toGroups-select"
          multiple
          value={formValues.toGroups}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              toGroups: event.target.value as EmailGroupType[],
            })
          }
          // input={<OutlinedInput label="By default, send to" />}
          MenuProps={MenuProps}
        >
          {groups.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, formValues.toGroups, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lessGroups-label-label">Remove groups</InputLabel>
        <Select
          size="small"
          labelId="lessGroups-label"
          label="Remove groups"
          id="lessGroups-select"
          multiple
          value={formValues.lessGroups}
          onChange={(event) =>
            setFormValues({
              ...formValues,
              lessGroups: event.target.value as EmailGroupType[],
            })
          }
          // input={<OutlinedInput label="By default, send to" />}
          MenuProps={MenuProps}
        >
          {groups.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, formValues.lessGroups, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* if we don't have a league, we only display toGroups and lessGroups */}
      {(() => {
        if (league) {
          return (
            <>
              <FormControl fullWidth>
                <InputLabel id="toGroups-label">Add individuals</InputLabel>
                <Select
                  size="small"
                  labelId="toIndividual-label"
                  label="Add individuals"
                  id="toIndividual-select"
                  multiple
                  value={(formValues as EmailType).toIndividuals}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      toIndividuals: event.target.value as string[],
                    })
                  }
                  // input={<OutlinedInput label="By default, send to" />}
                  MenuProps={MenuProps}
                >
                  {Object.keys(league.playerDetails)
                    .sort((a, b) =>
                      league.playerDetails[a].name >
                      league.playerDetails[b].name
                        ? 1
                        : -1
                    )
                    .map((pk) => (
                      <MenuItem
                        key={pk}
                        value={pk}
                        style={getStyles(
                          pk,
                          (formValues as EmailType).toIndividuals,
                          theme
                        )}
                      >
                        {league.playerDetails[pk].fullname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="lessIndividuals-label">
                  Remove individuals
                </InputLabel>
                <Select
                  size="small"
                  labelId="lessIndividual-label"
                  label="Remove individuals"
                  id="lessIndividual-select"
                  multiple
                  value={(formValues as EmailType).lessIndividuals}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      lessIndividuals: event.target.value as string[],
                    })
                  }
                  // input={<OutlinedInput label="By default, send to" />}
                  MenuProps={MenuProps}
                >
                  {Object.keys(league.playerDetails)
                    .sort((a, b) =>
                      league.playerDetails[a].name >
                      league.playerDetails[b].name
                        ? 1
                        : -1
                    )
                    .map((pk) => (
                      <MenuItem
                        key={pk}
                        value={pk}
                        style={getStyles(
                          pk,
                          (formValues as EmailType).lessIndividuals,
                          theme
                        )}
                      >
                        {league.playerDetails[pk].fullname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </>
          );
        }
      })()}
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={formValues.individualized} />}
          label="one email per recipient"
          onChange={(event) => {
            setFormValues({
              ...formValues,
              individualized: !formValues.individualized,
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={formValues.test} />}
          label="test email (send to self)"
          onChange={(event) => {
            setFormValues({
              ...formValues,
              test: !formValues.test,
            });
          }}
        />
      </FormGroup>
    </Stack>
  );
};

type EmailOptionValueProps = {
  option: EmailTemplateOption;
  index: number;
  setOptionValue: (val: boolean | string, index: number) => void;
};

const EmailOptionValue = ({
  option,
  index,
  setOptionValue,
}: EmailOptionValueProps) => {
  const [editTextField, setEditTextField] = useState(false);

  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      {editTextField && typeof option.value == "string" ? (
        <FullScreenText
          onClose={() => {
            setEditTextField(false);
          }}
          text={option.value}
          setText={(newtext) => {
            setOptionValue(newtext as string, index);
          }}
        />
      ) : (
        <></>
      )}
      {option.type === "boolean" ? (
        <Stack direction="row" sx={{ width: "100%" }}>
          <FormControlLabel
            label={
              <Typography sx={{ lineHeight: 1 }}>
                {option.description}
              </Typography>
            }
            control={
              <Checkbox
                checked={option.value ? true : false}
                onChange={(e) => {
                  setOptionValue(option.value ? false : true, index);
                }}
              />
            }
          />
        </Stack>
      ) : (
        <Stack direction="column" sx={{ width: "100%" }}>
          <Typography sx={{ lineHeight: 1 }}>{option.description}</Typography>
          <Stack direction="row">
            <TextField
              multiline
              rows={3}
              value={option.value}
              onChange={(e) => {
                setOptionValue(e.target.value, index);
              }}
              sx={{ flexGrow: 1 }}
            />
            <Button
              size="small"
              onClick={() => {
                setEditTextField(true);
              }}
            >
              ...
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

type ComposeProps = {
  event: EventType;
  showModal: boolean;
  setShowModal: (n: boolean) => void;
  lid: string;
  email: EmailType;
};

export const Compose = ({
  event,
  showModal,
  setShowModal,
  lid,
  email,
}: ComposeProps) => {
  const { state: state, setState: setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  const [editing, setEditing] = useState<EmailType>(_.cloneDeep(email));
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  // const [preview, setPreview] = useState(true);

  // will store locally the compute groups
  // recomputed with app context or admin context are changed
  const [groups, setGroups] = useState<hEmailGroupsType>({});
  const [toList, setToList] = useState<string[]>([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onSave = async () => {
    if (state.loggedPlayer) {
      const toSave: EmailType = _.cloneDeep(editing);
      if (toSave.EmailID == "") {
        toSave.EmailID = firestoreAutoId();
      }
      const emailRef = doc(
        db,
        "leagues",
        lid,
        "events",
        event.PlayingDayID,
        "emails",
        toSave.EmailID
      );

      // can't save undefined as a field value, removing fields
      if (!editing.subject) delete toSave.subject;
      if (!editing.contents) delete toSave.contents;

      // setting time to send
      toSave.sendAt = computeTimeToSend(event, editing);
      toSave.author = state.loggedPlayer.uid;

      if (
        toSave.sendAt > dayjs().toJSON() ||
        window.confirm("Email will be sent immeditaly, proceed?")
      ) {
        console.log(JSON.stringify(toSave, null, 2));

        await setDoc(emailRef, toSave);
        setShowModal(false);
      }
    }
  };

  const onDelete = async () => {
    if (event.PlayingDayID && window.confirm("Delete email?")) {
      const emailRef = doc(
        db,
        "leagues",
        lid,
        "events",
        event.PlayingDayID,
        "emails",
        editing.EmailID
      );
      await deleteDoc(emailRef);
      setShowModal(false);
    }
  };

  //
  // recomputing the groups whenever the state changes
  //

  useEffect(() => {
    setGroups(processGroups(state.leagues[lid], event));
  }, [state, adminState]);

  //
  // recomputing the tolist whenever the groups change
  //

  useEffect(() => {
    setToList(
      processToList(editing, groups).sort((a, b) =>
        state.leagues[lid].playerDetails[a].fullname >
        state.leagues[lid].playerDetails[b].fullname
          ? 1
          : -1
      )
    );
  }, [groups, editing]);

  const groupString = () => {
    var res = "";
    if (editing.toGroups && editing.toGroups.length > 0) {
      res += "+" + editing.toGroups.join(" +");
    }
    if (editing.lessGroups && editing.lessGroups.length > 0) {
      res += " -" + editing.lessGroups.join(" -");
    }
    if (editing.toIndividuals && editing.toIndividuals.length > 0) {
      res +=
        " +" +
        editing.toIndividuals
          .map((ti) => state.leagues[lid].playerDetails[ti].fullname)
          .join(" +");
    }
    if (editing.lessIndividuals && editing.lessIndividuals.length > 0) {
      res +=
        " -" +
        editing.lessIndividuals
          .map((ti) => state.leagues[lid].playerDetails[ti].fullname)
          .join(" -");
    }
    return res;
  };

  //
  // we compute what we are going to show for subject and contents
  // could be editing or could be preview
  //

  const subjectContentsOptions = getSubjectContentsOptions(
    editing,
    adminState.templates,
    adminState.partials || { partials: [] }
  );

  const subjectContents =
    state.loggedPlayer &&
    ((editing.TemplateID && adminState.templates[editing.TemplateID]) ||
      (editing.subject && editing.contents)) &&
    // preview &&
    expanded === "panel3"
      ? processTemplate2(
          state.leagues[lid],
          event,
          subjectContentsOptions.subject,
          subjectContentsOptions.contents,
          groups,
          // if we are previewing and sending multiple emails, we take the first recipient as example
          editing.individualized ? toList.slice(0, 1) : toList,
          state.loggedPlayer,
          editing.options,
          editing
        )
      : // : adminState.templates[editing.TemplateID]
        // ? adminState.templates[editing.TemplateID]
        { subject: "", contents: "" };

  if (state.loggedPlayer) {
    var eventData = buildEmailDataPass1(
      state.leagues[lid],
      event,
      editing.individualized ? toList.slice(0, 1) : toList
    );
    eventData = buildEmailDataPass2(
      state.leagues[lid],
      event,
      editing.individualized ? toList.slice(0, 1) : toList,
      state.loggedPlayer,
      eventData,
      editing
    );

    console.log(eventData);

    return (
      <Modal
        open={showModal ? true : false}
        onClose={() => {
          if (window.confirm("You will lose changes, close?")) {
            setShowModal(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={composestyle}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Stack direction="row" spacing={1}>
                <Stack direction="column" sx={{ textAlign: "right" }}>
                  <Typography>To:</Typography>
                </Stack>

                <Typography
                  onClick={(event) => {
                    if (expanded == "panel1") {
                      event.stopPropagation();
                      alert(
                        toList
                          .map(
                            (tl) =>
                              state.leagues[lid].playerDetails[tl].fullname
                          )
                          .join(", ")
                      );
                    }
                  }}
                >
                  {true
                    ? toList
                        // if expanded, we show 6, otherwise 2
                        .slice(0, expanded === "panel1" ? 6 : 1)
                        .map(
                          (tl) => state.leagues[lid].playerDetails[tl].fullname
                        )
                        .join(", ") +
                      (toList.length > (expanded === "panel1" ? 6 : 1)
                        ? " +" +
                          (
                            toList.length - (expanded === "panel1" ? 6 : 1)
                          ).toString()
                        : "")
                    : groupString()}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <DefaultRecipients
                formValues={editing}
                setFormValues={
                  setEditing as (et: EmailType | ToLessGroups) => void
                }
                lid={lid}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              When: {dayjs(editing.sendAt).format("LLLL")},{" "}
              {dayjs(editing.sendAt).fromNow()}
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={editing.whenCategory || "never"}
                  name="radio-buttons-group"
                  onChange={(newvalue) => {
                    setEditing({
                      ...editing,
                      whenCategory: newvalue.currentTarget
                        .value as EmailWhenCategoryType,
                      sendAt: computeTimeToSend(event, {
                        ...editing,
                        whenCategory: newvalue.currentTarget
                          .value as EmailWhenCategoryType,
                      }),
                    });
                  }}
                >
                  <FormControlLabel
                    value="never"
                    control={<Radio />}
                    label="Don't send - hold"
                  />
                  <FormControlLabel
                    value="immediately"
                    control={<Radio />}
                    label="Send right away"
                  />
                  <FormControlLabel
                    value="before"
                    control={<Radio />}
                    label="before the event - specify below:"
                  />
                </RadioGroup>
                {(() => {
                  const days = Math.floor(editing.whenValue / (60 * 24));
                  const hours = Math.floor(
                    (editing.whenValue - days * (60 * 24)) / 60
                  );
                  const minutes = Math.floor(
                    editing.whenValue - days * (60 * 24) - hours * 60
                  );

                  return (
                    <Stack direction="row" spacing={1}>
                      <TextField
                        label="days"
                        value={days.toString()}
                        onChange={(newvalue) => {
                          const nb =
                            Number.parseInt(newvalue.currentTarget.value) || 0;
                          const whenValue = nb * 60 * 24 + hours * 60 + minutes;
                          setEditing({
                            ...editing,
                            whenValue: whenValue,
                            sendAt: computeTimeToSend(event, {
                              ...editing,
                              whenValue: whenValue,
                            }),
                          });
                        }}
                      />
                      <TextField
                        label="hours"
                        value={hours.toString()}
                        onChange={(newvalue) => {
                          const nb =
                            Number.parseInt(newvalue.currentTarget.value) || 0;
                          const whenValue = days * 24 * 60 + nb * 60 + minutes;
                          if (!isNaN(nb)) {
                            setEditing({
                              ...editing,
                              whenValue: whenValue,
                              sendAt: computeTimeToSend(event, {
                                ...editing,
                                whenValue: whenValue,
                              }),
                            });
                          }
                        }}
                      />
                      <TextField
                        value={minutes.toString()}
                        label="minutes"
                        onChange={(newvalue) => {
                          const nb =
                            Number.parseInt(newvalue.currentTarget.value) || 0;
                          const whenValue = days * 24 * 60 + hours * 60 + nb;
                          if (!isNaN(nb)) {
                            setEditing({
                              ...editing,
                              whenValue: whenValue,
                              sendAt: computeTimeToSend(event, {
                                ...editing,
                                whenValue: whenValue,
                              }),
                            });
                          }
                        }}
                      />
                    </Stack>
                  );
                })()}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "options"}
            onChange={handleChange("options")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="options-content"
              id="options-header"
            >
              Options
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction="column"
                spacing={0}
                sx={{ width: "100%", maxHeight: "300px", overflow: "auto" }}
              >
                {editing.options.map((o, i) => (
                  <EmailOptionValue
                    option={o}
                    index={i}
                    setOptionValue={(e, i) => {
                      setEditing((editing) => {
                        const newoptions = _.cloneDeep(editing.options);
                        newoptions[i].value = e;
                        return { ...editing, options: newoptions };
                      });
                    }}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Preview (
              {adminState.templates[editing.TemplateID || ""]
                ? adminState.templates[editing.TemplateID || ""].name
                : "No template"}
              )
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column" spacing={2}>
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Template
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editing.TemplateID}
                    label="Template"
                    onChange={(event) => {
                      if (
                        (!editing.subject && !editing.contents) ||
                        window.confirm("You will lose your customizations")
                      ) {
                        setEditing({
                          ...editing,
                          subject: undefined,
                          contents: undefined,
                          TemplateID: event.target.value,
                        });
                      }
                    }}
                  >
                    {Object.values(adminState.templates).map((t) => (
                      <MenuItem
                        key={t.EmailTemplateID}
                        value={t.EmailTemplateID}
                      >
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {(() => {
                  const t = adminState.templates[editing.TemplateID || ""];

                  if (true) {
                    // if (preview) {
                    //
                    //
                    // PREVIEW
                    //
                    //

                    return (
                      <Stack direction="column" spacing={2}>
                        <Typography
                          sx={{ backgroundColor: "#eee", minHeight: "20px" }}
                        >
                          {subjectContents.subject}
                        </Typography>

                        {editing.TemplateID &&
                        adminState.templates[editing.TemplateID].type ==
                          "html" ? (
                          <Box
                            sx={{ maxHeight: "300px", overflow: "auto" }}
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: subjectContents.contents,
                            }}
                          ></Box>
                        ) : (
                          <Typography
                            sx={{
                              backgroundColor: "#eee",
                              minHeight: "100px",
                              maxHeight: "300px",
                              overflow: "auto",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {subjectContents.contents}
                          </Typography>
                        )}
                      </Stack>
                    );
                  } else {
                    //
                    //
                    // EDITING
                    //
                    //

                    return (
                      <Stack direction="column" spacing={2}>
                        <TextField
                          value={
                            editing.subject
                              ? editing.subject
                              : subjectContents.subject
                          }
                          onChange={(event) => {
                            setEditing({
                              ...editing,
                              subject: event.target.value,
                            });
                          }}
                        />
                        <TextField
                          multiline
                          rows={8}
                          value={
                            editing.contents != undefined
                              ? editing.contents
                              : subjectContents.contents
                          }
                          onChange={(event) => {
                            setEditing({
                              ...editing,
                              contents: event.target.value,
                            });
                          }}
                        />
                        <Typography
                          sx={{
                            whiteSpace: "pre",
                            height: "100px",
                            overflow: "auto",
                          }}
                        >
                          {JSON.stringify(eventData, null, 2)}
                        </Typography>
                      </Stack>
                    );
                  }
                })()}
              </Stack>
            </AccordionDetails>
          </Accordion>
          {/* <FormGroup>
            <FormControlLabel
              control={<Switch checked={preview} />}
              label="preview"
              onChange={(event) => {
                setPreview(!preview);
              }}
            />
          </FormGroup> */}
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              key="save"
              variant="contained"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              key="delete"
              onClick={() => {
                onDelete();
              }}
            >
              Delete
            </Button>
            <Button
              key="cancel"
              variant="contained"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    );
  } else {
    return <></>;
  }
};
