import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  updateDoc,
  setDoc,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

import { User as FirebaseUser } from "firebase/auth";

// MUI

import {
  IconButton,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { Event } from "./Event";
import { PartialDocumentType, PartialType } from "../types";

// MUI ICONS

import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { AdminContext } from "../App";

import { style } from "./Event";
import { firestoreAutoId } from "../processEmails";

import { FullScreenText, TemplateOption } from "./EmailTemplates";

export type PartialFormProps = {
  showModal: boolean;
  setShowModal: (a: boolean) => void;
  partial: PartialType;
};

export const PartialForm = ({
  partial,
  showModal,
  setShowModal,
}: PartialFormProps) => {
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const [edited, setEdited] = useState(_.cloneDeep(partial));
  const [editText, setEditText] = useState(false);

  const onSave = () => {
    const partialRef = doc(db, "configuration", "partials");
    const partials = _.cloneDeep(adminState.partials || { partials: [] });
    partials.partials = partials?.partials.map((p) =>
      p.PartialID === edited.PartialID ? edited : p
    );
    setDoc(partialRef, partials);
    setShowModal(false);
  };

  const onDelete = () => {
    if (window.confirm("Delete this partial?")) {
      const partialRef = doc(db, "configuration", "partials");
      const partials = _.cloneDeep(adminState.partials || { partials: [] });
      partials.partials = partials?.partials.filter(
        (p) => p.PartialID !== edited.PartialID
      );
      setDoc(partialRef, partials);
      setShowModal(false);
    }
  };

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="column" spacing={1}>
          <TextField
            value={edited.name}
            onChange={(e) => {
              setEdited({ ...edited, name: e.currentTarget.value });
            }}
          />
          <Stack direction="row">
            {editText ? (
              <FullScreenText
                onClose={() => {
                  setEditText(false);
                }}
                text={edited.contents}
                setText={(newtext) => {
                  setEdited({ ...edited, contents: newtext as string });
                }}
              />
            ) : (
              <></>
            )}
            <TextField
              multiline
              rows={5}
              value={edited.contents}
              onChange={(e) => {
                setEdited({ ...edited, contents: e.currentTarget.value });
              }}
              sx={{ flexGrow: 1 }}
            />
            <Button
              onClick={() => {
                setEditText(true);
              }}
            >
              ...
            </Button>
          </Stack>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={edited.isHtml} />}
              label="produces HTML"
              onChange={(event) => {
                setEdited({
                  ...edited,
                  isHtml: !edited.isHtml,
                });
              }}
            />
          </FormGroup>
          <Stack
            direction="column"
            sx={{
              border: 1,
              borderColor: "#ccc",
              padding: 1,
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            {edited.options.map((o, i) => (
              <TemplateOption
                option={o}
                index={i}
                setOption={(newoption, index) => {
                  const newoptions = _.cloneDeep(edited.options);
                  newoptions[index] = newoption;
                  setEdited({
                    ...edited,
                    options: newoptions,
                  });
                  // setOptions(newoptions);
                }}
                deleteOption={(index) => {
                  var newoptions = edited.options.filter((v, i) => i != index);
                  setEdited({
                    ...edited,
                    options: newoptions,
                  });
                }}
                moveUpOption={(index) => {
                  if (index > 0) {
                    const newoptions = _.cloneDeep(edited.options);
                    const down = newoptions[index - 1];
                    const up = newoptions[index];
                    newoptions[index - 1] = up;
                    newoptions[index] = down;
                    setEdited({
                      ...edited,
                      options: newoptions,
                    });
                  }
                }}
              />
            ))}
            <Button
              variant="contained"
              onClick={() => {
                const newoptions = _.cloneDeep(edited.options);
                newoptions.push({
                  type: "boolean",
                  name: "",
                  description: "",
                });
                setEdited({
                  ...edited,
                  options: newoptions,
                });
              }}
            >
              <AddIcon />
            </Button>
          </Stack>
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={onDelete}>
              Delete
            </Button>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export type PartialProps = {
  partial: PartialType;
  index: number;
  onUp: (a: number) => void;
};

export const Partial = ({ partial, index, onUp }: PartialProps) => {
  const [editing, setEditing] = useState(false);

  return (
    <>
      {editing ? (
        <PartialForm
          partial={partial}
          showModal={editing}
          setShowModal={setEditing}
        />
      ) : (
        <></>
      )}

      <Box
        sx={{
          border: 1,
          padding: 1,
          backgroundColor: partial.isHtml ? "#efe" : "#fff",
        }}
      >
        <Stack direction="row">
          <Typography
            sx={{ flexGrow: 1 }}
            onClick={() => {
              setEditing(true);
            }}
          >
            {partial.name}
          </Typography>
          {/* <Typography>{partial.contents}</Typography> */}
          <IconButton
            size="small"
            onClick={() => {
              onUp(index);
            }}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Stack>
      </Box>
    </>
  );
};

const swapArrayElements = (array: any, index1: any, index2: any) => {
  // Checking parameters for errors
  if (
    index1 < 0 ||
    index2 < 0 ||
    index1 >= array.length ||
    index2 >= array.length
  ) {
    console.error("Invalid index passed to swapArrayElements()");

    return array;
  }

  // Swapping elements and returning the mutated array
  [array[index1], array[index2]] = [array[index2], array[index1]];

  return array;
};

export const AdminPartials = () => {
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  const partials = adminState.partials || {
    partials: [],
  };

  const addPartial = () => {
    const partialRef = doc(db, "configuration", "partials");
    partials.partials.push({
      PartialID: firestoreAutoId(),
      name: "New partial",
      contents: "",
      options: [],
    });
    setDoc(partialRef, partials);
  };

  const setPartialWithJSON = (json: string) => {
    try {
      const newPartials = JSON.parse(json) as PartialDocumentType;
      const partialRef = doc(db, "configuration", "partials");
      setDoc(partialRef, newPartials);
    } catch (e) {
      alert("Couldn't parse");
    }
  };

  const onUp = (i: number) => {
    const partialRef = doc(db, "configuration", "partials");
    if (i > 0) {
      swapArrayElements(partials.partials, i, i - 1);
      setDoc(partialRef, partials);
    }
  };

  return (
    <Stack direction="column" spacing={1}>
      {partials.partials.map((p, i) => (
        <Partial key={p.PartialID} partial={p} index={i} onUp={onUp} />
      ))}
      <Button variant="contained">
        <AddIcon onClick={addPartial} />
      </Button>
      <TextField
        multiline
        rows={3}
        value={JSON.stringify(partials, null, 2)}
        onChange={(e) => setPartialWithJSON(e.currentTarget.value)}
      />
    </Stack>
  );
};
