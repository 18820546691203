import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  doc,
  deleteField,
  FieldValue,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Event } from "./Event";
import {
  AcceptedInvitation,
  EventType,
  Invitation,
  hEventType,
} from "../types";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import FilterListIcon from "@mui/icons-material/FilterList";
import BuildIcon from "@mui/icons-material/Build";

import { LeagueType } from "../types";
import { AppContext, updateInvitation, updatePlayerRecord } from "../App";

import { style } from "./Event";

type HomeInvitationProps = {
  invitation: Invitation;
};

export const HomeInvitation = ({ invitation }: HomeInvitationProps) => {
  const { state, setState } = useContext(AppContext);

  const decline = () => {
    if (
      state.playerRecord &&
      state.playerRecord &&
      state.playerRecord.invitations
    ) {
      // we remove this invitation from our list
      updatePlayerRecord(state.playerRecord, {
        ["invitations." + invitation.InvitationID]: deleteField(),
      });

      // we update the invitation in the league
      updateInvitation(invitation, { status: "declined" });
    }
  };

  const accept = () => {
    if (
      state.playerRecord &&
      state.playerRecord &&
      state.playerRecord.invitations
    ) {
      // we keep invitation in player record
      // this will trigger a function that will add us to league

      const acceptedInvitation: AcceptedInvitation = {
        ...invitation,
        status: "accepted",
        playerDetails: {
          fullname: state.playerRecord.fullname,
          name: state.playerRecord.fullname,
          email: state.playerRecord.email,
          phone: state.playerRecord.phone,
          contractshares: 0,
          external: false,
        },
      };

      const aiRef = doc(
        db,
        "acceptedinvitations",
        acceptedInvitation.InvitationID
      );
      setDoc(aiRef, acceptedInvitation);

      // now removing the accepted invitation from players
      updatePlayerRecord(state.playerRecord, {
        ["invitations." + invitation.InvitationID]: deleteField(),
      });
    }
  };

  return (
    <Paper sx={{ padding: 1 }}>
      <Stack>
        <Typography>On {dayjs(invitation.date).format("LLLL")}</Typography>
        <Typography>{invitation.fromName}</Typography>
        <Typography>
          Invited you to: <br />
          {invitation.leagueName}
        </Typography>
        <Stack
          direction="row"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button size="small" onClick={accept}>
            Accept
          </Button>
          <Button size="small" color="secondary" onClick={decline}>
            Decline
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export const HomeInvitations = () => {
  const { state, setState } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);

  if (
    state &&
    state.playerRecord &&
    state.playerRecord.invitations &&
    Object.keys(state.playerRecord.invitations).length > 0
  ) {
    return (
      <Accordion
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary>
          You have {Object.keys(state.playerRecord.invitations).length}{" "}
          invitation
          {Object.keys(state.playerRecord.invitations).length > 1 ? "s" : ""}
        </AccordionSummary>
        <AccordionDetails>
          {Object.values(state.playerRecord.invitations).map((i) => (
            <HomeInvitation key={i.InvitationID} invitation={i} />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  } else return <></>;
};
