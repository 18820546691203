import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import dayjs, { Dayjs } from "dayjs";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

// MUI

import {
  Box,
  Typography,
  Stack,
  Button,
  Modal,
  TextField,
  Slider,
} from "@mui/material";

// MUI ICONS

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

// TYPES

import {
  MatchType,
  LeagueType,
  SpotType,
  AppContextContent,
  EventType,
} from "../types";

import { AppContext, updateEvent } from "../App";
import { match } from "assert";
import { flexbox } from "@mui/system";
import { PlayerButton, Slot } from "./PlayerButton";

import { hPlayersWithAvailabilitiesType } from "./Event";
import { PlayerButtonProps } from "./PlayerButton";

// STYLE

import { style } from "./Event";

export const CourtForm = (props: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  match: MatchType;
  event: EventType;
  lid: string;
}) => {
  const { state, setState } = useContext(AppContext);
  const { showModal, setShowModal, match, event, lid } = props;
  const [courtName, setCourtName] = useState(match.court);
  const [courtMinutes, setCourtMinutes] = useState(match.minutes || 90);
  const [courtOffset, setCourtOffset] = useState(match.offset || 0);

  const deleteCourt = async () => {
    if (window.confirm("Delete court?")) {
      // deleting
      const newmatches: { [MatchID: string]: MatchType } = JSON.parse(
        JSON.stringify(event.matches)
      );

      delete newmatches[match.MatchID];
      updateEvent(state.leagues[lid], event, { matches: newmatches });

      setShowModal(false);
    }
  };

  const moveCourt = async (direction: "left" | "right") => {
    // deleting
    const newmatches: { [MatchID: string]: MatchType } = JSON.parse(
      JSON.stringify(event.matches)
    );

    const newposition =
      direction === "left" ? match.order - 1 : match.order + 1;

    if (newposition > 0 && newposition <= Object.keys(event.matches).length) {
      Object.values(newmatches)
        .filter((m) => m.order == newposition)
        .forEach((m) => (m.order = match.order));
      newmatches[match.MatchID].order = newposition;

      updateEvent(state.leagues[lid], event, { matches: newmatches });
    } else {
      alert("Can't move further");
    }
  };

  const saveCourt = async () => {
    // saving
    const newmatch: MatchType = JSON.parse(JSON.stringify(match));

    newmatch.court = courtName;
    newmatch.minutes = courtMinutes;
    newmatch.offset = courtOffset;

    updateEvent(state.leagues[lid], event, {
      ["matches." + match.MatchID]: newmatch,
    });

    setShowModal(false);
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={2} direction="column">
          <Typography sx={{ alignContent: "center", fontSize: "16pt" }}>
            Editing court
          </Typography>
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              onClick={() => {
                moveCourt("left");
              }}
              variant="contained"
              startIcon={<KeyboardDoubleArrowLeftIcon />}
            >
              move left
            </Button>
            <Button
              onClick={() => {
                moveCourt("right");
              }}
              variant="contained"
              endIcon={<KeyboardDoubleArrowRightIcon />}
            >
              move right
            </Button>
          </Stack>
          <TextField
            value={courtName}
            label="Court name"
            onChange={(event) => {
              setCourtName(event.currentTarget.value);
            }}
          />
          <Typography sx={{ marginTop: 3 }}>
            Match duration in minutes
          </Typography>
          <Slider
            aria-label="Default match duration"
            valueLabelDisplay="on"
            min={0}
            max={300}
            step={5}
            onChange={(event, newvalue) => {
              setCourtMinutes(typeof newvalue == "number" ? newvalue : 0);
            }}
            value={courtMinutes}
            marks
          ></Slider>
          <Typography sx={{ marginTop: 3 }}>
            Match start time: offset in minutes
          </Typography>
          <TextField
            type="number"
            value={courtOffset}
            onChange={(e) => {
              setCourtOffset(Number.parseInt(e.currentTarget.value));
            }}
          />
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
            spacing={1}
          >
            <Button variant="contained" onClick={() => saveCourt()}>
              Save
            </Button>
            <Button
              onClick={() => {
                deleteCourt();
              }}
              variant="outlined"
              color="secondary"
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
