import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  where,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

import { User as FirebaseUser, updateEmail } from "firebase/auth";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  TextField,
  Divider,
  Select,
  MenuItem,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Event } from "./Event";
import { EmailTemplateTypeType, EventType, hEventType } from "../types";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import FilterListIcon from "@mui/icons-material/FilterList";
import BuildIcon from "@mui/icons-material/Build";

import {
  LeagueType,
  EmailTemplateType,
  AdminContextContent,
  hTemplateType,
} from "../types";
import { AppContext, updateLeague } from "../App";
import { AdminContext } from "../App";

import { style } from "./Event";

import { VERSION } from "../processEmails";

const SetupLoggedUser = () => {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);

  const changeEmail = () => {
    const newEmail = window.prompt(
      "New email address:",
      state.loggedPlayer?.email || ""
    );

    if (
      newEmail &&
      window.confirm("Sure you want to change to " + newEmail + "?")
    ) {
      if (auth.currentUser) {
        updateEmail(auth.currentUser, newEmail)
          .then(() => {
            alert(
              "Success. Please verify your new email address, and login with your new email."
            );
            auth.signOut().then(() => {
              window.location.reload();
            });
          })
          .catch((e) => {
            alert("Error: " + e);
          });
      }
      alert("You will receive an email to verify your new address");
    }
  };

  const changePhone = () => {
    const newPhone = window.prompt(
      "New phone number:",
      state.loggedPlayer?.phoneNumber || ""
    );

    if (
      newPhone &&
      state.playerRecord &&
      window.confirm("Sure you want to change to " + newPhone + "?")
    ) {
      const playerRef = doc(db, "players", state.playerRecord?.PlayerID);
      updateDoc(playerRef, {
        phone: newPhone,
      }).then(() => {});
    }
  };

  return (
    <Stack
      direction="column"
      sx={{ margin: 5, marginTop: 5, marginBottom: 20 }}
    >
      <Typography sx={{ marginTop: 3 }}>
        Logged in as {state.loggedPlayer?.displayName}
      </Typography>
      <Typography sx={{ marginTop: 0, userSelect: "text" }}>
        {state.loggedPlayer?.uid}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          auth.signOut().then(() => {
            window.location.reload();
          });
        }}
      >
        Sign out
      </Button>
      <Typography sx={{ marginTop: 3 }}>Client Version {VERSION}</Typography>
      <Typography>
        Latest Version Available {adminState?.configuration?.version}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          window.location.reload();
        }}
      >
        Use latest version
      </Button>

      <Typography sx={{ marginTop: 3 }}>
        {(state.reads || 0) + (adminState.reads || 0)} reads
      </Typography>

      <Paper sx={{ padding: 2 }}>
        <Typography>Email address</Typography>
        <Typography> {state.loggedPlayer?.email}</Typography>
        <Button
          onClick={() => {
            changeEmail();
          }}
        >
          Change
        </Button>
        <Typography>Phone number for texts</Typography>
        <Typography>{state.playerRecord?.phone || "None set"}</Typography>
        <Button
          onClick={() => {
            changePhone();
          }}
        >
          Change
        </Button>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={!state.playerRecord?.optOutEmails} />}
              label="opt in to receive emails from Racquetbudz"
              onChange={(event) => {
                if (state.playerRecord) {
                  const playerRef = doc(
                    db,
                    "players",
                    state.playerRecord?.PlayerID
                  );
                  updateDoc(playerRef, {
                    optOutEmails: state.playerRecord.optOutEmails
                      ? false
                      : true,
                  });
                }
              }}
            />
          </FormGroup>
        </Box>
      </Paper>
    </Stack>
  );
};

const SetupLeagues = () => {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const [leagues, setLeagues] = useState<LeagueType[]>([]);

  const fetchAllLeagues = () => {
    var q = query(
      collection(db, "leagues"),
      where("players", "array-contains", state.loggedPlayer?.uid || undefined)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const loadedLeagues: LeagueType[] = [];
      querySnapshot.docChanges().forEach(async (changes) => {
        console.log("Snapshot on all leagues");
        if (changes.type === "added" || changes.type === "modified") {
          loadedLeagues.push(changes.doc.data() as LeagueType);
        }
      });
      setLeagues(loadedLeagues);
    });

    return unsubscribe;
  };

  useEffect(() => {
    return fetchAllLeagues();
  }, []);

  return (
    <>
      {leagues
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((v) => (
          <Card>
            <CardContent>
              <Typography>{v.name}</Typography>
              <Typography sx={{ fontSize: "12pt" }}>
                Administered by{" "}
                {v.admins.map((a) => v.playerDetails[a].fullname).join(", ")}
              </Typography>
              <Typography>{v.standalone ? "Standalone" : "League"} </Typography>

              {(() => {
                if (
                  v.admins.includes(state.loggedPlayer?.uid || "") ||
                  state.tokenResult?.claims.license === "sysadmin"
                ) {
                  return (
                    <>
                      <Button
                        onClick={() => {
                          if (window.confirm("Sure?")) {
                            updateLeague(v, { closed: !v.closed });
                          }
                        }}
                      >
                        {v.closed ? "Reopen league" : "Close league"}
                      </Button>
                    </>
                  );
                }
              })()}
            </CardContent>
          </Card>
        ))}
    </>
  );
};

function Setup() {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const [panel, setPanel] = useState("logged");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (state) {
    return (
      <Box sx={{ marginTop: 7 }}>
        <Accordion
          expanded={panel === "logged"}
          onChange={() => {
            setPanel("logged");
          }}
        >
          <AccordionSummary>Logged user</AccordionSummary>
          <AccordionDetails>
            <SetupLoggedUser />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel === "leagues"}
          onChange={() => {
            setPanel("leagues");
          }}
        >
          <AccordionSummary>My leagues</AccordionSummary>
          <AccordionDetails>
            <SetupLeagues />
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  } else return <></>;
}

export default Setup;
